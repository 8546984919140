import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Color: any;
  Date: any;
  Timestamp: any;
  Url: any;
};

export type CardTemplate = {
  __typename?: 'CardTemplate';
  cardTemplateProperties?: Maybe<Array<Maybe<CardTemplateProperty>>>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  defaultMessage?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  guid: Scalars['ID'];
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  templateImageFileName?: Maybe<Scalars['String']>;
  templateImageUrl?: Maybe<Scalars['Url']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CardTemplateProperty = {
  __typename?: 'CardTemplateProperty';
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplate: CardTemplate;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  guid: Scalars['ID'];
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};

export type Catalog = {
  __typename?: 'Catalog';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  items?: Maybe<Array<Maybe<Item>>>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CenterMember = {
  __typename?: 'CenterMember';
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email: Scalars['String'];
  guid: Scalars['ID'];
  id: Scalars['String'];
  isVisible?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shop?: Maybe<Shop>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};


export type CommunicationMessage = {
  __typename?: 'CommunicationMessage';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  notifyDate?: Maybe<Scalars['Date']>;
  order?: Maybe<Order>;
  readDate?: Maybe<Scalars['Date']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CommunicationMessageFilter = {
  orderGuid?: Maybe<Scalars['String']>;
};

export type CommunicationMessageOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type Contact = {
  __typename?: 'Contact';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  giftgivingHistory?: Maybe<Array<Maybe<GiftgivingHistory>>>;
  guid?: Maybe<Scalars['ID']>;
  member?: Maybe<Member>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ContactFilter = {
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  addressTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  memberGuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
};

export type ContactOrderByInput = {
  createdAt?: Maybe<Sort>;
  name?: Maybe<Sort>;
  nameKana?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type Coupon = {
  __typename?: 'Coupon';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  guid: Scalars['ID'];
  minPriceToGain?: Maybe<Scalars['Int']>;
  minPriceToUse?: Maybe<Scalars['Int']>;
  slugName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CouponDeliveryRule = {
  __typename?: 'CouponDeliveryRule';
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CouponHistory = {
  __typename?: 'CouponHistory';
  couponAmount?: Maybe<Scalars['Int']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponExpirationDate?: Maybe<Scalars['Timestamp']>;
  couponGuid?: Maybe<Scalars['String']>;
  couponMinPriceToGain?: Maybe<Scalars['Int']>;
  couponMinPriceToUse?: Maybe<Scalars['Int']>;
  couponSlugName?: Maybe<Scalars['String']>;
  couponTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  guid?: Maybe<Scalars['ID']>;
  member?: Maybe<Member>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CouponOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type CreateCardTemplateInput = {
  cardTemplateProperties?: Maybe<Array<Maybe<UpsertCardTemplatePropertyInput>>>;
  defaultMessage?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  templateImageFileName?: Maybe<Scalars['String']>;
  templateImageUrl?: Maybe<Scalars['Url']>;
};

export type CreateCardTemplatePropertyInput = {
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplateGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};

export type CreateCatalogInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateCenterMemberInput = {
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shopGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateCommunicationMessageInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  notifyDate?: Maybe<Scalars['Date']>;
  orderGuid: Scalars['String'];
  readDate?: Maybe<Scalars['Date']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateContactInput = {
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  member: GuidInput;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<GuidInput>>>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateCouponDeliveryRuleInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateCouponHistoryInput = {
  couponAmount?: Maybe<Scalars['Int']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponExpirationDate?: Maybe<Scalars['Timestamp']>;
  couponGuid?: Maybe<Scalars['String']>;
  couponMinPriceToGain?: Maybe<Scalars['Int']>;
  couponMinPriceToUse?: Maybe<Scalars['Int']>;
  couponSlugName?: Maybe<Scalars['String']>;
  couponTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  member?: Maybe<GuidInput>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateCouponInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  minPriceToGain?: Maybe<Scalars['Int']>;
  minPriceToUse?: Maybe<Scalars['Int']>;
  slugName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDeliverdCouponInput = {
  couponAmount?: Maybe<Scalars['Int']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponGuid?: Maybe<Scalars['String']>;
  couponMinPriceToGain?: Maybe<Scalars['Int']>;
  couponMinPriceToUse?: Maybe<Scalars['Int']>;
  couponSlugName?: Maybe<Scalars['String']>;
  couponTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  expirationDate?: Maybe<Scalars['Timestamp']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  memberGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  usedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDeliveryCalendarDayInput = {
  date: Scalars['Date'];
  type: DeliveryCalendarDayType;
};

export type CreateDeliveryDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catchCopy?: Maybe<Scalars['String']>;
  categoryGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  daishinCode?: Maybe<Scalars['String']>;
  deliveryGuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['String']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  itemGuid?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  orderOptions?: Maybe<Array<Maybe<CreateOrderOptionInput>>>;
  postage?: Maybe<Scalars['Int']>;
  postageWithTax?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priceWithTax?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  quantity?: Maybe<Scalars['Int']>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  unitPrice?: Maybe<Scalars['Int']>;
  unitPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  webCode?: Maybe<Scalars['String']>;
};

export type CreateDeliveryInput = {
  contactGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  deliveryDetails?: Maybe<Array<Maybe<CreateDeliveryDetailInput>>>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  deliverySlipNo?: Maybe<Scalars['String']>;
  deliveryTraderNo?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  order?: Maybe<UpdateOrderInput>;
  orderGuid?: Maybe<Scalars['String']>;
  orderOptions?: Maybe<Array<Maybe<CreateOrderOptionInput>>>;
  postageFee?: Maybe<Scalars['Int']>;
  postageFeeWithTax?: Maybe<Scalars['Int']>;
  purposeGuid?: Maybe<Scalars['String']>;
  srcDeliveryGuid?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDiscountRateInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder: Scalars['Int'];
  lowerLimit: Scalars['Int'];
  name: Scalars['String'];
  rate: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit: Scalars['Int'];
};

export type CreateDomesticPostageFeeInput = {
  amount: Scalars['Int'];
  amountForHikidemono?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  prefecture: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDraftDeliveryDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery: GuidInput;
  draftOrderOptions?: Maybe<Array<Maybe<GuidInput>>>;
  guid?: Maybe<Scalars['String']>;
  itemGuid: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDraftDeliveryInput = {
  contact?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  draftDeliveryDetails?: Maybe<Array<Maybe<UpsertDraftDeliveryDetailInput>>>;
  draftOrder: GuidInput;
  draftOrderOptions?: Maybe<Array<Maybe<UpsertDraftOrderOptionInput>>>;
  itemCombinationQuantity?: Maybe<Scalars['Int']>;
  purposeGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDraftOrderInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddressType?: Maybe<DeliveryAddressType>;
  draftOrderOptions?: Maybe<Array<Maybe<GuidInput>>>;
  itemCombinations?: Maybe<Array<Maybe<GuidInput>>>;
  memberGuid: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  orderNote?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<GuidInput>>>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderNameKana?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDraftOrderOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery?: Maybe<GuidInput>;
  draftDeliveryDetail?: Maybe<GuidInput>;
  draftOrderOptionPropertyValues?: Maybe<Array<Maybe<UpsertDraftOrderOptionPropertyValueInput>>>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionGuid?: Maybe<Scalars['String']>;
  optionItemGuid?: Maybe<Scalars['String']>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateDraftOrderOptionPropertyValueInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftOrderOptionGuid?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  propertyGuid?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type CreateGiftgivingHistoryInput = {
  amount?: Maybe<Scalars['Int']>;
  contactGuid: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  description?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  returnPresentItemName?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateHandoverMessageInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  message?: Maybe<Scalars['String']>;
  orderGuid: Scalars['String'];
  readDate?: Maybe<Scalars['Date']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateInformationInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateItemBrandInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateItemCategoryInput = {
  aliasName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentGuid?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateItemCombinationDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid?: Maybe<Scalars['String']>;
  itemCombinationGuid?: Maybe<Scalars['String']>;
  itemCombinationOptions?: Maybe<Array<Maybe<UpsertItemCombinationOptionInput>>>;
  itemGuid?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateItemCombinationInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  itemCombinationDetails?: Maybe<Array<Maybe<UpsertItemCombinationDetailInput>>>;
  itemCombinationOptions?: Maybe<Array<Maybe<UpsertItemCombinationOptionInput>>>;
  member: GuidInput;
  name?: Maybe<Scalars['String']>;
  purposeGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateItemInput = {
  affiliatePrice?: Maybe<Scalars['Int']>;
  brands?: Maybe<Array<Maybe<GuidInput>>>;
  bundledItems?: Maybe<Array<Maybe<GuidInput>>>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catalogUrl?: Maybe<Scalars['String']>;
  catalogs?: Maybe<Array<Maybe<GuidInput>>>;
  catchCopy?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<GuidInput>>>;
  character?: Maybe<GuidInput>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  daishinCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageFileName01?: Maybe<Scalars['String']>;
  imageFileName02?: Maybe<Scalars['String']>;
  imageFileName03?: Maybe<Scalars['String']>;
  imageFileName04?: Maybe<Scalars['String']>;
  imageFileName05?: Maybe<Scalars['String']>;
  imageFileName06?: Maybe<Scalars['String']>;
  imageFileName07?: Maybe<Scalars['String']>;
  imageFileName08?: Maybe<Scalars['String']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  onlyForMember?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<GuidInput>>>;
  originalPrice?: Maybe<Scalars['Int']>;
  postage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  purposes?: Maybe<Array<Maybe<GuidInput>>>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  specialMemberPrice?: Maybe<Scalars['Int']>;
  specialPrice?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  webCode: Scalars['String'];
};

export type CreateItemPriceRangeInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder: Scalars['Int'];
  lowerLimit: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit: Scalars['Int'];
};

export type CreateMaterialImageInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  memberGuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateMemberInput = {
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNameKana?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  emailAuthSub?: Maybe<Scalars['String']>;
  facebookAuthSub?: Maybe<Scalars['String']>;
  googleAuthSub?: Maybe<Scalars['String']>;
  hasSubscriptionToMailzine?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  lineAuthSub?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  expressionName?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<GuidInput>>>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optionItems?: Maybe<Array<Maybe<GuidInput>>>;
  optionType?: Maybe<OptionType>;
  purposes?: Maybe<Array<Maybe<GuidInput>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateOptionItemInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oldPackingId?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  properties?: Maybe<Array<Maybe<GuidInput>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateOrderInput = {
  cardAccessId?: Maybe<Scalars['String']>;
  cardAccessPass?: Maybe<Scalars['String']>;
  cardResult?: Maybe<Scalars['String']>;
  chargePrice?: Maybe<Scalars['Int']>;
  chargePriceWithTax?: Maybe<Scalars['Int']>;
  couponDiscountPrice?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveries?: Maybe<Array<Maybe<CreateDeliveryInput>>>;
  discountPrice?: Maybe<Scalars['Int']>;
  discountPriceWithTax?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Float']>;
  memberGuid?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  orderNote?: Maybe<Scalars['String']>;
  orderOptins?: Maybe<Array<Maybe<CreateOrderOptionInput>>>;
  paymentMethodType?: Maybe<PaymentMethodType>;
  pointDiscountPrice?: Maybe<Scalars['Int']>;
  pointGainPrice?: Maybe<Scalars['Int']>;
  pointGainRate?: Maybe<Scalars['Float']>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderNameKana?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  shopCode: Scalars['String'];
  status?: Maybe<OrderState>;
  subTotalPriceNotOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceNotOnDiscountWithTax?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscountWithTax?: Maybe<Scalars['Int']>;
  totalPostageFee?: Maybe<Scalars['Int']>;
  totalPostageFeeWithTax?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTax?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateOrderOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  delivery?: Maybe<GuidInput>;
  deliveryDetail?: Maybe<GuidInput>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionGuid?: Maybe<Scalars['String']>;
  optionItemGuid?: Maybe<Scalars['String']>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  orderOptionPropertyValues?: Maybe<Array<Maybe<UpsertOrderOptionPropertyValueInput>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateOrderOptionPropertyValueInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderOptionGuid?: Maybe<Scalars['String']>;
  propertyGuid?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type CreateOriginalCardInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  memberGuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreatePickupStoreInput = {
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreatePointHistoryInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  description?: Maybe<Scalars['String']>;
  memberGuid: Scalars['String'];
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreatePointInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  memberGuid: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreatePointRateInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder: Scalars['Int'];
  lowerLimit: Scalars['Int'];
  name: Scalars['String'];
  rate: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit: Scalars['Int'];
};

export type CreatePropertyInput = {
  cautionText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  name?: Maybe<Scalars['String']>;
  placeholderText?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreatePurposeInput = {
  categoryName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  freeShippingLine?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<GuidInput>>>;
  purposeNo?: Maybe<Scalars['Int']>;
  slugName?: Maybe<Scalars['String']>;
};

export type CreateRankingRawRecordInput = {
  categories?: Maybe<Array<Maybe<GuidInput>>>;
  categoryGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  deliveryDetailGuid?: Maybe<Scalars['String']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  itemGuid?: Maybe<Scalars['String']>;
  orderGuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  purposeGuid?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sectionId?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  unitPrice?: Maybe<Scalars['Int']>;
  webCode?: Maybe<Scalars['String']>;
};

export type CreateRelationshipInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  memberGuid: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateShopInformationInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateShopInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CreateShopSettingInput = {
  contactAddress?: Maybe<Scalars['String']>;
  contactEmailAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  discountRateDefermentDays?: Maybe<Scalars['Int']>;
  maxOrderAmountForDeferredPayment?: Maybe<Scalars['Int']>;
  minOrderAmountForFreeShipping?: Maybe<Scalars['Int']>;
  pointGrantRate?: Maybe<Scalars['Float']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shopCode: Scalars['String'];
  shopName: Scalars['String'];
  specifiedCommercialTransactionAct?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};


export enum DebitCreditType {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type DeleteCardTemplateInput = {
  guid: Scalars['String'];
};

export type DeleteCardTemplatePropertyInput = {
  guid: Scalars['String'];
};

export type DeleteCatalogInput = {
  guid: Scalars['String'];
};

export type DeleteCenterMemberInput = {
  guid: Scalars['String'];
};

export type DeleteCommunicationMessageInput = {
  guid: Scalars['String'];
};

export type DeleteContactInput = {
  guid: Scalars['String'];
};

export type DeleteCouponDeliveryRuleInput = {
  guid: Scalars['String'];
};

export type DeleteCouponHistoryInput = {
  guid: Scalars['String'];
};

export type DeleteCouponInput = {
  guid: Scalars['String'];
};

export type DeleteDeliverdCouponInput = {
  guid: Scalars['String'];
};

export type DeleteDeliveryCalendarDayInput = {
  guid: Scalars['String'];
};

export type DeleteDeliveryDetailInput = {
  guid: Scalars['String'];
};

export type DeleteDeliveryInput = {
  guid: Scalars['String'];
};

export type DeleteDiscountRateInput = {
  guid: Scalars['String'];
};

export type DeleteDomesticPostageFeeInput = {
  guid: Scalars['String'];
};

export type DeleteDraftDeliveryDetailInput = {
  guid: Scalars['String'];
};

export type DeleteDraftDeliveryInput = {
  guid: Scalars['String'];
};

export type DeleteDraftOrderInput = {
  guid: Scalars['String'];
};

export type DeleteDraftOrderOptionInput = {
  guid: Scalars['String'];
};

export type DeleteDraftOrderOptionPropertyValueInput = {
  guid: Scalars['String'];
};

export type DeleteDraftOrderOptionsInput = {
  draftDeliveryDetailGuid?: Maybe<Scalars['String']>;
  draftDeliveryGuid?: Maybe<Scalars['String']>;
  draftOrderGuid?: Maybe<Scalars['String']>;
};

export type DeleteGiftgivingHistoryInput = {
  guid: Scalars['String'];
};

export type DeleteHandoverMessageInput = {
  guid: Scalars['String'];
};

export type DeleteInformationInput = {
  guid: Scalars['String'];
};

export type DeleteItemBrandInput = {
  guid: Scalars['String'];
};

export type DeleteItemCategoryInput = {
  guid: Scalars['String'];
};

export type DeleteItemCombinationInput = {
  guid: Scalars['String'];
};

export type DeleteItemCombinationOptionInput = {
  guid: Scalars['String'];
};

export type DeleteItemCombinationOptionsInput = {
  itemCombinationDetailGuid?: Maybe<Scalars['String']>;
  itemCombinationGuid?: Maybe<Scalars['String']>;
};

export type DeleteItemInput = {
  guid: Scalars['String'];
};

export type DeleteItemPriceRangeInput = {
  guid: Scalars['String'];
};

export type DeleteMaterialImageInput = {
  guid: Scalars['String'];
};

export type DeleteMaterialImagesInput = {
  guid: Scalars['String'];
};

export type DeleteMemberInput = {
  guid: Scalars['String'];
};

export type DeleteOptionInput = {
  guid: Scalars['String'];
};

export type DeleteOptionItemInput = {
  guid: Scalars['String'];
};

export type DeleteOrderInput = {
  guid: Scalars['String'];
};

export type DeleteOrderOptionInput = {
  guid: Scalars['String'];
};

export type DeleteOrderOptionPropertyValueInput = {
  guid: Scalars['String'];
};

export type DeleteOriginalCardInput = {
  guid: Scalars['String'];
};

export type DeletePickupStoreInput = {
  guid: Scalars['String'];
};

export type DeletePointHistoryInput = {
  guid: Scalars['String'];
};

export type DeletePointInput = {
  guid: Scalars['String'];
};

export type DeletePointRateInput = {
  guid: Scalars['String'];
};

export type DeletePropertyInput = {
  guid: Scalars['String'];
};

export type DeletePurposeInput = {
  guid: Scalars['String'];
};

export type DeleteRelationshipInput = {
  guid: Scalars['String'];
};

export type DeleteShopInformationInput = {
  guid: Scalars['String'];
};

export type DeleteShopInput = {
  guid: Scalars['String'];
};

export type DeleteShopSettingInput = {
  guid: Scalars['String'];
};

export type DeliverdCoupon = {
  __typename?: 'DeliverdCoupon';
  coupon?: Maybe<Coupon>;
  couponAmount?: Maybe<Scalars['Int']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponGuid?: Maybe<Scalars['String']>;
  couponMinPriceToGain?: Maybe<Scalars['Int']>;
  couponMinPriceToUse?: Maybe<Scalars['Int']>;
  couponSlugName?: Maybe<Scalars['String']>;
  couponTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  expirationDate?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  isUsed?: Maybe<Scalars['Boolean']>;
  member?: Maybe<Member>;
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  usedAt?: Maybe<Scalars['Timestamp']>;
};

export type DeliverdCouponFilter = {
  expirationDateFrom?: Maybe<Scalars['Timestamp']>;
  expirationDateTo?: Maybe<Scalars['Timestamp']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  memberGuid?: Maybe<Scalars['String']>;
};

export type DeliverdCouponOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type Delivery = {
  __typename?: 'Delivery';
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  deliveryDetails?: Maybe<Array<Maybe<DeliveryDetail>>>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  deliverySlipNo?: Maybe<Scalars['String']>;
  deliveryTraderNo?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderOptions?: Maybe<Array<Maybe<OrderOption>>>;
  postageFee?: Maybe<Scalars['Int']>;
  postageFeeWithTax?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Purpose>;
  srcDeliveryGuid?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export enum DeliveryAddressType {
  ContactOnly = 'CONTACT_ONLY',
  HomeAndContact = 'HOME_AND_CONTACT',
  HomeOnly = 'HOME_ONLY'
}

export type DeliveryCalendarDay = {
  __typename?: 'DeliveryCalendarDay';
  createdAt?: Maybe<Scalars['Timestamp']>;
  date: Scalars['Date'];
  guid: Scalars['ID'];
  type: DeliveryCalendarDayType;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export enum DeliveryCalendarDayType {
  NonBusiness = 'NON_BUSINESS',
  NonFlesh = 'NON_FLESH'
}

export type DeliveryDetail = {
  __typename?: 'DeliveryDetail';
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledDetails?: Maybe<Array<Maybe<DeliveryDetail>>>;
  bundledWithDetail?: Maybe<DeliveryDetail>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catchCopy?: Maybe<Scalars['String']>;
  categoryGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  daishinCode?: Maybe<Scalars['String']>;
  delivery?: Maybe<Delivery>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['ID']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  item?: Maybe<Item>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  orderOptions?: Maybe<Array<Maybe<OrderOption>>>;
  postage?: Maybe<Scalars['Int']>;
  postageWithTax?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priceWithTax?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  quantity?: Maybe<Scalars['Int']>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  unitPrice?: Maybe<Scalars['Int']>;
  unitPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  webCode?: Maybe<Scalars['String']>;
};

export type DisableDeliveryInput = {
  guid: Scalars['String'];
  order?: Maybe<UpdateOrderInput>;
};

export type DiscountRate = {
  __typename?: 'DiscountRate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder: Scalars['Int'];
  guid: Scalars['ID'];
  lowerLimit: Scalars['Int'];
  name: Scalars['String'];
  rate: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit: Scalars['Int'];
};

export type DiscountRateFilter = {
  lowerLimitFrom?: Maybe<Scalars['Int']>;
  lowerLimitTo?: Maybe<Scalars['Int']>;
  upperLimitFrom?: Maybe<Scalars['Int']>;
  upperLimitTo?: Maybe<Scalars['Int']>;
};

export type DiscountRateOrderByInput = {
  createdAt?: Maybe<Sort>;
  lowerLimit?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
  upperLimit?: Maybe<Sort>;
};

export type DomesticPostageFee = {
  __typename?: 'DomesticPostageFee';
  amount: Scalars['Int'];
  amountForHikidemono: Scalars['Int'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder: Scalars['Int'];
  guid: Scalars['ID'];
  prefecture: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type DomesticPostageFeeFilter = {
  prefecture?: Maybe<Scalars['String']>;
};

export type DomesticPostageFeeOrderByInput = {
  createdAt?: Maybe<Sort>;
  displayOrder?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type DraftDelivery = {
  __typename?: 'DraftDelivery';
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  draftDeliveryDetails?: Maybe<Array<Maybe<DraftDeliveryDetail>>>;
  draftOrder?: Maybe<DraftOrder>;
  draftOrderOptions?: Maybe<Array<Maybe<DraftOrderOption>>>;
  guid: Scalars['ID'];
  itemCombinationQuantity?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Purpose>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type DraftDeliveryDetail = {
  __typename?: 'DraftDeliveryDetail';
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledDetails?: Maybe<Array<Maybe<DraftDeliveryDetail>>>;
  bundledWithDetail?: Maybe<DraftDeliveryDetail>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery?: Maybe<DraftDelivery>;
  draftOrderOptions?: Maybe<Array<Maybe<DraftOrderOption>>>;
  guid: Scalars['ID'];
  item?: Maybe<Item>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type DraftDeliveryFilter = {
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  draftOrderGuid?: Maybe<Scalars['String']>;
  purposeGuid?: Maybe<Scalars['String']>;
};

export type DraftDeliveryOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type DraftOrder = {
  __typename?: 'DraftOrder';
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddressType?: Maybe<DeliveryAddressType>;
  draftDeliveries?: Maybe<Array<Maybe<DraftDelivery>>>;
  draftOrderOptions?: Maybe<Array<Maybe<DraftOrderOption>>>;
  guid: Scalars['ID'];
  itemCombinations?: Maybe<Array<Maybe<ItemCombination>>>;
  member?: Maybe<Member>;
  memo?: Maybe<Scalars['String']>;
  orderNote?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderNameKana?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type DraftOrderFilter = {
  memberGuid?: Maybe<Scalars['String']>;
};

export type DraftOrderOption = {
  __typename?: 'DraftOrderOption';
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery?: Maybe<DraftDelivery>;
  draftDeliveryDetail?: Maybe<DraftDeliveryDetail>;
  draftOrderOptionPropertyValues?: Maybe<Array<Maybe<DraftOrderOptionPropertyValue>>>;
  guid: Scalars['ID'];
  option?: Maybe<Option>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionItem?: Maybe<OptionItem>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type DraftOrderOptionFilter = {
  draftDeliveryDetailGuid?: Maybe<Scalars['String']>;
  draftDeliveryGuid?: Maybe<Scalars['String']>;
  draftOrderGuid?: Maybe<Scalars['String']>;
  expressionName?: Maybe<Scalars['String']>;
};

export type DraftOrderOptionOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type DraftOrderOptionPropertyValue = {
  __typename?: 'DraftOrderOptionPropertyValue';
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftOrderOption?: Maybe<DraftOrderOption>;
  guid: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type DraftOrderOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  HtmlPart?: Maybe<Scalars['String']>;
  SubjectPart?: Maybe<Scalars['String']>;
  TemplateName: Scalars['String'];
  TextPart?: Maybe<Scalars['String']>;
};

export type FindUniqueCouponKey = {
  guid?: Maybe<Scalars['ID']>;
  slugName?: Maybe<Scalars['String']>;
};

export type FindUniqueDeliveryKey = {
  guid?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type FindUniqueDomesticPostageFeeKey = {
  guid?: Maybe<Scalars['ID']>;
  prefecture?: Maybe<Scalars['String']>;
};

export type FindUniqueItemKey = {
  guid?: Maybe<Scalars['ID']>;
  webCode?: Maybe<Scalars['String']>;
};

export type FindUniqueMemberKey = {
  email?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type FindUniquePurposeKey = {
  guid?: Maybe<Scalars['ID']>;
  slugName?: Maybe<Scalars['String']>;
};

export type GiftgivingHistory = {
  __typename?: 'GiftgivingHistory';
  amount?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  description?: Maybe<Scalars['String']>;
  guid: Scalars['ID'];
  itemName?: Maybe<Scalars['String']>;
  returnPresentItemName?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type GuidInput = {
  guid: Scalars['String'];
};

export type HandoverMessage = {
  __typename?: 'HandoverMessage';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  readDate?: Maybe<Scalars['Date']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type HandoverMessageFilter = {
  orderGuid?: Maybe<Scalars['String']>;
};

export type HandoverMessageOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type Information = {
  __typename?: 'Information';
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type InformationFilter = {
  targetDate?: Maybe<Scalars['Timestamp']>;
};

export type InformationOrderByInput = {
  createdAt?: Maybe<Sort>;
  publicationDate?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type InventoryItemInput = {
  guid?: Maybe<Scalars['String']>;
  stockQuantity?: Maybe<Scalars['Int']>;
};

export type Item = {
  __typename?: 'Item';
  affiliatePrice?: Maybe<Scalars['Int']>;
  affiliatePriceDiff?: Maybe<Scalars['Int']>;
  affiliatePriceWithTax?: Maybe<Scalars['Int']>;
  brands?: Maybe<Array<Maybe<ItemBrand>>>;
  bundledItems?: Maybe<Array<Maybe<Item>>>;
  bundledWithItems?: Maybe<Array<Maybe<Item>>>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catalogUrl?: Maybe<Scalars['String']>;
  catalogs?: Maybe<Array<Maybe<Catalog>>>;
  catchCopy?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<ItemCategory>>>;
  character?: Maybe<ItemBrand>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  daishinCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  guid: Scalars['ID'];
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageFileName01?: Maybe<Scalars['String']>;
  imageFileName02?: Maybe<Scalars['String']>;
  imageFileName03?: Maybe<Scalars['String']>;
  imageFileName04?: Maybe<Scalars['String']>;
  imageFileName05?: Maybe<Scalars['String']>;
  imageFileName06?: Maybe<Scalars['String']>;
  imageFileName07?: Maybe<Scalars['String']>;
  imageFileName08?: Maybe<Scalars['String']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  onlyForMember?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<Option>>>;
  originalPrice?: Maybe<Scalars['Int']>;
  postage?: Maybe<Scalars['Int']>;
  postageWithTax?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priceWithTax?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  purposes?: Maybe<Array<Maybe<Purpose>>>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  specialMemberPrice?: Maybe<Scalars['Int']>;
  specialMemberPriceDiff?: Maybe<Scalars['Int']>;
  specialMemberPriceWithTax?: Maybe<Scalars['Int']>;
  specialPrice?: Maybe<Scalars['Int']>;
  specialPriceDiff?: Maybe<Scalars['Int']>;
  specialPriceWithTax?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  webCode?: Maybe<Scalars['String']>;
};

export type ItemBrand = {
  __typename?: 'ItemBrand';
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['ID'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ItemBrandFilter = {
  isAvailable?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
};

export type ItemBrandOrderByInput = {
  createdAt?: Maybe<Sort>;
  displayOrder?: Maybe<Sort>;
  name?: Maybe<Sort>;
  nameKana?: Maybe<Sort>;
  slugName?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  aliasName?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<ItemCategory>>>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['ID'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ItemCategory>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ItemCategoryFilter = {
  hasParent?: Maybe<Scalars['Boolean']>;
  includeGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  parentGuid?: Maybe<Scalars['String']>;
};

export type ItemCategoryOrderByInput = {
  createdAt?: Maybe<Sort>;
  displayOrder?: Maybe<Sort>;
  name?: Maybe<Sort>;
  slugName?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type ItemCombination = {
  __typename?: 'ItemCombination';
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  itemCombinationDetails?: Maybe<Array<Maybe<ItemCombinationDetail>>>;
  itemCombinationOptions?: Maybe<Array<Maybe<ItemCombinationOption>>>;
  member?: Maybe<Member>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<Purpose>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ItemCombinationDetail = {
  __typename?: 'ItemCombinationDetail';
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledDetails?: Maybe<Array<Maybe<ItemCombinationDetail>>>;
  bundledWithDetail?: Maybe<ItemCombinationDetail>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  item?: Maybe<Item>;
  itemCombination?: Maybe<ItemCombination>;
  itemCombinationOptions?: Maybe<Array<Maybe<ItemCombinationOption>>>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ItemCombinationDetailPropertyValue = {
  __typename?: 'ItemCombinationDetailPropertyValue';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  itemCombinatioDetail?: Maybe<ItemCombinationDetail>;
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type ItemCombinationFilter = {
  memberGuid?: Maybe<Scalars['String']>;
};

export type ItemCombinationOption = {
  __typename?: 'ItemCombinationOption';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  itemCombination?: Maybe<ItemCombination>;
  itemCombinationDetail?: Maybe<ItemCombinationDetail>;
  itemCombinationOptionPropertyValues?: Maybe<Array<Maybe<ItemCombinationOptionPropertyValue>>>;
  option?: Maybe<Option>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionItem?: Maybe<OptionItem>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ItemCombinationOptionPropertyValue = {
  __typename?: 'ItemCombinationOptionPropertyValue';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  itemCombinationOption?: Maybe<ItemCombinationOption>;
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type ItemCombinationOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type ItemFilter = {
  affiliatePriceFrom?: Maybe<Scalars['Int']>;
  affiliatePriceTo?: Maybe<Scalars['Int']>;
  brandSlugName?: Maybe<Scalars['String']>;
  catalogCode?: Maybe<Scalars['String']>;
  characterSlugName?: Maybe<Scalars['String']>;
  daishinCode?: Maybe<Scalars['String']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  hasBrandOrCharacter?: Maybe<Scalars['Boolean']>;
  hasMinimumOrderQuantity?: Maybe<Scalars['Boolean']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  includeBrandGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeCategoryGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeCharacterGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  includePurposeGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isAvailableExpressDelivery?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onBargainSale?: Maybe<Scalars['Boolean']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  onlyForMember?: Maybe<Scalars['Boolean']>;
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  publicationDateFrom?: Maybe<Scalars['Date']>;
  publicationDateTo?: Maybe<Scalars['Date']>;
  sectionId?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  specialMemberPriceFrom?: Maybe<Scalars['Int']>;
  specialMemberPriceTo?: Maybe<Scalars['Int']>;
  specialPriceFrom?: Maybe<Scalars['Int']>;
  specialPriceTo?: Maybe<Scalars['Int']>;
};

export type ItemOrderByInput = {
  affiliatePrice?: Maybe<Sort>;
  affiliatePriceWithTax?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
  displayOrder?: Maybe<Sort>;
  name?: Maybe<Sort>;
  onDiscount?: Maybe<Sort>;
  price?: Maybe<Sort>;
  priceWithTax?: Maybe<Sort>;
  specialMemberPrice?: Maybe<Sort>;
  specialMemberPriceWithTax?: Maybe<Sort>;
  specialPrice?: Maybe<Sort>;
  specialPriceWithTax?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type ItemPriceRange = {
  __typename?: 'ItemPriceRange';
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder: Scalars['Int'];
  guid: Scalars['ID'];
  lowerLimit: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit: Scalars['Int'];
};

export type ItemPriceRangeFilter = {
  lowerLimitFrom?: Maybe<Scalars['Int']>;
  lowerLimitTo?: Maybe<Scalars['Int']>;
  upperLimitFrom?: Maybe<Scalars['Int']>;
  upperLimitTo?: Maybe<Scalars['Int']>;
};

export type ItemPriceRangeOrderByInput = {
  createdAt?: Maybe<Sort>;
  lowerLimit?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
  upperLimit?: Maybe<Sort>;
};

export type ItemRanking = {
  __typename?: 'ItemRanking';
  _sum?: Maybe<ItemRankingSumFields>;
  item?: Maybe<Item>;
  itemGuid?: Maybe<Scalars['String']>;
  webCode?: Maybe<Scalars['String']>;
};

export type ItemRankingFilter = {
  categoryGuid?: Maybe<Scalars['String']>;
  categoryGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  categorySlugName?: Maybe<Scalars['String']>;
  categorySlugNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  purposeGuid?: Maybe<Scalars['String']>;
  purposeSlugName?: Maybe<Scalars['String']>;
  purposeSlugNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Scalars['String']>;
  sectionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionDateFrom?: Maybe<Scalars['Timestamp']>;
  transactionDateTo?: Maybe<Scalars['Timestamp']>;
};

export type ItemRankingSumFields = {
  __typename?: 'ItemRankingSumFields';
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type MaterialImage = {
  __typename?: 'MaterialImage';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type MaterialImageFilter = {
  memberGuid?: Maybe<Scalars['String']>;
};

export type MaterialImageOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type Member = {
  __typename?: 'Member';
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cartItemWebCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  cellPhone?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNameKana?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  couponHistories?: Maybe<Array<Maybe<CouponHistory>>>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliverdCoupons?: Maybe<Array<Maybe<DeliverdCoupon>>>;
  email?: Maybe<Scalars['String']>;
  emailAuthSub?: Maybe<Scalars['String']>;
  facebookAuthSub?: Maybe<Scalars['String']>;
  googleAuthSub?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['ID']>;
  hasSubscriptionToMailzine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  lineAuthSub?: Maybe<Scalars['String']>;
  materialImages?: Maybe<Array<Maybe<MaterialImage>>>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  originalCards?: Maybe<Array<Maybe<OriginalCard>>>;
  phone?: Maybe<Scalars['String']>;
  point?: Maybe<Point>;
  pointHistories?: Maybe<Array<Maybe<PointHistory>>>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type MemberFilter = {
  email?: Maybe<Scalars['String']>;
  emailAuthSub?: Maybe<Scalars['String']>;
  facebookAuthSub?: Maybe<Scalars['String']>;
  googleAuthSub?: Maybe<Scalars['String']>;
  lineAuthSub?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type MemberOrderByInput = {
  createdAt?: Maybe<Sort>;
  email?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type MemberPropertyValue = {
  __typename?: 'MemberPropertyValue';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  member?: Maybe<Member>;
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCardTemplate?: Maybe<CardTemplate>;
  createCardTemplateProperty?: Maybe<CardTemplateProperty>;
  createCatalog?: Maybe<Catalog>;
  createCenterMember?: Maybe<CenterMember>;
  createCommunicationMessage?: Maybe<CommunicationMessage>;
  createContact?: Maybe<Contact>;
  createCoupon?: Maybe<Coupon>;
  createCouponHistory?: Maybe<CouponHistory>;
  createDeliverdCoupon?: Maybe<DeliverdCoupon>;
  createDelivery?: Maybe<Delivery>;
  createDeliveryCalendarDay: DeliveryCalendarDay;
  createDeliveryDetail?: Maybe<DeliveryDetail>;
  createDiscountRate?: Maybe<DiscountRate>;
  createDomesticPostageFee?: Maybe<DomesticPostageFee>;
  createDraftDelivery?: Maybe<DraftDelivery>;
  createDraftDeliveryDetail?: Maybe<DraftDeliveryDetail>;
  createDraftOrder?: Maybe<DraftOrder>;
  createDraftOrderOption?: Maybe<DraftOrderOption>;
  createDraftOrderOptionPropertyValue?: Maybe<DraftOrderOptionPropertyValue>;
  createDraftOrderOptions?: Maybe<Array<Maybe<DraftOrderOption>>>;
  createGiftgivingHistory?: Maybe<GiftgivingHistory>;
  createHandoverMessage?: Maybe<HandoverMessage>;
  createInformation: Information;
  createItem?: Maybe<Item>;
  createItemBrand?: Maybe<ItemBrand>;
  createItemCategory?: Maybe<ItemCategory>;
  createItemCombination?: Maybe<ItemCombination>;
  createItemPriceRange?: Maybe<ItemPriceRange>;
  createMaterialImage?: Maybe<MaterialImage>;
  createMember?: Maybe<Member>;
  createOption?: Maybe<Option>;
  createOptionItem?: Maybe<OptionItem>;
  createOrder?: Maybe<Order>;
  createOrderOption?: Maybe<OrderOption>;
  createOrderOptionPropertyValue?: Maybe<OrderOptionPropertyValue>;
  createOriginalCard?: Maybe<OriginalCard>;
  createPickupStore: PickupStore;
  createPoint?: Maybe<Point>;
  createPointHistory?: Maybe<PointHistory>;
  createPointRate?: Maybe<PointRate>;
  createProperty?: Maybe<Property>;
  createPurpose?: Maybe<Purpose>;
  createRelationship?: Maybe<Relationship>;
  createShop?: Maybe<Shop>;
  createShopInformation?: Maybe<ShopInformation>;
  createShopSetting?: Maybe<ShopSetting>;
  deleteCardTemplate?: Maybe<CardTemplate>;
  deleteCardTemplateProperty?: Maybe<CardTemplateProperty>;
  deleteCatalog?: Maybe<Catalog>;
  deleteCenterMember?: Maybe<CenterMember>;
  deleteCommunicationMessage?: Maybe<CommunicationMessage>;
  deleteContact?: Maybe<Contact>;
  deleteCoupon?: Maybe<Coupon>;
  deleteCouponHistory?: Maybe<CouponHistory>;
  deleteDeliverdCoupon?: Maybe<DeliverdCoupon>;
  deleteDelivery?: Maybe<Delivery>;
  deleteDeliveryCalendarDay: DeliveryCalendarDay;
  deleteDeliveryDetail?: Maybe<DeliveryDetail>;
  deleteDiscountRate?: Maybe<DiscountRate>;
  deleteDomesticPostageFee?: Maybe<DomesticPostageFee>;
  deleteDraftDelivery?: Maybe<DraftDelivery>;
  deleteDraftDeliveryDetail?: Maybe<DraftDeliveryDetail>;
  deleteDraftOrder?: Maybe<DraftOrder>;
  deleteDraftOrderOption?: Maybe<DraftOrderOption>;
  deleteDraftOrderOptionPropertyValue?: Maybe<DraftOrderOptionPropertyValue>;
  deleteDraftOrderOptions?: Maybe<Array<Maybe<DraftOrderOption>>>;
  deleteGiftgivingHistory?: Maybe<GiftgivingHistory>;
  deleteHandoverMessage?: Maybe<HandoverMessage>;
  deleteInformation: Information;
  deleteItem?: Maybe<Item>;
  deleteItemBrand?: Maybe<ItemBrand>;
  deleteItemCategory?: Maybe<ItemCategory>;
  deleteItemCombination?: Maybe<ItemCombination>;
  deleteItemCombinationOptions?: Maybe<Array<Maybe<ItemCombinationOption>>>;
  deleteItemPriceRange?: Maybe<ItemPriceRange>;
  deleteMaterialImage?: Maybe<MaterialImage>;
  deleteMaterialImages?: Maybe<Array<Maybe<MaterialImage>>>;
  deleteMember?: Maybe<Member>;
  deleteOption?: Maybe<Option>;
  deleteOptionItem?: Maybe<OptionItem>;
  deleteOrder?: Maybe<Order>;
  deleteOrderOption?: Maybe<OrderOption>;
  deleteOrderOptionPropertyValue?: Maybe<OrderOptionPropertyValue>;
  deleteOriginalCard?: Maybe<OriginalCard>;
  deletePickupStore: PickupStore;
  deletePoint?: Maybe<Point>;
  deletePointHistory?: Maybe<PointHistory>;
  deletePointRate?: Maybe<PointRate>;
  deleteProperty?: Maybe<Property>;
  deletePurpose?: Maybe<Purpose>;
  deleteRelationship?: Maybe<Relationship>;
  deleteShop?: Maybe<Shop>;
  deleteShopInformation?: Maybe<ShopInformation>;
  deleteShopSetting?: Maybe<ShopSetting>;
  disableDelivery?: Maybe<Delivery>;
  inventoryItems?: Maybe<Array<Maybe<Item>>>;
  launchExportItems?: Maybe<Scalars['String']>;
  launchExportOrders?: Maybe<Scalars['String']>;
  launchImportItems?: Maybe<Array<Maybe<Item>>>;
  launchImportOrders?: Maybe<Array<Maybe<Order>>>;
  notifyMessageCardDesingCommunicationMessage?: Maybe<CommunicationMessage>;
  sendCatalogRequestMail?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendHikidemonoInquiryMail?: Maybe<Scalars['Boolean']>;
  sendInquiryMail?: Maybe<Scalars['Boolean']>;
  sendTemplatedEmail?: Maybe<Scalars['Boolean']>;
  testRenderEmailTemplate?: Maybe<Scalars['String']>;
  transitionToCanceled?: Maybe<Order>;
  transitionToDeleted?: Maybe<Order>;
  transitionToDeliveryCompleted?: Maybe<Order>;
  transitionToGettingReady?: Maybe<Order>;
  transitionToImportedDeliverySlip?: Maybe<Order>;
  transitionToImportingDeliverySlip?: Maybe<Order>;
  transitionToPaid?: Maybe<Order>;
  transitionToPendingForRejection?: Maybe<Order>;
  transitionToRefunding?: Maybe<Order>;
  transitionToWaitingForAuthDeferredPayment?: Maybe<Order>;
  transitionToWaitingForBankTransfer?: Maybe<Order>;
  transitionToWaitingForCreditCardPayment?: Maybe<Order>;
  updateBundledItems?: Maybe<Item>;
  updateCardTemplate?: Maybe<CardTemplate>;
  updateCardTemplateProperty?: Maybe<CardTemplateProperty>;
  updateCartItemWebCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  updateCatalog?: Maybe<Catalog>;
  updateCenterMember?: Maybe<CenterMember>;
  updateCommunicationMessage?: Maybe<CommunicationMessage>;
  updateContact?: Maybe<Contact>;
  updateCoupon?: Maybe<Coupon>;
  updateCouponHistory?: Maybe<CouponHistory>;
  updateDeliverdCoupon?: Maybe<DeliverdCoupon>;
  updateDelivery?: Maybe<Delivery>;
  updateDeliveryCalendarDay: DeliveryCalendarDay;
  updateDeliveryDetail?: Maybe<DeliveryDetail>;
  updateDeliverySlipNo?: Maybe<Delivery>;
  updateDiscountRate?: Maybe<DiscountRate>;
  updateDomesticPostageFee?: Maybe<DomesticPostageFee>;
  updateDraftDelivery?: Maybe<DraftDelivery>;
  updateDraftDeliveryDetail?: Maybe<DraftDeliveryDetail>;
  updateDraftOrder?: Maybe<DraftOrder>;
  updateDraftOrderOption?: Maybe<DraftOrderOption>;
  updateDraftOrderOptionPropertyValue?: Maybe<DraftOrderOptionPropertyValue>;
  updateGiftgivingHistory?: Maybe<GiftgivingHistory>;
  updateHandoverMessage?: Maybe<HandoverMessage>;
  updateInformation: Information;
  updateItem?: Maybe<Item>;
  updateItemBrand?: Maybe<ItemBrand>;
  updateItemCategory?: Maybe<ItemCategory>;
  updateItemCombination?: Maybe<ItemCombination>;
  updateItemPriceRange?: Maybe<ItemPriceRange>;
  updateMaterialImage?: Maybe<MaterialImage>;
  updateMember?: Maybe<Member>;
  updateOption?: Maybe<Option>;
  updateOptionItem?: Maybe<OptionItem>;
  updateOrder?: Maybe<Order>;
  updateOrderCredit?: Maybe<Order>;
  updateOrderOption?: Maybe<OrderOption>;
  updateOrderOptionPropertyValue?: Maybe<OrderOptionPropertyValue>;
  updateOrderPaymentMethod?: Maybe<Order>;
  updateOriginalCard?: Maybe<OriginalCard>;
  updatePickupStore: PickupStore;
  updatePoint?: Maybe<Point>;
  updatePointHistory?: Maybe<PointHistory>;
  updatePointRate?: Maybe<PointRate>;
  updateProperty?: Maybe<Property>;
  updatePurpose?: Maybe<Purpose>;
  updateRelationship?: Maybe<Relationship>;
  updateShop?: Maybe<Shop>;
  updateShopInformation?: Maybe<ShopInformation>;
  updateShopSetting?: Maybe<ShopSetting>;
  upsertCatalogs?: Maybe<Array<Maybe<Catalog>>>;
  upsertContacts?: Maybe<Array<Maybe<Contact>>>;
  upsertEmailTemplate?: Maybe<EmailTemplate>;
  upsertItemBrands?: Maybe<Array<Maybe<ItemBrand>>>;
  upsertItemCategories?: Maybe<Array<Maybe<ItemCategory>>>;
  upsertItems?: Maybe<Array<Maybe<Item>>>;
  upsertOrders?: Maybe<Array<Maybe<Order>>>;
  upsertPurposes?: Maybe<Array<Maybe<Purpose>>>;
  useDeliverdCoupon?: Maybe<DeliverdCoupon>;
};


export type MutationCreateCardTemplateArgs = {
  input?: Maybe<CreateCardTemplateInput>;
};


export type MutationCreateCardTemplatePropertyArgs = {
  input?: Maybe<CreateCardTemplatePropertyInput>;
};


export type MutationCreateCatalogArgs = {
  input?: Maybe<CreateCatalogInput>;
};


export type MutationCreateCenterMemberArgs = {
  input?: Maybe<CreateCenterMemberInput>;
};


export type MutationCreateCommunicationMessageArgs = {
  input?: Maybe<CreateCommunicationMessageInput>;
};


export type MutationCreateContactArgs = {
  input?: Maybe<CreateContactInput>;
};


export type MutationCreateCouponArgs = {
  input?: Maybe<CreateCouponInput>;
};


export type MutationCreateCouponHistoryArgs = {
  input?: Maybe<CreateCouponHistoryInput>;
};


export type MutationCreateDeliverdCouponArgs = {
  input?: Maybe<CreateDeliverdCouponInput>;
};


export type MutationCreateDeliveryArgs = {
  input?: Maybe<CreateDeliveryInput>;
};


export type MutationCreateDeliveryCalendarDayArgs = {
  input?: Maybe<CreateDeliveryCalendarDayInput>;
};


export type MutationCreateDeliveryDetailArgs = {
  input?: Maybe<CreateDeliveryDetailInput>;
};


export type MutationCreateDiscountRateArgs = {
  input?: Maybe<CreateDiscountRateInput>;
};


export type MutationCreateDomesticPostageFeeArgs = {
  input?: Maybe<CreateDomesticPostageFeeInput>;
};


export type MutationCreateDraftDeliveryArgs = {
  input?: Maybe<CreateDraftDeliveryInput>;
};


export type MutationCreateDraftDeliveryDetailArgs = {
  input?: Maybe<CreateDraftDeliveryDetailInput>;
};


export type MutationCreateDraftOrderArgs = {
  input?: Maybe<CreateDraftOrderInput>;
};


export type MutationCreateDraftOrderOptionArgs = {
  input?: Maybe<CreateDraftOrderOptionInput>;
};


export type MutationCreateDraftOrderOptionPropertyValueArgs = {
  input?: Maybe<CreateDraftOrderOptionPropertyValueInput>;
};


export type MutationCreateDraftOrderOptionsArgs = {
  input?: Maybe<Array<Maybe<CreateDraftOrderOptionInput>>>;
};


export type MutationCreateGiftgivingHistoryArgs = {
  input?: Maybe<CreateGiftgivingHistoryInput>;
};


export type MutationCreateHandoverMessageArgs = {
  input?: Maybe<CreateHandoverMessageInput>;
};


export type MutationCreateInformationArgs = {
  input?: Maybe<CreateInformationInput>;
};


export type MutationCreateItemArgs = {
  input?: Maybe<CreateItemInput>;
};


export type MutationCreateItemBrandArgs = {
  input?: Maybe<CreateItemBrandInput>;
};


export type MutationCreateItemCategoryArgs = {
  input?: Maybe<CreateItemCategoryInput>;
};


export type MutationCreateItemCombinationArgs = {
  input?: Maybe<CreateItemCombinationInput>;
};


export type MutationCreateItemPriceRangeArgs = {
  input?: Maybe<CreateItemPriceRangeInput>;
};


export type MutationCreateMaterialImageArgs = {
  input?: Maybe<CreateMaterialImageInput>;
};


export type MutationCreateMemberArgs = {
  input?: Maybe<CreateMemberInput>;
};


export type MutationCreateOptionArgs = {
  input?: Maybe<CreateOptionInput>;
};


export type MutationCreateOptionItemArgs = {
  input?: Maybe<CreateOptionItemInput>;
};


export type MutationCreateOrderArgs = {
  input?: Maybe<CreateOrderInput>;
};


export type MutationCreateOrderOptionArgs = {
  input?: Maybe<CreateOrderOptionInput>;
};


export type MutationCreateOrderOptionPropertyValueArgs = {
  input?: Maybe<CreateOrderOptionPropertyValueInput>;
};


export type MutationCreateOriginalCardArgs = {
  input?: Maybe<CreateOriginalCardInput>;
};


export type MutationCreatePickupStoreArgs = {
  input?: Maybe<CreatePickupStoreInput>;
};


export type MutationCreatePointArgs = {
  input?: Maybe<CreatePointInput>;
};


export type MutationCreatePointHistoryArgs = {
  input?: Maybe<CreatePointHistoryInput>;
};


export type MutationCreatePointRateArgs = {
  input?: Maybe<CreatePointRateInput>;
};


export type MutationCreatePropertyArgs = {
  input?: Maybe<CreatePropertyInput>;
};


export type MutationCreatePurposeArgs = {
  input?: Maybe<CreatePurposeInput>;
};


export type MutationCreateRelationshipArgs = {
  input?: Maybe<CreateRelationshipInput>;
};


export type MutationCreateShopArgs = {
  input?: Maybe<CreateShopInput>;
};


export type MutationCreateShopInformationArgs = {
  input?: Maybe<CreateShopInformationInput>;
};


export type MutationCreateShopSettingArgs = {
  input?: Maybe<CreateShopSettingInput>;
};


export type MutationDeleteCardTemplateArgs = {
  input?: Maybe<DeleteCardTemplateInput>;
};


export type MutationDeleteCardTemplatePropertyArgs = {
  input?: Maybe<DeleteCardTemplatePropertyInput>;
};


export type MutationDeleteCatalogArgs = {
  input?: Maybe<DeleteCatalogInput>;
};


export type MutationDeleteCenterMemberArgs = {
  input?: Maybe<DeleteCenterMemberInput>;
};


export type MutationDeleteCommunicationMessageArgs = {
  input?: Maybe<DeleteCommunicationMessageInput>;
};


export type MutationDeleteContactArgs = {
  input?: Maybe<DeleteContactInput>;
};


export type MutationDeleteCouponArgs = {
  input?: Maybe<DeleteCouponInput>;
};


export type MutationDeleteCouponHistoryArgs = {
  input?: Maybe<DeleteCouponHistoryInput>;
};


export type MutationDeleteDeliverdCouponArgs = {
  input?: Maybe<DeleteDeliverdCouponInput>;
};


export type MutationDeleteDeliveryArgs = {
  input?: Maybe<DeleteDeliveryInput>;
};


export type MutationDeleteDeliveryCalendarDayArgs = {
  input?: Maybe<DeleteDeliveryCalendarDayInput>;
};


export type MutationDeleteDeliveryDetailArgs = {
  input?: Maybe<DeleteDeliveryDetailInput>;
};


export type MutationDeleteDiscountRateArgs = {
  input?: Maybe<DeleteDiscountRateInput>;
};


export type MutationDeleteDomesticPostageFeeArgs = {
  input?: Maybe<DeleteDomesticPostageFeeInput>;
};


export type MutationDeleteDraftDeliveryArgs = {
  input?: Maybe<DeleteDraftDeliveryInput>;
};


export type MutationDeleteDraftDeliveryDetailArgs = {
  input?: Maybe<DeleteDraftDeliveryDetailInput>;
};


export type MutationDeleteDraftOrderArgs = {
  input?: Maybe<DeleteDraftOrderInput>;
};


export type MutationDeleteDraftOrderOptionArgs = {
  input?: Maybe<DeleteDraftOrderOptionInput>;
};


export type MutationDeleteDraftOrderOptionPropertyValueArgs = {
  input?: Maybe<DeleteDraftOrderOptionPropertyValueInput>;
};


export type MutationDeleteDraftOrderOptionsArgs = {
  input?: Maybe<DeleteDraftOrderOptionsInput>;
};


export type MutationDeleteGiftgivingHistoryArgs = {
  input?: Maybe<DeleteGiftgivingHistoryInput>;
};


export type MutationDeleteHandoverMessageArgs = {
  input?: Maybe<DeleteHandoverMessageInput>;
};


export type MutationDeleteInformationArgs = {
  input?: Maybe<DeleteInformationInput>;
};


export type MutationDeleteItemArgs = {
  input?: Maybe<DeleteItemInput>;
};


export type MutationDeleteItemBrandArgs = {
  input?: Maybe<DeleteItemBrandInput>;
};


export type MutationDeleteItemCategoryArgs = {
  input?: Maybe<DeleteItemCategoryInput>;
};


export type MutationDeleteItemCombinationArgs = {
  input?: Maybe<DeleteItemCombinationInput>;
};


export type MutationDeleteItemCombinationOptionsArgs = {
  input?: Maybe<DeleteItemCombinationOptionsInput>;
};


export type MutationDeleteItemPriceRangeArgs = {
  input?: Maybe<DeleteItemPriceRangeInput>;
};


export type MutationDeleteMaterialImageArgs = {
  input?: Maybe<DeleteMaterialImageInput>;
};


export type MutationDeleteMaterialImagesArgs = {
  input?: Maybe<Array<Maybe<DeleteMaterialImagesInput>>>;
};


export type MutationDeleteMemberArgs = {
  input?: Maybe<DeleteMemberInput>;
};


export type MutationDeleteOptionArgs = {
  input?: Maybe<DeleteOptionInput>;
};


export type MutationDeleteOptionItemArgs = {
  input?: Maybe<DeleteOptionItemInput>;
};


export type MutationDeleteOrderArgs = {
  input?: Maybe<DeleteOrderInput>;
};


export type MutationDeleteOrderOptionArgs = {
  input?: Maybe<DeleteOrderOptionInput>;
};


export type MutationDeleteOrderOptionPropertyValueArgs = {
  input?: Maybe<DeleteOrderOptionPropertyValueInput>;
};


export type MutationDeleteOriginalCardArgs = {
  input?: Maybe<DeleteOriginalCardInput>;
};


export type MutationDeletePickupStoreArgs = {
  input?: Maybe<DeletePickupStoreInput>;
};


export type MutationDeletePointArgs = {
  input?: Maybe<DeletePointInput>;
};


export type MutationDeletePointHistoryArgs = {
  input?: Maybe<DeletePointHistoryInput>;
};


export type MutationDeletePointRateArgs = {
  input?: Maybe<DeletePointRateInput>;
};


export type MutationDeletePropertyArgs = {
  input?: Maybe<DeletePropertyInput>;
};


export type MutationDeletePurposeArgs = {
  input?: Maybe<DeletePurposeInput>;
};


export type MutationDeleteRelationshipArgs = {
  input?: Maybe<DeleteRelationshipInput>;
};


export type MutationDeleteShopArgs = {
  input?: Maybe<DeleteShopInput>;
};


export type MutationDeleteShopInformationArgs = {
  input?: Maybe<DeleteShopInformationInput>;
};


export type MutationDeleteShopSettingArgs = {
  input?: Maybe<DeleteShopSettingInput>;
};


export type MutationDisableDeliveryArgs = {
  input?: Maybe<DisableDeliveryInput>;
};


export type MutationInventoryItemsArgs = {
  input?: Maybe<Array<Maybe<InventoryItemInput>>>;
};


export type MutationNotifyMessageCardDesingCommunicationMessageArgs = {
  input?: Maybe<NotifyCommunicationMessageInput>;
};


export type MutationSendCatalogRequestMailArgs = {
  input?: Maybe<SendCatalogRequestMailInput>;
};


export type MutationSendEmailArgs = {
  input?: Maybe<SendEmailInput>;
};


export type MutationSendHikidemonoInquiryMailArgs = {
  input?: Maybe<SendHikidemonoInquiryMailInput>;
};


export type MutationSendInquiryMailArgs = {
  input?: Maybe<SendInquiryMailInput>;
};


export type MutationSendTemplatedEmailArgs = {
  input?: Maybe<SendTemplatedEmailInput>;
};


export type MutationTestRenderEmailTemplateArgs = {
  input?: Maybe<TestRenderEmailTemplateInput>;
};


export type MutationTransitionToCanceledArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToDeletedArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToDeliveryCompletedArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToGettingReadyArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToImportedDeliverySlipArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToImportingDeliverySlipArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToPaidArgs = {
  guid: Scalars['ID'];
  needCreditCardMail?: Maybe<Scalars['Boolean']>;
};


export type MutationTransitionToPendingForRejectionArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToRefundingArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToWaitingForAuthDeferredPaymentArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToWaitingForBankTransferArgs = {
  guid: Scalars['ID'];
};


export type MutationTransitionToWaitingForCreditCardPaymentArgs = {
  guid: Scalars['ID'];
};


export type MutationUpdateBundledItemsArgs = {
  input?: Maybe<UpdateBundledItemsInput>;
};


export type MutationUpdateCardTemplateArgs = {
  input?: Maybe<UpdateCardTemplateInput>;
};


export type MutationUpdateCardTemplatePropertyArgs = {
  input?: Maybe<UpdateCardTemplatePropertyInput>;
};


export type MutationUpdateCartItemWebCodesArgs = {
  input?: Maybe<UpdateCartItemWebCodesInput>;
};


export type MutationUpdateCatalogArgs = {
  input?: Maybe<UpdateCatalogInput>;
};


export type MutationUpdateCenterMemberArgs = {
  input?: Maybe<UpdateCenterMemberInput>;
};


export type MutationUpdateCommunicationMessageArgs = {
  input?: Maybe<UpdateCommunicationMessageInput>;
};


export type MutationUpdateContactArgs = {
  input?: Maybe<UpdateContactInput>;
};


export type MutationUpdateCouponArgs = {
  input?: Maybe<UpdateCouponInput>;
};


export type MutationUpdateCouponHistoryArgs = {
  input?: Maybe<UpdateCouponHistoryInput>;
};


export type MutationUpdateDeliverdCouponArgs = {
  input?: Maybe<UpdateDeliverdCouponInput>;
};


export type MutationUpdateDeliveryArgs = {
  input?: Maybe<UpdateDeliveryInput>;
};


export type MutationUpdateDeliveryCalendarDayArgs = {
  input?: Maybe<UpdateDeliveryCalendarDayInput>;
};


export type MutationUpdateDeliveryDetailArgs = {
  input?: Maybe<UpdateDeliveryDetailInput>;
};


export type MutationUpdateDeliverySlipNoArgs = {
  input?: Maybe<UpdateDeliverySlipNoInput>;
};


export type MutationUpdateDiscountRateArgs = {
  input?: Maybe<UpdateDiscountRateInput>;
};


export type MutationUpdateDomesticPostageFeeArgs = {
  input?: Maybe<UpdateDomesticPostageFeeInput>;
};


export type MutationUpdateDraftDeliveryArgs = {
  input?: Maybe<UpdateDraftDeliveryInput>;
};


export type MutationUpdateDraftDeliveryDetailArgs = {
  input?: Maybe<UpdateDraftDeliveryDetailInput>;
};


export type MutationUpdateDraftOrderArgs = {
  input?: Maybe<UpdateDraftOrderInput>;
};


export type MutationUpdateDraftOrderOptionArgs = {
  input?: Maybe<UpdateDraftOrderOptionInput>;
};


export type MutationUpdateDraftOrderOptionPropertyValueArgs = {
  input?: Maybe<UpdateDraftOrderOptionPropertyValueInput>;
};


export type MutationUpdateGiftgivingHistoryArgs = {
  input?: Maybe<UpdateGiftgivingHistoryInput>;
};


export type MutationUpdateHandoverMessageArgs = {
  input?: Maybe<UpdateHandoverMessageInput>;
};


export type MutationUpdateInformationArgs = {
  input?: Maybe<UpdateInformationInput>;
};


export type MutationUpdateItemArgs = {
  input?: Maybe<UpdateItemInput>;
};


export type MutationUpdateItemBrandArgs = {
  input?: Maybe<UpdateItemBrandInput>;
};


export type MutationUpdateItemCategoryArgs = {
  input?: Maybe<UpdateItemCategoryInput>;
};


export type MutationUpdateItemCombinationArgs = {
  input?: Maybe<UpdateItemCombinationInput>;
};


export type MutationUpdateItemPriceRangeArgs = {
  input?: Maybe<UpdateItemPriceRangeInput>;
};


export type MutationUpdateMaterialImageArgs = {
  input?: Maybe<UpdateMaterialImageInput>;
};


export type MutationUpdateMemberArgs = {
  input?: Maybe<UpdateMemberInput>;
};


export type MutationUpdateOptionArgs = {
  input?: Maybe<UpdateOptionInput>;
};


export type MutationUpdateOptionItemArgs = {
  input?: Maybe<UpdateOptionItemInput>;
};


export type MutationUpdateOrderArgs = {
  input?: Maybe<UpdateOrderInput>;
};


export type MutationUpdateOrderCreditArgs = {
  input?: Maybe<UpdateOrderCreditInput>;
};


export type MutationUpdateOrderOptionArgs = {
  input?: Maybe<UpdateOrderOptionInput>;
};


export type MutationUpdateOrderOptionPropertyValueArgs = {
  input?: Maybe<UpdateOrderOptionPropertyValueInput>;
};


export type MutationUpdateOrderPaymentMethodArgs = {
  input?: Maybe<UpdateOrderPaymentMethodInput>;
};


export type MutationUpdateOriginalCardArgs = {
  input?: Maybe<UpdateOriginalCardInput>;
};


export type MutationUpdatePickupStoreArgs = {
  input?: Maybe<UpdatePickupStoreInput>;
};


export type MutationUpdatePointArgs = {
  input?: Maybe<UpdatePointInput>;
};


export type MutationUpdatePointHistoryArgs = {
  input?: Maybe<UpdatePointHistoryInput>;
};


export type MutationUpdatePointRateArgs = {
  input?: Maybe<UpdatePointRateInput>;
};


export type MutationUpdatePropertyArgs = {
  input?: Maybe<UpdatePropertyInput>;
};


export type MutationUpdatePurposeArgs = {
  input?: Maybe<UpdatePurposeInput>;
};


export type MutationUpdateRelationshipArgs = {
  input?: Maybe<UpdateRelationshipInput>;
};


export type MutationUpdateShopArgs = {
  input?: Maybe<UpdateShopInput>;
};


export type MutationUpdateShopInformationArgs = {
  input?: Maybe<UpdateShopInformationInput>;
};


export type MutationUpdateShopSettingArgs = {
  input?: Maybe<UpdateShopSettingInput>;
};


export type MutationUpsertCatalogsArgs = {
  input?: Maybe<Array<Maybe<UpsertCatalogInput>>>;
};


export type MutationUpsertContactsArgs = {
  input?: Maybe<Array<Maybe<UpsertContactInput>>>;
};


export type MutationUpsertEmailTemplateArgs = {
  input?: Maybe<UpsertEmailTemplateInput>;
};


export type MutationUpsertItemBrandsArgs = {
  input?: Maybe<Array<Maybe<UpsertItemBrandInput>>>;
};


export type MutationUpsertItemCategoriesArgs = {
  input?: Maybe<Array<Maybe<UpsertItemCategoryInput>>>;
};


export type MutationUpsertItemsArgs = {
  input?: Maybe<Array<Maybe<UpsertItemInput>>>;
};


export type MutationUpsertOrdersArgs = {
  input?: Maybe<Array<Maybe<UpsertOrderInput>>>;
};


export type MutationUpsertPurposesArgs = {
  input?: Maybe<Array<Maybe<UpsertPurposeInput>>>;
};


export type MutationUseDeliverdCouponArgs = {
  guid: Scalars['ID'];
};

export type Nav = {
  __typename?: 'Nav';
  deliveries?: Maybe<Array<Maybe<NavDelivery>>>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryDateSelect?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  np?: Maybe<Scalars['Int']>;
  postageFeeWithTax?: Maybe<Scalars['Int']>;
  purposeNo?: Maybe<Scalars['Int']>;
  senders?: Maybe<Array<Maybe<NavSender>>>;
  site?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['String']>;
};

export type NavDelivery = {
  __typename?: 'NavDelivery';
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryDateSelect?: Maybe<Scalars['Int']>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  includedDeliveryIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  items?: Maybe<Array<Maybe<NavDeliveryDetail>>>;
};

export type NavDeliveryDetail = {
  __typename?: 'NavDeliveryDetail';
  deliveryItemRow?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unitPriceWithTax?: Maybe<Scalars['Int']>;
  webCode?: Maybe<Scalars['String']>;
};

export type NavSender = {
  __typename?: 'NavSender';
  senderAddress?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalcode?: Maybe<Scalars['String']>;
};

export type NotifyCommunicationMessageInput = {
  guid: Scalars['String'];
};

export type NotifyHandoverMessageInput = {
  guid: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  expressionName?: Maybe<Scalars['String']>;
  guid: Scalars['ID'];
  items?: Maybe<Array<Maybe<Item>>>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optionItems?: Maybe<Array<Maybe<OptionItem>>>;
  optionType?: Maybe<OptionType>;
  purposes?: Maybe<Array<Maybe<Purpose>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type OptionFilter = {
  expressionName?: Maybe<Scalars['String']>;
  includeItemGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  includePurposeGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  purposeSlugName?: Maybe<Scalars['String']>;
};

export type OptionItem = {
  __typename?: 'OptionItem';
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['ID'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oldPackingId?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  options?: Maybe<Array<Maybe<Option>>>;
  properties?: Maybe<Array<Maybe<Property>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type OptionItemFindCondition = {
  optionType?: Maybe<Scalars['String']>;
};

export type OptionOrderByInput = {
  createdAt?: Maybe<Sort>;
  displayOrder?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export enum OptionType {
  GiftBox = 'GIFT_BOX',
  MessageCard = 'MESSAGE_CARD',
  NameEngraving = 'NAME_ENGRAVING',
  Noshi = 'NOSHI',
  Other = 'OTHER',
  ThanksGift = 'THANKS_GIFT',
  WrappingPaper = 'WRAPPING_PAPER'
}

export type Order = {
  __typename?: 'Order';
  cardAccessId?: Maybe<Scalars['String']>;
  cardAccessPass?: Maybe<Scalars['String']>;
  cardResult?: Maybe<Scalars['String']>;
  chargePrice?: Maybe<Scalars['Int']>;
  chargePriceWithTax?: Maybe<Scalars['Int']>;
  communicationMessages?: Maybe<Array<Maybe<CommunicationMessage>>>;
  couponDiscountPrice?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveries?: Maybe<Array<Maybe<Delivery>>>;
  discountPrice?: Maybe<Scalars['Int']>;
  discountPriceWithTax?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Float']>;
  guid?: Maybe<Scalars['ID']>;
  handoverMessages?: Maybe<Array<Maybe<HandoverMessage>>>;
  id?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['String']>;
  orderNote?: Maybe<Scalars['String']>;
  orderOptions?: Maybe<Array<Maybe<OrderOption>>>;
  paymentMethodType?: Maybe<PaymentMethodType>;
  pointDiscountPrice?: Maybe<Scalars['Int']>;
  pointGainPrice?: Maybe<Scalars['Int']>;
  pointGainRate?: Maybe<Scalars['Float']>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderNameKana?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Int']>;
  status?: Maybe<OrderState>;
  subTotalPriceNotOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceNotOnDiscountWithTax?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscountWithTax?: Maybe<Scalars['Int']>;
  totalPostageFee?: Maybe<Scalars['Int']>;
  totalPostageFeeWithTax?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTax?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type OrderFilter = {
  couponDiscountPrice?: Maybe<Scalars['Int']>;
  discountPrice?: Maybe<Scalars['Int']>;
  discountPriceWithTax?: Maybe<Scalars['Int']>;
  discountRateFrom?: Maybe<Scalars['Float']>;
  discountRateTo?: Maybe<Scalars['Float']>;
  excludeOrderStatus?: Maybe<Array<Maybe<OrderState>>>;
  id?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  memberName?: Maybe<Scalars['String']>;
  paymentMethodType?: Maybe<PaymentMethodType>;
  phoneNumber?: Maybe<Scalars['String']>;
  pointDiscountPrice?: Maybe<Scalars['Int']>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  status?: Maybe<OrderState>;
  totalPriceWithTaxFrom?: Maybe<Scalars['Int']>;
  totalPriceWithTaxTo?: Maybe<Scalars['Int']>;
  transactionDateFrom?: Maybe<Scalars['Timestamp']>;
  transactionDateTo?: Maybe<Scalars['Timestamp']>;
};

export type OrderOption = {
  __typename?: 'OrderOption';
  createdAt?: Maybe<Scalars['Timestamp']>;
  delivery?: Maybe<Delivery>;
  deliveryDetail?: Maybe<DeliveryDetail>;
  guid: Scalars['ID'];
  option?: Maybe<Option>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionItem?: Maybe<OptionItem>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  orderOptionPropertyValues?: Maybe<Array<Maybe<OrderOptionPropertyValue>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type OrderOptionPropertyValue = {
  __typename?: 'OrderOptionPropertyValue';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderOptionOption?: Maybe<OrderOption>;
  property?: Maybe<Property>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type OrderOrderByInput = {
  createdAt?: Maybe<Sort>;
  transactionDate?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export enum OrderState {
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  DeliveryCompleted = 'DELIVERY_COMPLETED',
  GettingReady = 'GETTING_READY',
  ImportedDeliverySlip = 'IMPORTED_DELIVERY_SLIP',
  ImportingDeliverySlip = 'IMPORTING_DELIVERY_SLIP',
  Paid = 'PAID',
  PendingForRejection = 'PENDING_FOR_REJECTION',
  Refunding = 'REFUNDING',
  WaitingForAuthDeferredPayment = 'WAITING_FOR_AUTH_DEFERRED_PAYMENT',
  WaitingForBankTransfer = 'WAITING_FOR_BANK_TRANSFER',
  WaitingForCreditCardPayment = 'WAITING_FOR_CREDIT_CARD_PAYMENT'
}

export type OriginalCard = {
  __typename?: 'OriginalCard';
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type OriginalCardFilter = {
  memberGuid?: Maybe<Scalars['String']>;
};

export type OriginalCardOrderByInput = {
  createdAt?: Maybe<Sort>;
  name?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export enum PaymentMethodType {
  BankTransferPayment = 'BANK_TRANSFER_PAYMENT',
  CreditCardPayment = 'CREDIT_CARD_PAYMENT',
  NpDeferredPayment = 'NP_DEFERRED_PAYMENT'
}

export type PickupStore = {
  __typename?: 'PickupStore';
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type Point = {
  __typename?: 'Point';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  member?: Maybe<Member>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type PointFilter = {
  memberId?: Maybe<Scalars['String']>;
};

export type PointHistory = {
  __typename?: 'PointHistory';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  description?: Maybe<Scalars['String']>;
  guid: Scalars['ID'];
  member?: Maybe<Member>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type PointHistoryFilter = {
  memberId?: Maybe<Scalars['String']>;
};

export type PointHistoryOrderByInput = {
  createdAt?: Maybe<Sort>;
  transactionDate?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type PointOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type PointRate = {
  __typename?: 'PointRate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder: Scalars['Int'];
  guid: Scalars['ID'];
  lowerLimit: Scalars['Int'];
  name: Scalars['String'];
  rate: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit: Scalars['Int'];
};

export type PointRateFilter = {
  lowerLimitFrom?: Maybe<Scalars['Int']>;
  lowerLimitTo?: Maybe<Scalars['Int']>;
  upperLimitFrom?: Maybe<Scalars['Int']>;
  upperLimitTo?: Maybe<Scalars['Int']>;
};

export type PointRateOrderByInput = {
  createdAt?: Maybe<Sort>;
  lowerLimit?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
  upperLimit?: Maybe<Sort>;
};

export type Property = {
  __typename?: 'Property';
  cautionText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  optionItems?: Maybe<Array<Maybe<OptionItem>>>;
  placeholderText?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export enum PropertyType {
  Date = 'DATE',
  Delivery = 'DELIVERY',
  MaterialImage = 'MATERIAL_IMAGE',
  MultilineText = 'MULTILINE_TEXT',
  Numeric = 'NUMERIC',
  OriginalMessageCard = 'ORIGINAL_MESSAGE_CARD',
  PreviewNoshi = 'PREVIEW_NOSHI',
  SinglelineText = 'SINGLELINE_TEXT'
}

export type Purpose = {
  __typename?: 'Purpose';
  categoryName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  freeShippingLine?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['ID']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<Item>>>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<Option>>>;
  purposeNo?: Maybe<Scalars['Int']>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type PurposeFilter = {
  categoryName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
};

export type PurposeOrderByInput = {
  categoryName?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
  displayOrder?: Maybe<Sort>;
  name?: Maybe<Sort>;
  slugName?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type Query = {
  __typename?: 'Query';
  base64NavFile?: Maybe<Scalars['String']>;
  base64SalesSlipExcel?: Maybe<Scalars['String']>;
  cardTemplate?: Maybe<CardTemplate>;
  cardTemplateProperties?: Maybe<Array<Maybe<CardTemplateProperty>>>;
  cardTemplateProperty?: Maybe<CardTemplateProperty>;
  cardTemplates?: Maybe<Array<Maybe<CardTemplate>>>;
  catalog?: Maybe<Catalog>;
  catalogByCode?: Maybe<Catalog>;
  catalogs?: Maybe<Array<Maybe<Catalog>>>;
  centerMember?: Maybe<CenterMember>;
  centerMembers?: Maybe<Array<Maybe<CenterMember>>>;
  communicationMessage?: Maybe<CommunicationMessage>;
  communicationMessages?: Maybe<Array<Maybe<CommunicationMessage>>>;
  contact?: Maybe<Contact>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  countItems?: Maybe<Scalars['Int']>;
  countMembers?: Maybe<Scalars['Int']>;
  countOrders?: Maybe<Scalars['Int']>;
  coupon?: Maybe<Coupon>;
  couponHistories?: Maybe<Array<Maybe<CouponHistory>>>;
  couponHistory?: Maybe<CouponHistory>;
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  deliverdCoupon?: Maybe<DeliverdCoupon>;
  deliverdCoupons?: Maybe<Array<Maybe<DeliverdCoupon>>>;
  deliveries?: Maybe<Array<Maybe<Delivery>>>;
  delivery?: Maybe<Delivery>;
  deliveryCalendarDay?: Maybe<DeliveryCalendarDay>;
  deliveryCalendarDays?: Maybe<Array<Maybe<DeliveryCalendarDay>>>;
  deliveryDetail?: Maybe<DeliveryDetail>;
  deliveryDetails?: Maybe<Array<Maybe<DeliveryDetail>>>;
  discountRate?: Maybe<DiscountRate>;
  discountRates?: Maybe<Array<Maybe<DiscountRate>>>;
  domesticPostageFee?: Maybe<DomesticPostageFee>;
  domesticPostageFees?: Maybe<Array<Maybe<DomesticPostageFee>>>;
  draftDeliveries?: Maybe<Array<Maybe<DraftDelivery>>>;
  draftDelivery?: Maybe<DraftDelivery>;
  draftDeliveryDetail?: Maybe<DraftDeliveryDetail>;
  draftDeliveryDetails?: Maybe<Array<Maybe<DraftDeliveryDetail>>>;
  draftOrder?: Maybe<DraftOrder>;
  draftOrderOption?: Maybe<DraftOrderOption>;
  draftOrderOptionPropertyValue?: Maybe<DraftOrderOptionPropertyValue>;
  draftOrderOptionPropertyValues?: Maybe<Array<Maybe<DraftOrderOptionPropertyValue>>>;
  draftOrderOptions?: Maybe<Array<Maybe<DraftOrderOption>>>;
  draftOrders?: Maybe<Array<Maybe<DraftOrder>>>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplates?: Maybe<Array<Maybe<EmailTemplate>>>;
  giftgivingHistories?: Maybe<Array<Maybe<GiftgivingHistory>>>;
  giftgivingHistory?: Maybe<GiftgivingHistory>;
  handoverMessage?: Maybe<HandoverMessage>;
  handoverMessages?: Maybe<Array<Maybe<HandoverMessage>>>;
  information?: Maybe<Information>;
  informations?: Maybe<Array<Maybe<Information>>>;
  item?: Maybe<Item>;
  itemBrand?: Maybe<ItemBrand>;
  itemBrands?: Maybe<Array<Maybe<ItemBrand>>>;
  itemCategories?: Maybe<Array<Maybe<ItemCategory>>>;
  itemCategory?: Maybe<ItemCategory>;
  itemCombination?: Maybe<ItemCombination>;
  itemCombinations?: Maybe<Array<Maybe<ItemCombination>>>;
  itemPriceRange?: Maybe<ItemPriceRange>;
  itemPriceRanges?: Maybe<Array<Maybe<ItemPriceRange>>>;
  items?: Maybe<Array<Maybe<Item>>>;
  materialImage?: Maybe<MaterialImage>;
  materialImages?: Maybe<Array<Maybe<MaterialImage>>>;
  member?: Maybe<Member>;
  memberPropertyValue?: Maybe<MemberPropertyValue>;
  memberPropertyValues?: Maybe<Array<Maybe<MemberPropertyValue>>>;
  members?: Maybe<Array<Maybe<Member>>>;
  navs?: Maybe<Array<Maybe<Nav>>>;
  nonBusinessDays?: Maybe<Array<Maybe<DeliveryCalendarDay>>>;
  nonFleshDays?: Maybe<Array<Maybe<DeliveryCalendarDay>>>;
  option?: Maybe<Option>;
  optionItem?: Maybe<OptionItem>;
  optionItems?: Maybe<Array<Maybe<OptionItem>>>;
  options?: Maybe<Array<Maybe<Option>>>;
  order?: Maybe<Order>;
  orderOption?: Maybe<OrderOption>;
  orderOptionPropertyValue?: Maybe<OrderOptionPropertyValue>;
  orderOptionPropertyValues?: Maybe<Array<Maybe<OrderOptionPropertyValue>>>;
  orderOptions?: Maybe<Array<Maybe<OrderOption>>>;
  orderRanking?: Maybe<Array<Maybe<ItemRanking>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  originalCard?: Maybe<OriginalCard>;
  originalCards?: Maybe<Array<Maybe<OriginalCard>>>;
  pickupStore?: Maybe<PickupStore>;
  pickupStores?: Maybe<Array<Maybe<PickupStore>>>;
  point?: Maybe<Point>;
  pointHistories?: Maybe<Array<Maybe<PointHistory>>>;
  pointHistory?: Maybe<PointHistory>;
  pointRate?: Maybe<PointRate>;
  pointRates?: Maybe<Array<Maybe<PointRate>>>;
  points?: Maybe<Array<Maybe<Point>>>;
  prefectureByPostalCode?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<Property>>>;
  property?: Maybe<Property>;
  purpose?: Maybe<Purpose>;
  purposes?: Maybe<Array<Maybe<Purpose>>>;
  rankingRawRecords?: Maybe<Array<Maybe<RankingRawRecord>>>;
  relationship?: Maybe<Relationship>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  salesRanking?: Maybe<Array<Maybe<ItemRanking>>>;
  searchOptionItemsByOptionType?: Maybe<Array<Maybe<OptionItem>>>;
  shop?: Maybe<Shop>;
  shopInformation?: Maybe<ShopInformation>;
  shopInformations?: Maybe<Array<Maybe<ShopInformation>>>;
  shopSetting?: Maybe<ShopSetting>;
  shops?: Maybe<Array<Maybe<Shop>>>;
  toplevelItemCategories?: Maybe<Array<Maybe<ItemCategory>>>;
};


export type QueryBase64NavFileArgs = {
  guid: Scalars['ID'];
};


export type QueryBase64SalesSlipExcelArgs = {
  guid: Scalars['ID'];
};


export type QueryCardTemplateArgs = {
  guid: Scalars['ID'];
};


export type QueryCardTemplatePropertyArgs = {
  guid: Scalars['ID'];
};


export type QueryCatalogArgs = {
  guid: Scalars['ID'];
};


export type QueryCatalogByCodeArgs = {
  code: Scalars['ID'];
};


export type QueryCenterMemberArgs = {
  guid?: Maybe<Scalars['String']>;
};


export type QueryCommunicationMessageArgs = {
  guid: Scalars['ID'];
};


export type QueryCommunicationMessagesArgs = {
  filter?: Maybe<CommunicationMessageFilter>;
  orderBy?: Maybe<CommunicationMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryContactArgs = {
  guid: Scalars['ID'];
};


export type QueryContactsArgs = {
  filter?: Maybe<ContactFilter>;
  orderBy?: Maybe<ContactOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryCountItemsArgs = {
  filter?: Maybe<ItemFilter>;
};


export type QueryCountMembersArgs = {
  filter?: Maybe<MemberFilter>;
};


export type QueryCountOrdersArgs = {
  filter?: Maybe<OrderFilter>;
};


export type QueryCouponArgs = {
  key: FindUniqueCouponKey;
};


export type QueryCouponHistoryArgs = {
  guid: Scalars['ID'];
};


export type QueryDeliverdCouponArgs = {
  guid: Scalars['ID'];
};


export type QueryDeliverdCouponsArgs = {
  filter?: Maybe<DeliverdCouponFilter>;
  orderBy?: Maybe<DeliverdCouponOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryArgs = {
  key?: Maybe<FindUniqueDeliveryKey>;
};


export type QueryDeliveryCalendarDayArgs = {
  guid?: Maybe<Scalars['String']>;
};


export type QueryDeliveryDetailArgs = {
  guid: Scalars['ID'];
};


export type QueryDiscountRateArgs = {
  guid: Scalars['ID'];
};


export type QueryDiscountRatesArgs = {
  filter?: Maybe<DiscountRateFilter>;
  orderBy?: Maybe<DiscountRateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDomesticPostageFeeArgs = {
  key: FindUniqueDomesticPostageFeeKey;
};


export type QueryDomesticPostageFeesArgs = {
  filter?: Maybe<DomesticPostageFeeFilter>;
  orderBy?: Maybe<DomesticPostageFeeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDraftDeliveriesArgs = {
  filter?: Maybe<DraftDeliveryFilter>;
  orderBy?: Maybe<DraftDeliveryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDraftDeliveryArgs = {
  guid: Scalars['ID'];
};


export type QueryDraftDeliveryDetailArgs = {
  guid: Scalars['ID'];
};


export type QueryDraftOrderArgs = {
  guid: Scalars['ID'];
};


export type QueryDraftOrderOptionArgs = {
  guid: Scalars['ID'];
};


export type QueryDraftOrderOptionPropertyValueArgs = {
  guid: Scalars['ID'];
};


export type QueryDraftOrderOptionsArgs = {
  filter?: Maybe<DraftOrderOptionFilter>;
  orderBy?: Maybe<DraftOrderOptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDraftOrdersArgs = {
  filter?: Maybe<DraftOrderFilter>;
  orderBy?: Maybe<DraftOrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryEmailTemplateArgs = {
  templateName: Scalars['String'];
};


export type QueryGiftgivingHistoryArgs = {
  guid: Scalars['ID'];
};


export type QueryHandoverMessageArgs = {
  guid: Scalars['ID'];
};


export type QueryHandoverMessagesArgs = {
  filter?: Maybe<HandoverMessageFilter>;
  orderBy?: Maybe<HandoverMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryInformationArgs = {
  guid: Scalars['ID'];
};


export type QueryInformationsArgs = {
  filter?: Maybe<InformationFilter>;
  orderBy?: Maybe<InformationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryItemArgs = {
  key: FindUniqueItemKey;
};


export type QueryItemBrandArgs = {
  guid: Scalars['ID'];
};


export type QueryItemBrandsArgs = {
  filter?: Maybe<ItemBrandFilter>;
  orderBy?: Maybe<ItemBrandOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryItemCategoriesArgs = {
  filter?: Maybe<ItemCategoryFilter>;
  orderBy?: Maybe<ItemCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryItemCategoryArgs = {
  guid: Scalars['ID'];
};


export type QueryItemCombinationArgs = {
  guid: Scalars['ID'];
};


export type QueryItemCombinationsArgs = {
  filter?: Maybe<ItemCombinationFilter>;
  orderBy?: Maybe<ItemCombinationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryItemPriceRangeArgs = {
  guid: Scalars['ID'];
};


export type QueryItemPriceRangesArgs = {
  filter?: Maybe<ItemPriceRangeFilter>;
  orderBy?: Maybe<ItemPriceRangeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryItemsArgs = {
  filter?: Maybe<ItemFilter>;
  orderBy?: Maybe<ItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryMaterialImageArgs = {
  guid: Scalars['ID'];
};


export type QueryMaterialImagesArgs = {
  filter?: Maybe<MaterialImageFilter>;
  orderBy?: Maybe<MaterialImageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryMemberArgs = {
  key: FindUniqueMemberKey;
};


export type QueryMemberPropertyValueArgs = {
  guid: Scalars['ID'];
};


export type QueryMembersArgs = {
  filter?: Maybe<MemberFilter>;
  orderBy?: Maybe<MemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryNavsArgs = {
  guid: Scalars['ID'];
};


export type QueryOptionArgs = {
  guid: Scalars['ID'];
};


export type QueryOptionItemArgs = {
  guid: Scalars['ID'];
};


export type QueryOptionItemsArgs = {
  condition?: Maybe<OptionItemFindCondition>;
};


export type QueryOptionsArgs = {
  filter?: Maybe<OptionFilter>;
  orderBy?: Maybe<OptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  guid: Scalars['ID'];
};


export type QueryOrderOptionArgs = {
  guid: Scalars['ID'];
};


export type QueryOrderOptionPropertyValueArgs = {
  guid: Scalars['ID'];
};


export type QueryOrderRankingArgs = {
  filter?: Maybe<ItemRankingFilter>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  filter?: Maybe<OrderFilter>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryOriginalCardArgs = {
  guid: Scalars['ID'];
};


export type QueryOriginalCardsArgs = {
  filter?: Maybe<OriginalCardFilter>;
  orderBy?: Maybe<OriginalCardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPickupStoreArgs = {
  guid?: Maybe<Scalars['String']>;
};


export type QueryPointArgs = {
  guid: Scalars['ID'];
};


export type QueryPointHistoriesArgs = {
  filter?: Maybe<PointHistoryFilter>;
  orderBy?: Maybe<PointHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPointHistoryArgs = {
  guid: Scalars['ID'];
};


export type QueryPointRateArgs = {
  guid: Scalars['ID'];
};


export type QueryPointRatesArgs = {
  filter?: Maybe<PointRateFilter>;
  orderBy?: Maybe<PointRateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPointsArgs = {
  filter?: Maybe<PointFilter>;
  orderBy?: Maybe<PointOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPrefectureByPostalCodeArgs = {
  postalCode?: Maybe<Scalars['String']>;
};


export type QueryPropertyArgs = {
  guid: Scalars['ID'];
};


export type QueryPurposeArgs = {
  key: FindUniquePurposeKey;
};


export type QueryPurposesArgs = {
  filter?: Maybe<PurposeFilter>;
  orderBy?: Maybe<PurposeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryRelationshipArgs = {
  guid: Scalars['ID'];
};


export type QueryRelationshipsArgs = {
  filter?: Maybe<RelationshipFilter>;
  orderBy?: Maybe<RelationshipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySalesRankingArgs = {
  filter?: Maybe<ItemRankingFilter>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySearchOptionItemsByOptionTypeArgs = {
  optionType?: Maybe<OptionType>;
};


export type QueryShopArgs = {
  uniqueCondition?: Maybe<ShopFindUniqueCondition>;
};


export type QueryShopInformationArgs = {
  guid: Scalars['ID'];
};


export type QueryShopInformationsArgs = {
  filter?: Maybe<ShopInformationFilter>;
  orderBy?: Maybe<ShopInformationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type RankingRawRecord = {
  __typename?: 'RankingRawRecord';
  deliveryDetail?: Maybe<DeliveryDetail>;
  guid?: Maybe<Scalars['String']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  itemGuid?: Maybe<Scalars['String']>;
  orderGuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  purposeGuid?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sectionId?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  unitPrice?: Maybe<Scalars['Int']>;
  webCode?: Maybe<Scalars['String']>;
};

export type Relationship = {
  __typename?: 'Relationship';
  contacts?: Maybe<Array<Maybe<Contact>>>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  member?: Maybe<Member>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type RelationshipFilter = {
  memberGuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RelationshipOrderByInput = {
  createdAt?: Maybe<Sort>;
  name?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type SendCatalogRequestMailInput = {
  address?: Maybe<Scalars['String']>;
  catalogName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  yotei?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SendEmailInput = {
  fromAddress?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  toAddress?: Maybe<Scalars['String']>;
};

export type SendHikidemonoInquiryMailInput = {
  changeAddress1?: Maybe<Scalars['String']>;
  changeAddress2?: Maybe<Scalars['String']>;
  changeAddress3?: Maybe<Scalars['String']>;
  changeName1?: Maybe<Scalars['String']>;
  changeName2?: Maybe<Scalars['String']>;
  changeName3?: Maybe<Scalars['String']>;
  changePhone1?: Maybe<Scalars['String']>;
  changePhone2?: Maybe<Scalars['String']>;
  changePhone3?: Maybe<Scalars['String']>;
  changeZip1?: Maybe<Scalars['String']>;
  changeZip2?: Maybe<Scalars['String']>;
  changeZip3?: Maybe<Scalars['String']>;
  deliveryName1?: Maybe<Scalars['String']>;
  deliveryName2?: Maybe<Scalars['String']>;
  deliveryName3?: Maybe<Scalars['String']>;
  deliveryNo1?: Maybe<Scalars['String']>;
  deliveryNo2?: Maybe<Scalars['String']>;
  deliveryNo3?: Maybe<Scalars['String']>;
  deliverySelect1_1?: Maybe<Scalars['Boolean']>;
  deliverySelect1_2?: Maybe<Scalars['Boolean']>;
  deliverySelect1_3?: Maybe<Scalars['Boolean']>;
  deliverySelect2_1?: Maybe<Scalars['Boolean']>;
  deliverySelect2_2?: Maybe<Scalars['Boolean']>;
  deliverySelect2_3?: Maybe<Scalars['Boolean']>;
  deliverySelect3_1?: Maybe<Scalars['Boolean']>;
  deliverySelect3_2?: Maybe<Scalars['Boolean']>;
  deliverySelect3_3?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
};

export type SendInquiryMailInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type SendTemplatedEmailInput = {
  fromAddress?: Maybe<Scalars['String']>;
  templateData?: Maybe<Scalars['String']>;
  templateName: Scalars['String'];
  toAddress?: Maybe<Scalars['String']>;
};

export type Shop = {
  __typename?: 'Shop';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ShopFindUniqueCondition = {
  code?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
};

export type ShopInformation = {
  __typename?: 'ShopInformation';
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['ID'];
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ShopInformationFilter = {
  targetDate?: Maybe<Scalars['Timestamp']>;
};

export type ShopInformationOrderByInput = {
  createdAt?: Maybe<Sort>;
  publicationDate?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type ShopSetting = {
  __typename?: 'ShopSetting';
  contactAddress?: Maybe<Scalars['String']>;
  contactEmailAddress?: Maybe<Scalars['String']>;
  discountRateDefermentDays?: Maybe<Scalars['Int']>;
  guid: Scalars['ID'];
  maxOrderAmountForDeferredPayment?: Maybe<Scalars['Int']>;
  minOrderAmountForFreeShipping?: Maybe<Scalars['Int']>;
  pointGrantRate?: Maybe<Scalars['Float']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shopCode: Scalars['String'];
  shopName: Scalars['String'];
  specifiedCommercialTransactionAct?: Maybe<Scalars['String']>;
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc'
}

export enum TaxType {
  Normal = 'NORMAL',
  Reduced = 'REDUCED'
}

export type TestRenderEmailTemplateInput = {
  TemplateData?: Maybe<Scalars['String']>;
  TemplateName: Scalars['String'];
};


export type UpdateBundledItemsInput = {
  bundledItems?: Maybe<Array<Maybe<GuidInput>>>;
  guid: Scalars['String'];
};

export type UpdateCardTemplateInput = {
  cardTemplateProperties?: Maybe<Array<Maybe<UpsertCardTemplatePropertyInput>>>;
  defaultMessage?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  templateImageFileName?: Maybe<Scalars['String']>;
  templateImageUrl?: Maybe<Scalars['Url']>;
};

export type UpdateCardTemplatePropertyInput = {
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplateGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};

export type UpdateCartItemWebCodesInput = {
  guid?: Maybe<Scalars['ID']>;
  webCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateCatalogInput = {
  code?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateCenterMemberInput = {
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  isVisible?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shopGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateCommunicationMessageInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  notifyDate?: Maybe<Scalars['Date']>;
  orderGuid: Scalars['String'];
  readDate?: Maybe<Scalars['Date']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateContactInput = {
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  member?: Maybe<GuidInput>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<GuidInput>>>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateCouponDeliveryRuleInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateCouponHistoryInput = {
  couponAmount?: Maybe<Scalars['Int']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponExpirationDate?: Maybe<Scalars['Timestamp']>;
  couponGuid?: Maybe<Scalars['String']>;
  couponMinPriceToGain?: Maybe<Scalars['Int']>;
  couponMinPriceToUse?: Maybe<Scalars['Int']>;
  couponSlugName?: Maybe<Scalars['String']>;
  couponTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  guid: Scalars['String'];
  member?: Maybe<GuidInput>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateCouponInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  minPriceToGain?: Maybe<Scalars['Int']>;
  minPriceToUse?: Maybe<Scalars['Int']>;
  slugName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDeliverdCouponInput = {
  couponAmount?: Maybe<Scalars['Int']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponGuid?: Maybe<Scalars['String']>;
  couponMinPriceToGain?: Maybe<Scalars['Int']>;
  couponMinPriceToUse?: Maybe<Scalars['Int']>;
  couponSlugName?: Maybe<Scalars['String']>;
  couponTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  expirationDate?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  isUsed?: Maybe<Scalars['Boolean']>;
  memberGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  usedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDeliveryCalendarDayInput = {
  date: Scalars['Date'];
  guid: Scalars['String'];
  type: DeliveryCalendarDayType;
};

export type UpdateDeliveryDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catchCopy?: Maybe<Scalars['String']>;
  categoryGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  daishinCode?: Maybe<Scalars['String']>;
  deliveryGuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  guid: Scalars['String'];
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  itemGuid?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  orderOptions?: Maybe<Array<Maybe<UpsertOrderOptionInput>>>;
  postage?: Maybe<Scalars['Int']>;
  postageWithTax?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priceWithTax?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  quantity?: Maybe<Scalars['Int']>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  unitPrice?: Maybe<Scalars['Int']>;
  unitPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  webCode?: Maybe<Scalars['String']>;
};

export type UpdateDeliveryInput = {
  contactGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  deliveryDetails?: Maybe<Array<Maybe<UpsertDeliveryDetailInput>>>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  deliverySlipNo?: Maybe<Scalars['String']>;
  deliveryTraderNo?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  guid: Scalars['String'];
  orderGuid?: Maybe<Scalars['String']>;
  orderOptions?: Maybe<Array<Maybe<UpsertOrderOptionInput>>>;
  postageFee?: Maybe<Scalars['Int']>;
  postageFeeWithTax?: Maybe<Scalars['Int']>;
  purposeGuid?: Maybe<Scalars['String']>;
  srcDeliveryGuid?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDeliverySlipNoInput = {
  deliverySlipNo?: Maybe<Scalars['String']>;
  deliveryTraderNo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateDiscountRateInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit?: Maybe<Scalars['Int']>;
};

export type UpdateDomesticPostageFeeInput = {
  amount?: Maybe<Scalars['Int']>;
  amountForHikidemono?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  prefecture?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDraftDeliveryDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery?: Maybe<GuidInput>;
  draftOrderOptions?: Maybe<Array<Maybe<GuidInput>>>;
  guid: Scalars['String'];
  itemGuid: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDraftDeliveryInput = {
  contact?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  draftDeliveryDetails?: Maybe<Array<Maybe<UpsertDraftDeliveryDetailInput>>>;
  draftOrder: GuidInput;
  draftOrderOptions?: Maybe<Array<Maybe<UpsertDraftOrderOptionInput>>>;
  guid: Scalars['String'];
  itemCombinationQuantity?: Maybe<Scalars['Int']>;
  purposeGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDraftOrderInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddressType?: Maybe<DeliveryAddressType>;
  draftOrderOptions?: Maybe<Array<Maybe<GuidInput>>>;
  guid: Scalars['String'];
  itemCombinations?: Maybe<Array<Maybe<GuidInput>>>;
  memberGuid?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  orderNote?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<GuidInput>>>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderNameKana?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDraftOrderOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery?: Maybe<GuidInput>;
  draftDeliveryDetail?: Maybe<GuidInput>;
  draftOrderOptionPropertyValues?: Maybe<Array<Maybe<UpsertDraftOrderOptionPropertyValueInput>>>;
  guid: Scalars['String'];
  optionExpressionName?: Maybe<Scalars['String']>;
  optionGuid?: Maybe<Scalars['String']>;
  optionItemGuid?: Maybe<Scalars['String']>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateDraftOrderOptionPropertyValueInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftOrderOptionGuid?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  propertyGuid?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateGiftgivingHistoryInput = {
  amount?: Maybe<Scalars['Int']>;
  contactGuid: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  description?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  itemName?: Maybe<Scalars['String']>;
  returnPresentItemName?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateHandoverMessageInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  orderGuid: Scalars['String'];
  readDate?: Maybe<Scalars['Date']>;
  senderName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateInformationInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  publicationDate: Scalars['Timestamp'];
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateItemBrandInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateItemCategoryInput = {
  aliasName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentGuid?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateItemCombinationDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  itemCombinationGuid?: Maybe<Scalars['String']>;
  itemCombinationOptions?: Maybe<Array<Maybe<UpsertItemCombinationOptionInput>>>;
  itemGuid?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateItemCombinationInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  itemCombinationDetails?: Maybe<Array<Maybe<UpsertItemCombinationDetailInput>>>;
  itemCombinationOptions?: Maybe<Array<Maybe<UpsertItemCombinationOptionInput>>>;
  member?: Maybe<GuidInput>;
  name?: Maybe<Scalars['String']>;
  purposeGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateItemInput = {
  affiliatePrice?: Maybe<Scalars['Int']>;
  brands?: Maybe<Array<Maybe<GuidInput>>>;
  bundledItems?: Maybe<Array<Maybe<GuidInput>>>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catalogUrl?: Maybe<Scalars['String']>;
  catalogs?: Maybe<Array<Maybe<GuidInput>>>;
  catchCopy?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<GuidInput>>>;
  character?: Maybe<GuidInput>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  daishinCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  guid: Scalars['String'];
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageFileName01?: Maybe<Scalars['String']>;
  imageFileName02?: Maybe<Scalars['String']>;
  imageFileName03?: Maybe<Scalars['String']>;
  imageFileName04?: Maybe<Scalars['String']>;
  imageFileName05?: Maybe<Scalars['String']>;
  imageFileName06?: Maybe<Scalars['String']>;
  imageFileName07?: Maybe<Scalars['String']>;
  imageFileName08?: Maybe<Scalars['String']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  onlyForMember?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<GuidInput>>>;
  originalPrice?: Maybe<Scalars['Int']>;
  postage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  purposes?: Maybe<Array<Maybe<GuidInput>>>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  specialMemberPrice?: Maybe<Scalars['Int']>;
  specialPrice?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  webCode?: Maybe<Scalars['String']>;
};

export type UpdateItemPriceRangeInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit?: Maybe<Scalars['Int']>;
};

export type UpdateMaterialImageInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  memberGuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateMemberInput = {
  accountNumberBC?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNameKana?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  emailAuthSub?: Maybe<Scalars['String']>;
  facebookAuthSub?: Maybe<Scalars['String']>;
  googleAuthSub?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  hasSubscriptionToMailzine?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  lineAuthSub?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  expressionName?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  items?: Maybe<Array<Maybe<GuidInput>>>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optionItems?: Maybe<Array<Maybe<GuidInput>>>;
  optionType?: Maybe<OptionType>;
  purposes?: Maybe<Array<Maybe<GuidInput>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateOptionItemInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oldPackingId?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  properties?: Maybe<Array<Maybe<GuidInput>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateOrderCreditInput = {
  cardAccessId?: Maybe<Scalars['String']>;
  cardAccessPass?: Maybe<Scalars['String']>;
  cardResult?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
};

export type UpdateOrderInput = {
  chargePrice?: Maybe<Scalars['Int']>;
  chargePriceWithTax?: Maybe<Scalars['Int']>;
  couponDiscountPrice?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveries?: Maybe<Array<Maybe<UpsertDeliveryInput>>>;
  discountPrice?: Maybe<Scalars['Int']>;
  discountPriceWithTax?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Float']>;
  guid: Scalars['String'];
  memberGuid?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  orderNote?: Maybe<Scalars['String']>;
  orderOptins?: Maybe<Array<Maybe<UpsertOrderOptionInput>>>;
  paymentMethodType?: Maybe<PaymentMethodType>;
  pointDiscountPrice?: Maybe<Scalars['Int']>;
  pointGainPrice?: Maybe<Scalars['Int']>;
  pointGainRate?: Maybe<Scalars['Float']>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderNameKana?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  status?: Maybe<OrderState>;
  subTotalPriceNotOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceNotOnDiscountWithTax?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscountWithTax?: Maybe<Scalars['Int']>;
  totalPostageFee?: Maybe<Scalars['Int']>;
  totalPostageFeeWithTax?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTax?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateOrderOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  delivery?: Maybe<GuidInput>;
  deliveryDetail?: Maybe<GuidInput>;
  guid: Scalars['String'];
  optionExpressionName?: Maybe<Scalars['String']>;
  optionGuid?: Maybe<Scalars['String']>;
  optionItemGuid?: Maybe<Scalars['String']>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  orderOptionPropertyValues?: Maybe<Array<Maybe<UpsertOrderOptionPropertyValueInput>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateOrderOptionPropertyValueInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderOptionGuid?: Maybe<Scalars['String']>;
  propertyGuid?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateOrderPaymentMethodInput = {
  guid: Scalars['String'];
  paymentMethodType?: Maybe<PaymentMethodType>;
  status?: Maybe<OrderState>;
};

export type UpdateOriginalCardInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  memberGuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdatePickupStoreInput = {
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdatePointHistoryInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  debitCreditType?: Maybe<DebitCreditType>;
  description?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  memberGuid?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdatePointInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  memberGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdatePointRateInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  upperLimit?: Maybe<Scalars['Int']>;
};

export type UpdatePropertyInput = {
  cautionText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  placeholderText?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdatePurposeInput = {
  categoryName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  freeShippingLine?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<GuidInput>>>;
  purposeNo?: Maybe<Scalars['Int']>;
  slugName?: Maybe<Scalars['String']>;
};

export type UpdateRelationshipInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  memberGuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateShopInformationInput = {
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateShopInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateShopSettingInput = {
  contactAddress?: Maybe<Scalars['String']>;
  contactEmailAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  discountRateDefermentDays?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  maxOrderAmountForDeferredPayment?: Maybe<Scalars['Int']>;
  minOrderAmountForFreeShipping?: Maybe<Scalars['Int']>;
  pointGrantRate?: Maybe<Scalars['Float']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shopCode?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  specifiedCommercialTransactionAct?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertCardTemplatePropertyInput = {
  align?: Maybe<Scalars['String']>;
  bold?: Maybe<Scalars['Int']>;
  cardTemplateGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fontColor?: Maybe<Scalars['Color']>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  width?: Maybe<Scalars['Int']>;
};

export type UpsertCatalogInput = {
  code?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpsertContactInput = {
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  cellPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  member?: Maybe<GuidInput>;
  memo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertDeliveryDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catchCopy?: Maybe<Scalars['String']>;
  categoryGuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  daishinCode?: Maybe<Scalars['String']>;
  deliveryGuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['String']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  itemGuid?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  orderOptions?: Maybe<Array<Maybe<UpsertOrderOptionInput>>>;
  postage?: Maybe<Scalars['Int']>;
  postageWithTax?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priceWithTax?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  quantity?: Maybe<Scalars['Int']>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  untPrice?: Maybe<Scalars['Int']>;
  untPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  webCode?: Maybe<Scalars['String']>;
};

export type UpsertDeliveryInput = {
  contactGuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryAddressType?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  deliveryDetails?: Maybe<Array<Maybe<UpsertDeliveryDetailInput>>>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryPhone?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryPrefecture?: Maybe<Scalars['String']>;
  deliverySlipNo?: Maybe<Scalars['String']>;
  deliveryTraderNo?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['String']>;
  orderGuid?: Maybe<Scalars['String']>;
  orderOptions?: Maybe<Array<Maybe<UpsertOrderOptionInput>>>;
  postageFee?: Maybe<Scalars['Int']>;
  postageFeeWithTax?: Maybe<Scalars['Int']>;
  purposeGuid?: Maybe<Scalars['String']>;
  srcDeliveryGuid?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertDraftDeliveryDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery?: Maybe<GuidInput>;
  draftOrderOptions?: Maybe<Array<Maybe<UpsertDraftOrderOptionInput>>>;
  guid?: Maybe<Scalars['String']>;
  itemGuid: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertDraftOrderOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftDelivery?: Maybe<GuidInput>;
  draftDeliveryDetail?: Maybe<GuidInput>;
  draftOrderOptionPropertyValues?: Maybe<Array<Maybe<UpsertDraftOrderOptionPropertyValueInput>>>;
  guid?: Maybe<Scalars['String']>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionGuid?: Maybe<Scalars['String']>;
  optionItemGuid?: Maybe<Scalars['String']>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertDraftOrderOptionPropertyValueInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  draftOrderOptionGuid?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  propertyGuid?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type UpsertEmailTemplateInput = {
  HtmlPart?: Maybe<Scalars['String']>;
  SubjectPart?: Maybe<Scalars['String']>;
  TemplateName: Scalars['String'];
  TextPart?: Maybe<Scalars['String']>;
};

export type UpsertItemBrandInput = {
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  imageFileName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
};

export type UpsertItemCategoryInput = {
  aliasName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentGuid?: Maybe<Scalars['String']>;
  slugName: Scalars['String'];
};

export type UpsertItemCombinationDetailInput = {
  addNoshi?: Maybe<Scalars['Boolean']>;
  bundledWithDetail?: Maybe<GuidInput>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid?: Maybe<Scalars['String']>;
  itemCombinationGuid?: Maybe<Scalars['String']>;
  itemCombinationOptions?: Maybe<Array<Maybe<UpsertItemCombinationOptionInput>>>;
  itemGuid?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertItemCombinationInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveryDate?: Maybe<Scalars['Timestamp']>;
  guid?: Maybe<Scalars['String']>;
  itemCombinationDetails?: Maybe<Array<Maybe<UpsertItemCombinationDetailInput>>>;
  itemCombinationOptions?: Maybe<Array<Maybe<UpsertItemCombinationOptionInput>>>;
  member?: Maybe<GuidInput>;
  name?: Maybe<Scalars['String']>;
  purposeGuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertItemCombinationOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid?: Maybe<Scalars['String']>;
  itemCombinationDetailGuid?: Maybe<Scalars['String']>;
  itemCombinationGuid?: Maybe<Scalars['String']>;
  itemCombinationOptionPropertyValues?: Maybe<Array<Maybe<UpsertItemCombinationOptionPropertyValueInput>>>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionGuid?: Maybe<Scalars['String']>;
  optionItemGuid?: Maybe<Scalars['String']>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertItemCombinationOptionPropertyValueInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  itemCombinationOptionGuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  propertyGuid?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type UpsertItemInput = {
  affiliatePrice?: Maybe<Scalars['Int']>;
  brands?: Maybe<Array<Maybe<GuidInput>>>;
  bundledItems?: Maybe<Array<Maybe<GuidInput>>>;
  canPickupAtStore?: Maybe<Scalars['Boolean']>;
  cannotBeAddedMessageCard?: Maybe<Scalars['Boolean']>;
  cannotBeAddedNoshi?: Maybe<Scalars['Boolean']>;
  cannotBeAddedWarppingPaper?: Maybe<Scalars['Boolean']>;
  catalogPage?: Maybe<Scalars['String']>;
  catalogUrl?: Maybe<Scalars['String']>;
  catalogs?: Maybe<Array<Maybe<GuidInput>>>;
  catchCopy?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<GuidInput>>>;
  character?: Maybe<GuidInput>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  daishinCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  forPrivateUse?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['String']>;
  hasPersonalizedProperty?: Maybe<Scalars['Boolean']>;
  imageFileName01?: Maybe<Scalars['String']>;
  imageFileName02?: Maybe<Scalars['String']>;
  imageFileName03?: Maybe<Scalars['String']>;
  imageFileName04?: Maybe<Scalars['String']>;
  imageFileName05?: Maybe<Scalars['String']>;
  imageFileName06?: Maybe<Scalars['String']>;
  imageFileName07?: Maybe<Scalars['String']>;
  imageFileName08?: Maybe<Scalars['String']>;
  imageUrl01?: Maybe<Scalars['String']>;
  imageUrl02?: Maybe<Scalars['String']>;
  imageUrl03?: Maybe<Scalars['String']>;
  imageUrl04?: Maybe<Scalars['String']>;
  imageUrl05?: Maybe<Scalars['String']>;
  imageUrl06?: Maybe<Scalars['String']>;
  imageUrl07?: Maybe<Scalars['String']>;
  imageUrl08?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFresh?: Maybe<Scalars['Boolean']>;
  isPostageFree?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  janCode?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  makerCode?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noshiSize?: Maybe<Scalars['String']>;
  onDiscount?: Maybe<Scalars['Boolean']>;
  onlyForCatalogOrder?: Maybe<Scalars['Boolean']>;
  onlyForMember?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<GuidInput>>>;
  originalPrice?: Maybe<Scalars['Int']>;
  postage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  publicationDate?: Maybe<Scalars['Timestamp']>;
  publicationEndDate?: Maybe<Scalars['Timestamp']>;
  purposes?: Maybe<Array<Maybe<GuidInput>>>;
  sectionId?: Maybe<Scalars['String']>;
  sellerCode?: Maybe<Scalars['String']>;
  sendDirectly?: Maybe<Scalars['Boolean']>;
  sendday?: Maybe<Scalars['Int']>;
  specialMemberPrice?: Maybe<Scalars['Int']>;
  specialPrice?: Maybe<Scalars['Int']>;
  stockQuantity?: Maybe<Scalars['Int']>;
  taxType?: Maybe<TaxType>;
  webCode?: Maybe<Scalars['String']>;
};

export type UpsertOrderInput = {
  chargePrice?: Maybe<Scalars['Int']>;
  chargePriceWithTax?: Maybe<Scalars['Int']>;
  couponDiscountPrice?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deliveries?: Maybe<Array<Maybe<UpsertDeliveryInput>>>;
  discountPrice?: Maybe<Scalars['Int']>;
  discountPriceWithTax?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Float']>;
  guid?: Maybe<Scalars['String']>;
  memberGuid?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  orderNote?: Maybe<Scalars['String']>;
  orderOptins?: Maybe<Array<Maybe<UpsertOrderOptionInput>>>;
  paymentMethodType?: Maybe<PaymentMethodType>;
  pointDiscountPrice?: Maybe<Scalars['Int']>;
  pointGainPrice?: Maybe<Scalars['Int']>;
  pointGainRate?: Maybe<Scalars['Float']>;
  senderAddress1?: Maybe<Scalars['String']>;
  senderAddress2?: Maybe<Scalars['String']>;
  senderAddress3?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderNameKana?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderPostalCode?: Maybe<Scalars['String']>;
  senderPrefecture?: Maybe<Scalars['String']>;
  status?: Maybe<OrderState>;
  subTotalPriceNotOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceNotOnDiscountWithTax?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscount?: Maybe<Scalars['Int']>;
  subTotalPriceOnDiscountWithTax?: Maybe<Scalars['Int']>;
  totalPostageFee?: Maybe<Scalars['Int']>;
  totalPostageFeeWithTax?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTax?: Maybe<Scalars['Int']>;
  totalPriceOnNormalTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTax?: Maybe<Scalars['Int']>;
  totalPriceOnReducedTaxWithTax?: Maybe<Scalars['Int']>;
  totalPriceWithTax?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['Timestamp']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertOrderOptionInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  delivery?: Maybe<GuidInput>;
  deliveryDetail?: Maybe<GuidInput>;
  guid?: Maybe<Scalars['String']>;
  optionExpressionName?: Maybe<Scalars['String']>;
  optionGuid?: Maybe<Scalars['String']>;
  optionItemGuid?: Maybe<Scalars['String']>;
  optionItemImageUrl?: Maybe<Scalars['String']>;
  optionItemName?: Maybe<Scalars['String']>;
  optionMemo?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  optionType?: Maybe<OptionType>;
  orderOptionPropertyValues?: Maybe<Array<Maybe<UpsertOrderOptionPropertyValueInput>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type UpsertOrderOptionPropertyValueInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderOptionGuid?: Maybe<Scalars['String']>;
  propertyGuid?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyType>;
  slugName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  value?: Maybe<Scalars['String']>;
};

export type UpsertPurposeInput = {
  categoryName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  freeShippingLine?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  purposeNo?: Maybe<Scalars['Int']>;
  slugName?: Maybe<Scalars['String']>;
};

export type UpsertRelationshipInput = {
  createdAt?: Maybe<Scalars['Timestamp']>;
  guid?: Maybe<Scalars['String']>;
  memberGuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};


export type CreateCatalogMutationVariables = Exact<{
  createCatalogInput?: Maybe<CreateCatalogInput>;
}>;


export type CreateCatalogMutation = (
  { __typename?: 'Mutation' }
  & { createCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateCommunicationMessageMutationVariables = Exact<{
  input?: Maybe<CreateCommunicationMessageInput>;
}>;


export type CreateCommunicationMessageMutation = (
  { __typename?: 'Mutation' }
  & { createCommunicationMessage?: Maybe<(
    { __typename?: 'CommunicationMessage' }
    & Pick<CommunicationMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )> }
);

export type CreateCouponMutationVariables = Exact<{
  createCouponInput?: Maybe<CreateCouponInput>;
}>;


export type CreateCouponMutation = (
  { __typename?: 'Mutation' }
  & { createCoupon?: Maybe<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'guid' | 'title' | 'slugName' | 'amount' | 'description' | 'minPriceToGain' | 'minPriceToUse' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateDeliverdCouponMutationVariables = Exact<{
  input?: Maybe<CreateDeliverdCouponInput>;
}>;


export type CreateDeliverdCouponMutation = (
  { __typename?: 'Mutation' }
  & { createDeliverdCoupon?: Maybe<(
    { __typename?: 'DeliverdCoupon' }
    & Pick<DeliverdCoupon, 'guid' | 'expirationDate' | 'couponGuid' | 'couponTitle' | 'couponSlugName' | 'couponAmount' | 'couponDescription' | 'couponMinPriceToGain' | 'couponMinPriceToUse' | 'createdAt' | 'updatedAt'>
    & { coupon?: Maybe<(
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'guid'>
    )>, member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid'>
    )> }
  )> }
);

export type CreateDiscountRateMutationVariables = Exact<{
  createDiscountRateInput?: Maybe<CreateDiscountRateInput>;
}>;


export type CreateDiscountRateMutation = (
  { __typename?: 'Mutation' }
  & { createDiscountRate?: Maybe<(
    { __typename?: 'DiscountRate' }
    & Pick<DiscountRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt'>
  )> }
);

export type CreateDomesticPostageFeeMutationVariables = Exact<{
  createDomesticPostageFeeInput?: Maybe<CreateDomesticPostageFeeInput>;
}>;


export type CreateDomesticPostageFeeMutation = (
  { __typename?: 'Mutation' }
  & { createDomesticPostageFee?: Maybe<(
    { __typename?: 'DomesticPostageFee' }
    & Pick<DomesticPostageFee, 'guid' | 'prefecture' | 'amount' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateHandoverMessageMutationVariables = Exact<{
  input?: Maybe<CreateHandoverMessageInput>;
}>;


export type CreateHandoverMessageMutation = (
  { __typename?: 'Mutation' }
  & { createHandoverMessage?: Maybe<(
    { __typename?: 'HandoverMessage' }
    & Pick<HandoverMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )> }
);

export type CreateItemMutationVariables = Exact<{
  createItemInput?: Maybe<CreateItemInput>;
}>;


export type CreateItemMutation = (
  { __typename?: 'Mutation' }
  & { createItem?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'guid' | 'name' | 'webCode' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId' | 'onlyForCatalogOrder' | 'isFresh' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'canPickupAtStore' | 'isPostageFree' | 'sendDirectly' | 'noshiSize' | 'catalogPage' | 'content' | 'keyword' | 'catchCopy' | 'comment' | 'description' | 'memo' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'catalogUrl' | 'minimumOrderQuantity' | 'stockQuantity' | 'forPrivateUse' | 'taxType' | 'price' | 'postage' | 'publicationDate' | 'publicationEndDate' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'onDiscount' | 'originalPrice' | 'specialPrice' | 'affiliatePrice' | 'specialMemberPrice' | 'onlyForMember' | 'isAvailable' | 'sendday'>
    & { purposes?: Maybe<Array<Maybe<(
      { __typename?: 'Purpose' }
      & Pick<Purpose, 'guid' | 'name' | 'slugName' | 'categoryName' | 'displayOrder' | 'updatedAt' | 'createdAt'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>>>, character?: Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>>, brands?: Maybe<Array<Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>>>, catalogs?: Maybe<Array<Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
    )>>>, bundledItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>>, bundledWithItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>> }
  )> }
);

export type CreateItemBrandMutationVariables = Exact<{
  createItemBrandInput?: Maybe<CreateItemBrandInput>;
}>;


export type CreateItemBrandMutation = (
  { __typename?: 'Mutation' }
  & { createItemBrand?: Maybe<(
    { __typename?: 'ItemBrand' }
    & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
  )> }
);

export type CreateItemCategoryMutationVariables = Exact<{
  createItemCategoryInput?: Maybe<CreateItemCategoryInput>;
}>;


export type CreateItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createItemCategory?: Maybe<(
    { __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    & { parent?: Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      & { parent?: Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
        & { parent?: Maybe<(
          { __typename?: 'ItemCategory' }
          & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          & { parent?: Maybe<(
            { __typename?: 'ItemCategory' }
            & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          )> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type CreateItemPriceRangeMutationVariables = Exact<{
  createItemPriceRangeInput?: Maybe<CreateItemPriceRangeInput>;
}>;


export type CreateItemPriceRangeMutation = (
  { __typename?: 'Mutation' }
  & { createItemPriceRange?: Maybe<(
    { __typename?: 'ItemPriceRange' }
    & Pick<ItemPriceRange, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateMemberMutationVariables = Exact<{
  createMemberInput?: Maybe<CreateMemberInput>;
}>;


export type CreateMemberMutation = (
  { __typename?: 'Mutation' }
  & { createMember?: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateOptionMutationVariables = Exact<{
  createOptionInput?: Maybe<CreateOptionInput>;
}>;


export type CreateOptionMutation = (
  { __typename?: 'Mutation' }
  & { createOption?: Maybe<(
    { __typename?: 'Option' }
    & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    & { optionItems?: Maybe<Array<Maybe<(
      { __typename?: 'OptionItem' }
      & Pick<OptionItem, 'guid' | 'name' | 'displayOrder' | 'imageUrl' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type CreateOptionItemMutationVariables = Exact<{
  createOptionItemInput?: Maybe<CreateOptionItemInput>;
}>;


export type CreateOptionItemMutation = (
  { __typename?: 'Mutation' }
  & { createOptionItem?: Maybe<(
    { __typename?: 'OptionItem' }
    & Pick<OptionItem, 'guid' | 'displayOrder' | 'imageUrl' | 'name' | 'optionType' | 'oldPackingId' | 'createdAt' | 'updatedAt'>
    & { properties?: Maybe<Array<Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type CreatePointRateMutationVariables = Exact<{
  createPointRateInput?: Maybe<CreatePointRateInput>;
}>;


export type CreatePointRateMutation = (
  { __typename?: 'Mutation' }
  & { createPointRate?: Maybe<(
    { __typename?: 'PointRate' }
    & Pick<PointRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt'>
  )> }
);

export type CreatePropertyMutationVariables = Exact<{
  createPropertyInput?: Maybe<CreatePropertyInput>;
}>;


export type CreatePropertyMutation = (
  { __typename?: 'Mutation' }
  & { createProperty?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreatePurposeMutationVariables = Exact<{
  createPurposeInput?: Maybe<CreatePurposeInput>;
}>;


export type CreatePurposeMutation = (
  { __typename?: 'Mutation' }
  & { createPurpose?: Maybe<(
    { __typename?: 'Purpose' }
    & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type CreateShopInformationMutationVariables = Exact<{
  createShopInformationInput?: Maybe<CreateShopInformationInput>;
}>;


export type CreateShopInformationMutation = (
  { __typename?: 'Mutation' }
  & { createShopInformation?: Maybe<(
    { __typename?: 'ShopInformation' }
    & Pick<ShopInformation, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateShopSettingMutationVariables = Exact<{
  input?: Maybe<CreateShopSettingInput>;
}>;


export type CreateShopSettingMutation = (
  { __typename?: 'Mutation' }
  & { createShopSetting?: Maybe<(
    { __typename?: 'ShopSetting' }
    & Pick<ShopSetting, 'guid' | 'discountRateDefermentDays' | 'contactEmailAddress' | 'maxOrderAmountForDeferredPayment' | 'minOrderAmountForFreeShipping' | 'pointGrantRate' | 'privacyPolicy' | 'shopCode' | 'shopName' | 'specifiedCommercialTransactionAct' | 'contactAddress'>
  )> }
);

export type DeleteCatalogMutationVariables = Exact<{
  deleteCatalogInput?: Maybe<DeleteCatalogInput>;
}>;


export type DeleteCatalogMutation = (
  { __typename?: 'Mutation' }
  & { deleteCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteCommunicationMessageMutationVariables = Exact<{
  input?: Maybe<DeleteCommunicationMessageInput>;
}>;


export type DeleteCommunicationMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteCommunicationMessage?: Maybe<(
    { __typename?: 'CommunicationMessage' }
    & Pick<CommunicationMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteCouponMutationVariables = Exact<{
  deleteCouponInput?: Maybe<DeleteCouponInput>;
}>;


export type DeleteCouponMutation = (
  { __typename?: 'Mutation' }
  & { deleteCoupon?: Maybe<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'guid' | 'title' | 'slugName' | 'amount' | 'description' | 'minPriceToGain' | 'minPriceToUse' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteDeliverdCouponMutationVariables = Exact<{
  deleteDeliverdCouponInput?: Maybe<DeleteDeliverdCouponInput>;
}>;


export type DeleteDeliverdCouponMutation = (
  { __typename?: 'Mutation' }
  & { deleteDeliverdCoupon?: Maybe<(
    { __typename?: 'DeliverdCoupon' }
    & Pick<DeliverdCoupon, 'guid' | 'expirationDate' | 'couponGuid' | 'couponTitle' | 'couponSlugName' | 'couponAmount' | 'couponDescription' | 'couponMinPriceToGain' | 'couponMinPriceToUse' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteDiscountRateMutationVariables = Exact<{
  deleteDiscountRateInput?: Maybe<DeleteDiscountRateInput>;
}>;


export type DeleteDiscountRateMutation = (
  { __typename?: 'Mutation' }
  & { deleteDiscountRate?: Maybe<(
    { __typename?: 'DiscountRate' }
    & Pick<DiscountRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt'>
  )> }
);

export type DeleteDomesticPostageFeeMutationVariables = Exact<{
  deleteDomesticPostageFeeInput?: Maybe<DeleteDomesticPostageFeeInput>;
}>;


export type DeleteDomesticPostageFeeMutation = (
  { __typename?: 'Mutation' }
  & { deleteDomesticPostageFee?: Maybe<(
    { __typename?: 'DomesticPostageFee' }
    & Pick<DomesticPostageFee, 'guid' | 'prefecture' | 'amount' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteHandoverMessageMutationVariables = Exact<{
  input?: Maybe<DeleteHandoverMessageInput>;
}>;


export type DeleteHandoverMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteHandoverMessage?: Maybe<(
    { __typename?: 'HandoverMessage' }
    & Pick<HandoverMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'readDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteItemMutationVariables = Exact<{
  deleteItemInput?: Maybe<DeleteItemInput>;
}>;


export type DeleteItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteItem?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'guid' | 'name' | 'webCode' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId' | 'onlyForCatalogOrder' | 'isFresh' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'canPickupAtStore' | 'isPostageFree' | 'sendDirectly' | 'noshiSize' | 'catalogPage' | 'content' | 'keyword' | 'catchCopy' | 'comment' | 'description' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'forPrivateUse' | 'taxType' | 'price' | 'postage' | 'publicationDate' | 'publicationEndDate' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'onDiscount' | 'originalPrice' | 'specialPrice' | 'affiliatePrice' | 'specialMemberPrice' | 'onlyForMember' | 'isAvailable' | 'catalogUrl' | 'sendday'>
  )> }
);

export type DeleteItemBrandMutationVariables = Exact<{
  deleteItemBrandInput?: Maybe<DeleteItemBrandInput>;
}>;


export type DeleteItemBrandMutation = (
  { __typename?: 'Mutation' }
  & { deleteItemBrand?: Maybe<(
    { __typename?: 'ItemBrand' }
    & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
  )> }
);

export type DeleteItemCategoryMutationVariables = Exact<{
  deleteItemCategoryInput?: Maybe<DeleteItemCategoryInput>;
}>;


export type DeleteItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteItemCategory?: Maybe<(
    { __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'isAvailable' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteItemPriceRangeMutationVariables = Exact<{
  deleteItemPriceRangeInput?: Maybe<DeleteItemPriceRangeInput>;
}>;


export type DeleteItemPriceRangeMutation = (
  { __typename?: 'Mutation' }
  & { deleteItemPriceRange?: Maybe<(
    { __typename?: 'ItemPriceRange' }
    & Pick<ItemPriceRange, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteMemberMutationVariables = Exact<{
  deleteMemberInput?: Maybe<DeleteMemberInput>;
}>;


export type DeleteMemberMutation = (
  { __typename?: 'Mutation' }
  & { deleteMember?: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteOptionMutationVariables = Exact<{
  deleteOptionInput?: Maybe<DeleteOptionInput>;
}>;


export type DeleteOptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteOption?: Maybe<(
    { __typename?: 'Option' }
    & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteOptionItemMutationVariables = Exact<{
  deleteOptionItemInput?: Maybe<DeleteOptionItemInput>;
}>;


export type DeleteOptionItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteOptionItem?: Maybe<(
    { __typename?: 'OptionItem' }
    & Pick<OptionItem, 'guid' | 'displayOrder' | 'name' | 'optionType' | 'oldPackingId' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeletePointRateMutationVariables = Exact<{
  deletePointRateInput?: Maybe<DeletePointRateInput>;
}>;


export type DeletePointRateMutation = (
  { __typename?: 'Mutation' }
  & { deletePointRate?: Maybe<(
    { __typename?: 'PointRate' }
    & Pick<PointRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt'>
  )> }
);

export type DeletePropertyMutationVariables = Exact<{
  deletePropertyInput?: Maybe<DeletePropertyInput>;
}>;


export type DeletePropertyMutation = (
  { __typename?: 'Mutation' }
  & { deleteProperty?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeletePurposeMutationVariables = Exact<{
  deletePurposeInput?: Maybe<DeletePurposeInput>;
}>;


export type DeletePurposeMutation = (
  { __typename?: 'Mutation' }
  & { deletePurpose?: Maybe<(
    { __typename?: 'Purpose' }
    & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type DeleteShopInformationMutationVariables = Exact<{
  deleteShopInformationInput?: Maybe<DeleteShopInformationInput>;
}>;


export type DeleteShopInformationMutation = (
  { __typename?: 'Mutation' }
  & { deleteShopInformation?: Maybe<(
    { __typename?: 'ShopInformation' }
    & Pick<ShopInformation, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type LaunchExportItemsMutationVariables = Exact<{ [key: string]: never; }>;


export type LaunchExportItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'launchExportItems'>
);

export type LaunchImportItemsMutationVariables = Exact<{ [key: string]: never; }>;


export type LaunchImportItemsMutation = (
  { __typename?: 'Mutation' }
  & { launchImportItems?: Maybe<Array<Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'guid' | 'name' | 'webCode' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId' | 'onlyForCatalogOrder' | 'isFresh' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'canPickupAtStore' | 'isPostageFree' | 'sendDirectly' | 'noshiSize' | 'catalogPage' | 'content' | 'keyword' | 'catchCopy' | 'comment' | 'description' | 'memo' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'minimumOrderQuantity' | 'stockQuantity' | 'forPrivateUse' | 'taxType' | 'price' | 'postage' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt' | 'onDiscount' | 'originalPrice' | 'specialPrice' | 'affiliatePrice' | 'specialMemberPrice' | 'onlyForMember' | 'isAvailable'>
    & { purposes?: Maybe<Array<Maybe<(
      { __typename?: 'Purpose' }
      & Pick<Purpose, 'guid' | 'name' | 'slugName' | 'categoryName' | 'displayOrder' | 'updatedAt' | 'createdAt'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt'>
    )>>>, character?: Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>>, brands?: Maybe<Array<Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>>>, catalogs?: Maybe<Array<Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
    )>>>, bundledItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>>, bundledWithItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>> }
  )>>> }
);

export type NotifyMessageCardDesingCommunicationMessageMutationVariables = Exact<{
  input?: Maybe<NotifyCommunicationMessageInput>;
}>;


export type NotifyMessageCardDesingCommunicationMessageMutation = (
  { __typename?: 'Mutation' }
  & { notifyMessageCardDesingCommunicationMessage?: Maybe<(
    { __typename?: 'CommunicationMessage' }
    & Pick<CommunicationMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )> }
);

export type TestRenderEmailTemplateMutationVariables = Exact<{
  input?: Maybe<TestRenderEmailTemplateInput>;
}>;


export type TestRenderEmailTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'testRenderEmailTemplate'>
);

export type TransitionToCanceledMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToCanceledMutation = (
  { __typename?: 'Mutation' }
  & { transitionToCanceled?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToDeletedMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToDeletedMutation = (
  { __typename?: 'Mutation' }
  & { transitionToDeleted?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'isAvailable' | 'sex' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToDeliveryCompletedMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToDeliveryCompletedMutation = (
  { __typename?: 'Mutation' }
  & { transitionToDeliveryCompleted?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToGettingReadyMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToGettingReadyMutation = (
  { __typename?: 'Mutation' }
  & { transitionToGettingReady?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToImportedDeliverySlipMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToImportedDeliverySlipMutation = (
  { __typename?: 'Mutation' }
  & { transitionToImportedDeliverySlip?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToImportingDeliverySlipMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToImportingDeliverySlipMutation = (
  { __typename?: 'Mutation' }
  & { transitionToImportingDeliverySlip?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToPaidMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToPaidMutation = (
  { __typename?: 'Mutation' }
  & { transitionToPaid?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToPendingForRejectionMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToPendingForRejectionMutation = (
  { __typename?: 'Mutation' }
  & { transitionToPendingForRejection?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToRefundingMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToRefundingMutation = (
  { __typename?: 'Mutation' }
  & { transitionToRefunding?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToWaitingForAuthDeferredPaymentMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToWaitingForAuthDeferredPaymentMutation = (
  { __typename?: 'Mutation' }
  & { transitionToWaitingForAuthDeferredPayment?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToWaitingForBankTransferMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToWaitingForBankTransferMutation = (
  { __typename?: 'Mutation' }
  & { transitionToWaitingForBankTransfer?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type TransitionToWaitingForCreditCardPaymentMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type TransitionToWaitingForCreditCardPaymentMutation = (
  { __typename?: 'Mutation' }
  & { transitionToWaitingForCreditCardPayment?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'deliveryName' | 'guid' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>>> }
  )> }
);

export type UpdateBundledItemsMutationVariables = Exact<{
  updateBundledItemsInput?: Maybe<UpdateBundledItemsInput>;
}>;


export type UpdateBundledItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateBundledItems?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'guid' | 'name' | 'webCode' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId' | 'onlyForCatalogOrder' | 'isFresh' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'canPickupAtStore' | 'isPostageFree' | 'sendDirectly' | 'noshiSize' | 'catalogPage' | 'content' | 'keyword' | 'catchCopy' | 'comment' | 'description' | 'memo' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'catalogUrl' | 'minimumOrderQuantity' | 'stockQuantity' | 'forPrivateUse' | 'taxType' | 'price' | 'postage' | 'publicationDate' | 'publicationEndDate' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'onDiscount' | 'originalPrice' | 'specialPrice' | 'affiliatePrice' | 'specialMemberPrice' | 'onlyForMember' | 'isAvailable'>
    & { purposes?: Maybe<Array<Maybe<(
      { __typename?: 'Purpose' }
      & Pick<Purpose, 'guid' | 'name' | 'slugName' | 'categoryName' | 'displayOrder' | 'updatedAt' | 'createdAt'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'isAvailable' | 'createdAt' | 'updatedAt'>
    )>>>, character?: Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>>, brands?: Maybe<Array<Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>>>, catalogs?: Maybe<Array<Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
    )>>>, bundledItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>>, bundledWithItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>> }
  )> }
);

export type UpdateCatalogMutationVariables = Exact<{
  updateCatalogInput?: Maybe<UpdateCatalogInput>;
}>;


export type UpdateCatalogMutation = (
  { __typename?: 'Mutation' }
  & { updateCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateCommunicationMessageMutationVariables = Exact<{
  input?: Maybe<UpdateCommunicationMessageInput>;
}>;


export type UpdateCommunicationMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateCommunicationMessage?: Maybe<(
    { __typename?: 'CommunicationMessage' }
    & Pick<CommunicationMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )> }
);

export type UpdateCouponMutationVariables = Exact<{
  updateCouponInput?: Maybe<UpdateCouponInput>;
}>;


export type UpdateCouponMutation = (
  { __typename?: 'Mutation' }
  & { updateCoupon?: Maybe<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'guid' | 'title' | 'slugName' | 'amount' | 'description' | 'minPriceToGain' | 'minPriceToUse' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateDeliverdCouponMutationVariables = Exact<{
  updateDeliverdCouponInput?: Maybe<UpdateDeliverdCouponInput>;
}>;


export type UpdateDeliverdCouponMutation = (
  { __typename?: 'Mutation' }
  & { updateDeliverdCoupon?: Maybe<(
    { __typename?: 'DeliverdCoupon' }
    & Pick<DeliverdCoupon, 'guid' | 'expirationDate' | 'couponGuid' | 'couponTitle' | 'couponSlugName' | 'couponAmount' | 'couponDescription' | 'couponMinPriceToGain' | 'couponMinPriceToUse' | 'createdAt' | 'updatedAt'>
    & { coupon?: Maybe<(
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'guid'>
    )>, member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid'>
    )> }
  )> }
);

export type UpdateDeliverySlipNoMutationVariables = Exact<{
  input?: Maybe<UpdateDeliverySlipNoInput>;
}>;


export type UpdateDeliverySlipNoMutation = (
  { __typename?: 'Mutation' }
  & { updateDeliverySlipNo?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'guid' | 'deliverySlipNo' | 'deliveryTraderNo' | 'deliveryName' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateDiscountRateMutationVariables = Exact<{
  updateDiscountRateInput?: Maybe<UpdateDiscountRateInput>;
}>;


export type UpdateDiscountRateMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscountRate?: Maybe<(
    { __typename?: 'DiscountRate' }
    & Pick<DiscountRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt'>
  )> }
);

export type UpdateDomesticPostageFeeMutationVariables = Exact<{
  updateDomesticPostageFeeInput?: Maybe<UpdateDomesticPostageFeeInput>;
}>;


export type UpdateDomesticPostageFeeMutation = (
  { __typename?: 'Mutation' }
  & { updateDomesticPostageFee?: Maybe<(
    { __typename?: 'DomesticPostageFee' }
    & Pick<DomesticPostageFee, 'guid' | 'prefecture' | 'amount' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateHandoverMessageMutationVariables = Exact<{
  input?: Maybe<UpdateHandoverMessageInput>;
}>;


export type UpdateHandoverMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateHandoverMessage?: Maybe<(
    { __typename?: 'HandoverMessage' }
    & Pick<HandoverMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )> }
);

export type UpdateItemMutationVariables = Exact<{
  updateItemInput?: Maybe<UpdateItemInput>;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'guid' | 'name' | 'webCode' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId' | 'onlyForCatalogOrder' | 'isFresh' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'canPickupAtStore' | 'isPostageFree' | 'sendDirectly' | 'noshiSize' | 'catalogPage' | 'content' | 'keyword' | 'catchCopy' | 'comment' | 'description' | 'memo' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'catalogUrl' | 'minimumOrderQuantity' | 'stockQuantity' | 'forPrivateUse' | 'taxType' | 'price' | 'postage' | 'publicationDate' | 'publicationEndDate' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'onDiscount' | 'originalPrice' | 'specialPrice' | 'affiliatePrice' | 'specialMemberPrice' | 'onlyForMember' | 'isAvailable' | 'sendday'>
    & { purposes?: Maybe<Array<Maybe<(
      { __typename?: 'Purpose' }
      & Pick<Purpose, 'guid' | 'name' | 'slugName' | 'categoryName' | 'displayOrder' | 'updatedAt' | 'createdAt'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'isAvailable' | 'createdAt' | 'updatedAt'>
    )>>>, character?: Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>>, brands?: Maybe<Array<Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>>>, catalogs?: Maybe<Array<Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
    )>>>, bundledItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>>, bundledWithItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>> }
  )> }
);

export type UpdateItemBrandMutationVariables = Exact<{
  updateItemBrandInput?: Maybe<UpdateItemBrandInput>;
}>;


export type UpdateItemBrandMutation = (
  { __typename?: 'Mutation' }
  & { updateItemBrand?: Maybe<(
    { __typename?: 'ItemBrand' }
    & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
  )> }
);

export type UpdateItemCategoryMutationVariables = Exact<{
  updateItemCategoryInput?: Maybe<UpdateItemCategoryInput>;
}>;


export type UpdateItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateItemCategory?: Maybe<(
    { __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    & { parent?: Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      & { parent?: Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
        & { parent?: Maybe<(
          { __typename?: 'ItemCategory' }
          & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          & { parent?: Maybe<(
            { __typename?: 'ItemCategory' }
            & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          )> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type UpdateItemPriceRangeMutationVariables = Exact<{
  updateItemPriceRangeInput?: Maybe<UpdateItemPriceRangeInput>;
}>;


export type UpdateItemPriceRangeMutation = (
  { __typename?: 'Mutation' }
  & { updateItemPriceRange?: Maybe<(
    { __typename?: 'ItemPriceRange' }
    & Pick<ItemPriceRange, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateMemberMutationVariables = Exact<{
  updateMemberInput?: Maybe<UpdateMemberInput>;
}>;


export type UpdateMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateMember?: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateOptionMutationVariables = Exact<{
  updateOptionInput?: Maybe<UpdateOptionInput>;
}>;


export type UpdateOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateOption?: Maybe<(
    { __typename?: 'Option' }
    & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    & { optionItems?: Maybe<Array<Maybe<(
      { __typename?: 'OptionItem' }
      & Pick<OptionItem, 'guid' | 'name' | 'displayOrder' | 'imageUrl' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type UpdateOptionItemMutationVariables = Exact<{
  updateOptionItemInput?: Maybe<UpdateOptionItemInput>;
}>;


export type UpdateOptionItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOptionItem?: Maybe<(
    { __typename?: 'OptionItem' }
    & Pick<OptionItem, 'guid' | 'displayOrder' | 'imageUrl' | 'name' | 'optionType' | 'oldPackingId' | 'createdAt' | 'updatedAt'>
    & { properties?: Maybe<Array<Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type UpdatePointRateMutationVariables = Exact<{
  updatePointRateInput?: Maybe<UpdatePointRateInput>;
}>;


export type UpdatePointRateMutation = (
  { __typename?: 'Mutation' }
  & { updatePointRate?: Maybe<(
    { __typename?: 'PointRate' }
    & Pick<PointRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt'>
  )> }
);

export type UpdatePropertyMutationVariables = Exact<{
  updatePropertyInput?: Maybe<UpdatePropertyInput>;
}>;


export type UpdatePropertyMutation = (
  { __typename?: 'Mutation' }
  & { updateProperty?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdatePurposeMutationVariables = Exact<{
  updatePurposeInput?: Maybe<UpdatePurposeInput>;
}>;


export type UpdatePurposeMutation = (
  { __typename?: 'Mutation' }
  & { updatePurpose?: Maybe<(
    { __typename?: 'Purpose' }
    & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type UpdateShopInformationMutationVariables = Exact<{
  updateShopInformationInput?: Maybe<UpdateShopInformationInput>;
}>;


export type UpdateShopInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateShopInformation?: Maybe<(
    { __typename?: 'ShopInformation' }
    & Pick<ShopInformation, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateShopSettingMutationVariables = Exact<{
  updateShopSettingInput?: Maybe<UpdateShopSettingInput>;
}>;


export type UpdateShopSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateShopSetting?: Maybe<(
    { __typename?: 'ShopSetting' }
    & Pick<ShopSetting, 'guid' | 'shopCode' | 'shopName' | 'contactAddress' | 'contactEmailAddress' | 'discountRateDefermentDays' | 'maxOrderAmountForDeferredPayment' | 'minOrderAmountForFreeShipping' | 'privacyPolicy' | 'specifiedCommercialTransactionAct' | 'pointGrantRate'>
  )> }
);

export type UpsertCatalogsMutationVariables = Exact<{
  upsertCatalogsInput?: Maybe<Array<Maybe<UpsertCatalogInput>> | Maybe<UpsertCatalogInput>>;
}>;


export type UpsertCatalogsMutation = (
  { __typename?: 'Mutation' }
  & { upsertCatalogs?: Maybe<Array<Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type UpsertEmailTemplateMutationVariables = Exact<{
  input?: Maybe<UpsertEmailTemplateInput>;
}>;


export type UpsertEmailTemplateMutation = (
  { __typename?: 'Mutation' }
  & { upsertEmailTemplate?: Maybe<(
    { __typename?: 'EmailTemplate' }
    & Pick<EmailTemplate, 'HtmlPart' | 'SubjectPart' | 'TemplateName' | 'TextPart'>
  )> }
);

export type Base64NavFileQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type Base64NavFileQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'base64NavFile'>
);

export type Base64SalesSlipExcelQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type Base64SalesSlipExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'base64SalesSlipExcel'>
);

export type CatalogQueryVariables = Exact<{
  catalogGuid: Scalars['ID'];
}>;


export type CatalogQuery = (
  { __typename?: 'Query' }
  & { catalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CatalogByCodeQueryVariables = Exact<{
  catalogByCodeCode: Scalars['ID'];
}>;


export type CatalogByCodeQuery = (
  { __typename?: 'Query' }
  & { catalogByCode?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CatalogsQueryVariables = Exact<{ [key: string]: never; }>;


export type CatalogsQuery = (
  { __typename?: 'Query' }
  & { catalogs?: Maybe<Array<Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type CommunicationMessageQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type CommunicationMessageQuery = (
  { __typename?: 'Query' }
  & { communicationMessage?: Maybe<(
    { __typename?: 'CommunicationMessage' }
    & Pick<CommunicationMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )> }
);

export type CommunicationMessagesQueryVariables = Exact<{
  filter?: Maybe<CommunicationMessageFilter>;
  orderBy?: Maybe<CommunicationMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type CommunicationMessagesQuery = (
  { __typename?: 'Query' }
  & { communicationMessages?: Maybe<Array<Maybe<(
    { __typename?: 'CommunicationMessage' }
    & Pick<CommunicationMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )>>> }
);

export type CountItemsQueryVariables = Exact<{
  filter?: Maybe<ItemFilter>;
}>;


export type CountItemsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countItems'>
);

export type CountMembersQueryVariables = Exact<{
  filter?: Maybe<MemberFilter>;
}>;


export type CountMembersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countMembers'>
);

export type CountOrdersQueryVariables = Exact<{
  filter?: Maybe<OrderFilter>;
}>;


export type CountOrdersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countOrders'>
);

export type CouponQueryVariables = Exact<{
  key: FindUniqueCouponKey;
}>;


export type CouponQuery = (
  { __typename?: 'Query' }
  & { coupon?: Maybe<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'guid' | 'title' | 'slugName' | 'amount' | 'description' | 'minPriceToGain' | 'minPriceToUse' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CouponHistoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponHistoriesQuery = (
  { __typename?: 'Query' }
  & { couponHistories?: Maybe<Array<Maybe<(
    { __typename?: 'CouponHistory' }
    & Pick<CouponHistory, 'guid' | 'transactionDate' | 'debitCreditType' | 'couponGuid' | 'couponTitle' | 'couponSlugName' | 'couponAmount' | 'couponDescription' | 'couponMinPriceToGain' | 'couponMinPriceToUse' | 'couponExpirationDate' | 'createdAt' | 'updatedAt'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid'>
    )> }
  )>>> }
);

export type CouponsQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponsQuery = (
  { __typename?: 'Query' }
  & { coupons?: Maybe<Array<Maybe<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'guid' | 'title' | 'slugName' | 'amount' | 'description' | 'minPriceToGain' | 'minPriceToUse' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type DeliverdCouponQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type DeliverdCouponQuery = (
  { __typename?: 'Query' }
  & { deliverdCoupon?: Maybe<(
    { __typename?: 'DeliverdCoupon' }
    & Pick<DeliverdCoupon, 'guid' | 'couponGuid' | 'couponTitle' | 'couponSlugName' | 'couponAmount' | 'couponDescription' | 'couponMinPriceToGain' | 'couponMinPriceToUse' | 'expirationDate' | 'createdAt' | 'updatedAt' | 'isUsed' | 'usedAt'>
    & { coupon?: Maybe<(
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'guid'>
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )>, member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid'>
    )> }
  )> }
);

export type DeliverdCouponsQueryVariables = Exact<{
  filter?: Maybe<DeliverdCouponFilter>;
  orderBy?: Maybe<DeliverdCouponOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type DeliverdCouponsQuery = (
  { __typename?: 'Query' }
  & { deliverdCoupons?: Maybe<Array<Maybe<(
    { __typename?: 'DeliverdCoupon' }
    & Pick<DeliverdCoupon, 'guid' | 'couponGuid' | 'couponTitle' | 'couponSlugName' | 'couponAmount' | 'couponDescription' | 'couponMinPriceToGain' | 'couponMinPriceToUse' | 'expirationDate' | 'createdAt' | 'updatedAt' | 'isUsed' | 'usedAt'>
    & { coupon?: Maybe<(
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'guid'>
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )>, member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid'>
    )> }
  )>>> }
);

export type DeliveryQueryVariables = Exact<{
  key?: Maybe<FindUniqueDeliveryKey>;
}>;


export type DeliveryQuery = (
  { __typename?: 'Query' }
  & { delivery?: Maybe<(
    { __typename?: 'Delivery' }
    & Pick<Delivery, 'guid' | 'id' | 'deliverySlipNo' | 'deliveryTraderNo' | 'deliveryName' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
    & { purpose?: Maybe<(
      { __typename?: 'Purpose' }
      & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
    )>, contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'guid'>
    )>, orderOptions?: Maybe<Array<Maybe<(
      { __typename?: 'OrderOption' }
      & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
      & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOptionPropertyValue' }
        & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
      )>>> }
    )>>>, deliveryDetails?: Maybe<Array<Maybe<(
      { __typename?: 'DeliveryDetail' }
      & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
      & { orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type DiscountRateQueryVariables = Exact<{
  discountRateGuid: Scalars['ID'];
}>;


export type DiscountRateQuery = (
  { __typename?: 'Query' }
  & { discountRate?: Maybe<(
    { __typename?: 'DiscountRate' }
    & Pick<DiscountRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DiscountRatesQueryVariables = Exact<{
  discountRatesFilter?: Maybe<DiscountRateFilter>;
}>;


export type DiscountRatesQuery = (
  { __typename?: 'Query' }
  & { discountRates?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountRate' }
    & Pick<DiscountRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type DomesticPostageFeeQueryVariables = Exact<{
  domesticPostageFeeKey: FindUniqueDomesticPostageFeeKey;
}>;


export type DomesticPostageFeeQuery = (
  { __typename?: 'Query' }
  & { domesticPostageFee?: Maybe<(
    { __typename?: 'DomesticPostageFee' }
    & Pick<DomesticPostageFee, 'guid' | 'prefecture' | 'amount' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DomesticPostageFeesQueryVariables = Exact<{ [key: string]: never; }>;


export type DomesticPostageFeesQuery = (
  { __typename?: 'Query' }
  & { domesticPostageFees?: Maybe<Array<Maybe<(
    { __typename?: 'DomesticPostageFee' }
    & Pick<DomesticPostageFee, 'guid' | 'prefecture' | 'amount' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type EmailTemplateQueryVariables = Exact<{
  templateName: Scalars['String'];
}>;


export type EmailTemplateQuery = (
  { __typename?: 'Query' }
  & { emailTemplate?: Maybe<(
    { __typename?: 'EmailTemplate' }
    & Pick<EmailTemplate, 'HtmlPart' | 'SubjectPart' | 'TemplateName' | 'TextPart'>
  )> }
);

export type EmailTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailTemplatesQuery = (
  { __typename?: 'Query' }
  & { emailTemplates?: Maybe<Array<Maybe<(
    { __typename?: 'EmailTemplate' }
    & Pick<EmailTemplate, 'HtmlPart' | 'SubjectPart' | 'TemplateName' | 'TextPart'>
  )>>> }
);

export type HandoverMessageQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type HandoverMessageQuery = (
  { __typename?: 'Query' }
  & { handoverMessage?: Maybe<(
    { __typename?: 'HandoverMessage' }
    & Pick<HandoverMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )> }
);

export type HandoverMessagesQueryVariables = Exact<{
  filter?: Maybe<HandoverMessageFilter>;
  orderBy?: Maybe<HandoverMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type HandoverMessagesQuery = (
  { __typename?: 'Query' }
  & { handoverMessages?: Maybe<Array<Maybe<(
    { __typename?: 'HandoverMessage' }
    & Pick<HandoverMessage, 'guid' | 'subject' | 'message' | 'senderName' | 'readDate' | 'createdAt' | 'updatedAt'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id'>
    )> }
  )>>> }
);

export type ItemQueryVariables = Exact<{
  itemKey: FindUniqueItemKey;
}>;


export type ItemQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'guid' | 'name' | 'webCode' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId' | 'onlyForCatalogOrder' | 'isFresh' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'canPickupAtStore' | 'isPostageFree' | 'sendDirectly' | 'noshiSize' | 'catalogPage' | 'content' | 'keyword' | 'catchCopy' | 'comment' | 'description' | 'memo' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'catalogUrl' | 'minimumOrderQuantity' | 'stockQuantity' | 'forPrivateUse' | 'taxType' | 'price' | 'postage' | 'publicationDate' | 'publicationEndDate' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'sendday' | 'onDiscount' | 'originalPrice' | 'specialPrice' | 'affiliatePrice' | 'specialMemberPrice' | 'onlyForMember' | 'isAvailable'>
    & { purposes?: Maybe<Array<Maybe<(
      { __typename?: 'Purpose' }
      & Pick<Purpose, 'guid' | 'name' | 'slugName' | 'categoryName' | 'displayOrder' | 'updatedAt' | 'createdAt'>
    )>>>, categories?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'isAvailable' | 'createdAt' | 'updatedAt'>
    )>>>, character?: Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'isAvailable' | 'createdAt' | 'updatedAt'>
    )>, options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>>, brands?: Maybe<Array<Maybe<(
      { __typename?: 'ItemBrand' }
      & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'isAvailable' | 'createdAt' | 'updatedAt'>
    )>>>, catalogs?: Maybe<Array<Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'guid' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
    )>>>, bundledItems?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'guid' | 'name' | 'displayName' | 'code' | 'janCode' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId'>
    )>>> }
  )> }
);

export type ItemBrandQueryVariables = Exact<{
  itemBrandGuid: Scalars['ID'];
}>;


export type ItemBrandQuery = (
  { __typename?: 'Query' }
  & { itemBrand?: Maybe<(
    { __typename?: 'ItemBrand' }
    & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
  )> }
);

export type ItemBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemBrandsQuery = (
  { __typename?: 'Query' }
  & { itemBrands?: Maybe<Array<Maybe<(
    { __typename?: 'ItemBrand' }
    & Pick<ItemBrand, 'guid' | 'slugName' | 'name' | 'nameKana' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
  )>>> }
);

export type ItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemCategoriesQuery = (
  { __typename?: 'Query' }
  & { itemCategories?: Maybe<Array<Maybe<(
    { __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    & { parent?: Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      & { parent?: Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
        & { parent?: Maybe<(
          { __typename?: 'ItemCategory' }
          & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          & { parent?: Maybe<(
            { __typename?: 'ItemCategory' }
            & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          )> }
        )> }
      )>>> }
    )>>> }
  )>>> }
);

export type ItemCategoryQueryVariables = Exact<{
  itemCategoryGuid: Scalars['ID'];
}>;


export type ItemCategoryQuery = (
  { __typename?: 'Query' }
  & { itemCategory?: Maybe<(
    { __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    & { parent?: Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      & { parent?: Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
        & { parent?: Maybe<(
          { __typename?: 'ItemCategory' }
          & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          & { parent?: Maybe<(
            { __typename?: 'ItemCategory' }
            & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          )> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type ItemPriceRangeQueryVariables = Exact<{
  itemPriceRangeGuid: Scalars['ID'];
}>;


export type ItemPriceRangeQuery = (
  { __typename?: 'Query' }
  & { itemPriceRange?: Maybe<(
    { __typename?: 'ItemPriceRange' }
    & Pick<ItemPriceRange, 'createdAt' | 'displayOrder' | 'guid' | 'lowerLimit' | 'name' | 'updatedAt' | 'upperLimit'>
  )> }
);

export type ItemPriceRangesQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemPriceRangesQuery = (
  { __typename?: 'Query' }
  & { itemPriceRanges?: Maybe<Array<Maybe<(
    { __typename?: 'ItemPriceRange' }
    & Pick<ItemPriceRange, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type ItemsQueryVariables = Exact<{
  filter?: Maybe<ItemFilter>;
  orderBy?: Maybe<ItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type ItemsQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'guid' | 'name' | 'webCode' | 'displayName' | 'code' | 'janCode' | 'displayOrder' | 'daishinCode' | 'makerCode' | 'sellerCode' | 'sectionId' | 'onlyForCatalogOrder' | 'isFresh' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'canPickupAtStore' | 'isPostageFree' | 'sendDirectly' | 'noshiSize' | 'catalogPage' | 'content' | 'keyword' | 'catchCopy' | 'comment' | 'description' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'forPrivateUse' | 'taxType' | 'price' | 'priceWithTax' | 'postage' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt' | 'onDiscount' | 'originalPrice' | 'specialPrice' | 'affiliatePrice' | 'specialMemberPrice' | 'onlyForMember' | 'isAvailable' | 'sendday'>
  )>>> }
);

export type MemberQueryVariables = Exact<{
  memberKey: FindUniqueMemberKey;
}>;


export type MemberQuery = (
  { __typename?: 'Query' }
  & { member?: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    & { orders?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate'>
    )>>> }
  )> }
);

export type MembersQueryVariables = Exact<{
  filter?: Maybe<MemberFilter>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MemberOrderByInput>;
}>;


export type MembersQuery = (
  { __typename?: 'Query' }
  & { members?: Maybe<Array<Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
    & { orders?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate'>
    )>>> }
  )>>> }
);

export type NavsQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;


export type NavsQuery = (
  { __typename?: 'Query' }
  & { navs?: Maybe<Array<Maybe<(
    { __typename?: 'Nav' }
    & Pick<Nav, 'id' | 'memberId' | 'name' | 'purposeNo' | 'totalPriceWithTax' | 'postageFeeWithTax' | 'deliveryDate' | 'transactionDate' | 'np' | 'site' | 'deliveryDateSelect'>
    & { senders?: Maybe<Array<Maybe<(
      { __typename?: 'NavSender' }
      & Pick<NavSender, 'senderName' | 'senderId' | 'senderPostalcode' | 'senderAddress' | 'senderPhone'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'NavDelivery' }
      & Pick<NavDelivery, 'id' | 'deliveryName' | 'deliveryPostalCode' | 'deliveryAddress' | 'deliveryPhone' | 'deliveryDateSelect' | 'includedDeliveryIds'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'NavDeliveryDetail' }
        & Pick<NavDeliveryDetail, 'webCode' | 'name' | 'quantity' | 'unitPriceWithTax' | 'deliveryItemRow'>
      )>>> }
    )>>> }
  )>>> }
);

export type OptionQueryVariables = Exact<{
  optionGuid: Scalars['ID'];
}>;


export type OptionQuery = (
  { __typename?: 'Query' }
  & { option?: Maybe<(
    { __typename?: 'Option' }
    & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    & { optionItems?: Maybe<Array<Maybe<(
      { __typename?: 'OptionItem' }
      & Pick<OptionItem, 'guid' | 'name' | 'displayOrder' | 'imageUrl' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type OptionItemQueryVariables = Exact<{
  optionItemGuid: Scalars['ID'];
}>;


export type OptionItemQuery = (
  { __typename?: 'Query' }
  & { optionItem?: Maybe<(
    { __typename?: 'OptionItem' }
    & Pick<OptionItem, 'guid' | 'displayOrder' | 'imageUrl' | 'name' | 'optionType' | 'oldPackingId' | 'createdAt' | 'updatedAt'>
    & { properties?: Maybe<Array<Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type OptionItemsQueryVariables = Exact<{
  optionItemFindCondition?: Maybe<OptionItemFindCondition>;
}>;


export type OptionItemsQuery = (
  { __typename?: 'Query' }
  & { optionItems?: Maybe<Array<Maybe<(
    { __typename?: 'OptionItem' }
    & Pick<OptionItem, 'guid' | 'displayOrder' | 'imageUrl' | 'name' | 'optionType' | 'oldPackingId' | 'createdAt' | 'updatedAt'>
    & { properties?: Maybe<Array<Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
    )>>> }
  )>>> }
);

export type OptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type OptionsQuery = (
  { __typename?: 'Query' }
  & { options?: Maybe<Array<Maybe<(
    { __typename?: 'Option' }
    & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    & { optionItems?: Maybe<Array<Maybe<(
      { __typename?: 'OptionItem' }
      & Pick<OptionItem, 'guid' | 'name' | 'displayOrder' | 'imageUrl' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )>>> }
);

export type OrderQueryVariables = Exact<{
  orderGuid: Scalars['ID'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
      & { orders?: Maybe<Array<Maybe<(
        { __typename?: 'Order' }
        & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate'>
      )>>> }
    )>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'guid' | 'id' | 'deliverySlipNo' | 'deliveryTraderNo' | 'deliveryName' | 'deliveryAddress' | 'deliveryDate' | 'deliveryPhone' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
      & { purpose?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'guid'>
      )>, orderOptions?: Maybe<Array<Maybe<(
        { __typename?: 'OrderOption' }
        & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
        & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOptionPropertyValue' }
          & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
        )>>> }
      )>>>, deliveryDetails?: Maybe<Array<Maybe<(
        { __typename?: 'DeliveryDetail' }
        & Pick<DeliveryDetail, 'guid' | 'quantity' | 'unitPrice' | 'unitPriceWithTax' | 'price' | 'priceWithTax' | 'name' | 'displayName' | 'displayOrder' | 'sectionId' | 'daishinCode' | 'webCode' | 'janCode' | 'catalogPage' | 'onlyForCatalogOrder' | 'keyword' | 'catchCopy' | 'comment' | 'isFresh' | 'makerCode' | 'sellerCode' | 'memo' | 'minimumOrderQuantity' | 'stockQuantity' | 'sendDirectly' | 'noshiSize' | 'addNoshi' | 'forPrivateUse' | 'code' | 'taxType' | 'content' | 'postage' | 'postageWithTax' | 'isPostageFree' | 'canPickupAtStore' | 'hasPersonalizedProperty' | 'cannotBeAddedNoshi' | 'cannotBeAddedWarppingPaper' | 'cannotBeAddedMessageCard' | 'sendday' | 'description' | 'isAvailable' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05' | 'imageUrl06' | 'imageUrl07' | 'imageUrl08' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
        & { orderOptions?: Maybe<Array<Maybe<(
          { __typename?: 'OrderOption' }
          & Pick<OrderOption, 'guid' | 'optionName' | 'optionMemo' | 'optionExpressionName' | 'optionType' | 'optionItemName' | 'optionItemImageUrl' | 'createdAt' | 'updatedAt'>
          & { orderOptionPropertyValues?: Maybe<Array<Maybe<(
            { __typename?: 'OrderOptionPropertyValue' }
            & Pick<OrderOptionPropertyValue, 'name' | 'guid' | 'slugName' | 'value' | 'imageUrl' | 'createdAt' | 'updatedAt' | 'propertyType'>
          )>>> }
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type OrdersQueryVariables = Exact<{
  ordersFilter?: Maybe<OrderFilter>;
  ordersSkip?: Maybe<Scalars['Int']>;
  ordersTake?: Maybe<Scalars['Int']>;
  ordersOrderBy?: Maybe<OrderOrderByInput>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<Array<Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate' | 'paymentMethodType' | 'discountRate' | 'discountPrice' | 'discountPriceWithTax' | 'couponDiscountPrice' | 'pointDiscountPrice' | 'memberId' | 'senderName' | 'senderNameKana' | 'senderPostalCode' | 'senderPrefecture' | 'senderAddress1' | 'senderAddress2' | 'senderAddress3' | 'senderPhone' | 'senderEmail' | 'orderNote' | 'chargePrice' | 'chargePriceWithTax' | 'createdAt' | 'updatedAt' | 'subTotalPriceOnDiscount' | 'subTotalPriceOnDiscountWithTax' | 'subTotalPriceNotOnDiscount' | 'subTotalPriceNotOnDiscountWithTax' | 'totalPostageFee' | 'totalPostageFeeWithTax' | 'totalPrice' | 'totalPriceWithTax' | 'totalPriceOnNormalTax' | 'totalPriceOnNormalTaxWithTax' | 'totalPriceOnReducedTax' | 'totalPriceOnReducedTaxWithTax'>
    & { member?: Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'guid' | 'id' | 'email' | 'accountNumberBC' | 'address1' | 'address2' | 'address3' | 'birthday' | 'cellPhone' | 'memo' | 'name' | 'nameKana' | 'phone' | 'postalCode' | 'prefecture' | 'sex' | 'isAvailable' | 'hasSubscriptionToMailzine' | 'createdAt' | 'updatedAt'>
      & { orders?: Maybe<Array<Maybe<(
        { __typename?: 'Order' }
        & Pick<Order, 'guid' | 'id' | 'status' | 'transactionDate'>
      )>>> }
    )>, deliveries?: Maybe<Array<Maybe<(
      { __typename?: 'Delivery' }
      & Pick<Delivery, 'guid' | 'id' | 'deliverySlipNo' | 'deliveryTraderNo' | 'deliveryDate' | 'deliveryName' | 'deliveryPostalCode' | 'deliveryPrefecture' | 'deliveryAddress' | 'deliveryPhone' | 'deliveryAddressType' | 'postageFee' | 'postageFeeWithTax' | 'disabled' | 'srcDeliveryGuid' | 'createdAt' | 'updatedAt'>
    )>>>, handoverMessages?: Maybe<Array<Maybe<(
      { __typename?: 'HandoverMessage' }
      & Pick<HandoverMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'readDate' | 'createdAt' | 'updatedAt'>
    )>>>, communicationMessages?: Maybe<Array<Maybe<(
      { __typename?: 'CommunicationMessage' }
      & Pick<CommunicationMessage, 'guid' | 'senderName' | 'subject' | 'message' | 'notifyDate' | 'readDate' | 'createdAt' | 'updatedAt' | 'imageUrl01' | 'imageUrl02' | 'imageUrl03' | 'imageUrl04' | 'imageUrl05'>
    )>>> }
  )>>> }
);

export type PointRateQueryVariables = Exact<{
  pointRateGuid: Scalars['ID'];
}>;


export type PointRateQuery = (
  { __typename?: 'Query' }
  & { pointRate?: Maybe<(
    { __typename?: 'PointRate' }
    & Pick<PointRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )> }
);

export type PointRatesQueryVariables = Exact<{ [key: string]: never; }>;


export type PointRatesQuery = (
  { __typename?: 'Query' }
  & { pointRates?: Maybe<Array<Maybe<(
    { __typename?: 'PointRate' }
    & Pick<PointRate, 'guid' | 'name' | 'lowerLimit' | 'upperLimit' | 'rate' | 'displayOrder' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type PropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type PropertiesQuery = (
  { __typename?: 'Query' }
  & { properties?: Maybe<Array<Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type PropertyQueryVariables = Exact<{
  propertyGuid: Scalars['ID'];
}>;


export type PropertyQuery = (
  { __typename?: 'Query' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
  )> }
);

export type PurposeQueryVariables = Exact<{
  purposeKey: FindUniquePurposeKey;
}>;


export type PurposeQuery = (
  { __typename?: 'Query' }
  & { purpose?: Maybe<(
    { __typename?: 'Purpose' }
    & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type PurposesQueryVariables = Exact<{ [key: string]: never; }>;


export type PurposesQuery = (
  { __typename?: 'Query' }
  & { purposes?: Maybe<Array<Maybe<(
    { __typename?: 'Purpose' }
    & Pick<Purpose, 'guid' | 'slugName' | 'updatedAt' | 'createdAt' | 'name' | 'categoryName' | 'displayOrder' | 'isAvailable' | 'freeShippingLine' | 'purposeNo'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'Option' }
      & Pick<Option, 'guid' | 'expressionName' | 'memo' | 'name' | 'displayOrder' | 'optionType' | 'createdAt' | 'updatedAt'>
    )>>> }
  )>>> }
);

export type SearchOptionItemsByOptionTypeQueryVariables = Exact<{
  searchOptionItemsByOptionTypeOptionType?: Maybe<OptionType>;
}>;


export type SearchOptionItemsByOptionTypeQuery = (
  { __typename?: 'Query' }
  & { searchOptionItemsByOptionType?: Maybe<Array<Maybe<(
    { __typename?: 'OptionItem' }
    & Pick<OptionItem, 'guid' | 'displayOrder' | 'imageUrl' | 'name' | 'optionType' | 'oldPackingId' | 'createdAt' | 'updatedAt'>
    & { properties?: Maybe<Array<Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'guid' | 'name' | 'slugName' | 'propertyType' | 'placeholderText' | 'cautionText' | 'createdAt' | 'updatedAt'>
    )>>> }
  )>>> }
);

export type ShopInformationQueryVariables = Exact<{
  shopInformationGuid: Scalars['ID'];
}>;


export type ShopInformationQuery = (
  { __typename?: 'Query' }
  & { shopInformation?: Maybe<(
    { __typename?: 'ShopInformation' }
    & Pick<ShopInformation, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  )> }
);

export type ShopInformationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopInformationsQuery = (
  { __typename?: 'Query' }
  & { shopInformations?: Maybe<Array<Maybe<(
    { __typename?: 'ShopInformation' }
    & Pick<ShopInformation, 'guid' | 'title' | 'content' | 'publicationDate' | 'publicationEndDate' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type ShopSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopSettingQuery = (
  { __typename?: 'Query' }
  & { shopSetting?: Maybe<(
    { __typename?: 'ShopSetting' }
    & Pick<ShopSetting, 'guid' | 'shopCode' | 'shopName' | 'contactAddress' | 'contactEmailAddress' | 'discountRateDefermentDays' | 'maxOrderAmountForDeferredPayment' | 'minOrderAmountForFreeShipping' | 'privacyPolicy' | 'specifiedCommercialTransactionAct' | 'pointGrantRate'>
  )> }
);

export type ToplevelItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ToplevelItemCategoriesQuery = (
  { __typename?: 'Query' }
  & { toplevelItemCategories?: Maybe<Array<Maybe<(
    { __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    & { parent?: Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'isAvailable'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      & { parent?: Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
        & { parent?: Maybe<(
          { __typename?: 'ItemCategory' }
          & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          & { parent?: Maybe<(
            { __typename?: 'ItemCategory' }
            & Pick<ItemCategory, 'guid' | 'name' | 'aliasName' | 'slugName' | 'imageUrl' | 'description' | 'createdAt' | 'updatedAt' | 'isAvailable'>
          )> }
        )> }
      )>>> }
    )>>> }
  )>>> }
);


export const CreateCatalogDocument = gql`
    mutation createCatalog($createCatalogInput: CreateCatalogInput) {
  createCatalog(input: $createCatalogInput) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const CreateCommunicationMessageDocument = gql`
    mutation createCommunicationMessage($input: CreateCommunicationMessageInput) {
  createCommunicationMessage(input: $input) {
    guid
    subject
    message
    senderName
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    notifyDate
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const CreateCouponDocument = gql`
    mutation createCoupon($createCouponInput: CreateCouponInput) {
  createCoupon(input: $createCouponInput) {
    guid
    title
    slugName
    amount
    description
    minPriceToGain
    minPriceToUse
    createdAt
    updatedAt
  }
}
    `;
export const CreateDeliverdCouponDocument = gql`
    mutation createDeliverdCoupon($input: CreateDeliverdCouponInput) {
  createDeliverdCoupon(input: $input) {
    guid
    expirationDate
    couponGuid
    couponTitle
    couponSlugName
    couponAmount
    couponDescription
    couponMinPriceToGain
    couponMinPriceToUse
    createdAt
    updatedAt
    coupon {
      guid
    }
    member {
      guid
    }
  }
}
    `;
export const CreateDiscountRateDocument = gql`
    mutation createDiscountRate($createDiscountRateInput: CreateDiscountRateInput) {
  createDiscountRate(input: $createDiscountRateInput) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
  }
}
    `;
export const CreateDomesticPostageFeeDocument = gql`
    mutation createDomesticPostageFee($createDomesticPostageFeeInput: CreateDomesticPostageFeeInput) {
  createDomesticPostageFee(input: $createDomesticPostageFeeInput) {
    guid
    prefecture
    amount
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const CreateHandoverMessageDocument = gql`
    mutation createHandoverMessage($input: CreateHandoverMessageInput) {
  createHandoverMessage(input: $input) {
    guid
    subject
    message
    senderName
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const CreateItemDocument = gql`
    mutation createItem($createItemInput: CreateItemInput) {
  createItem(input: $createItemInput) {
    guid
    name
    webCode
    displayName
    code
    janCode
    daishinCode
    makerCode
    sellerCode
    sectionId
    onlyForCatalogOrder
    isFresh
    hasPersonalizedProperty
    cannotBeAddedNoshi
    cannotBeAddedWarppingPaper
    cannotBeAddedMessageCard
    canPickupAtStore
    isPostageFree
    sendDirectly
    noshiSize
    purposes {
      guid
      name
      slugName
      categoryName
      displayOrder
      updatedAt
      createdAt
    }
    categories {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    character {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
    brands {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    catalogs {
      guid
      name
      code
      createdAt
      updatedAt
    }
    catalogPage
    content
    keyword
    catchCopy
    comment
    description
    memo
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    imageUrl06
    imageUrl07
    imageUrl08
    catalogUrl
    minimumOrderQuantity
    stockQuantity
    forPrivateUse
    taxType
    price
    postage
    publicationDate
    publicationEndDate
    displayOrder
    createdAt
    updatedAt
    bundledItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    bundledWithItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    onDiscount
    originalPrice
    specialPrice
    affiliatePrice
    specialMemberPrice
    onlyForMember
    isAvailable
    sendday
  }
}
    `;
export const CreateItemBrandDocument = gql`
    mutation createItemBrand($createItemBrandInput: CreateItemBrandInput) {
  createItemBrand(input: $createItemBrandInput) {
    guid
    slugName
    name
    nameKana
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
  }
}
    `;
export const CreateItemCategoryDocument = gql`
    mutation createItemCategory($createItemCategoryInput: CreateItemCategoryInput) {
  createItemCategory(input: $createItemCategoryInput) {
    guid
    name
    aliasName
    slugName
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
    parent {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    children {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      createdAt
      updatedAt
      isAvailable
      parent {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
      }
      children {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
        parent {
          guid
          name
          aliasName
          slugName
          imageUrl
          description
          createdAt
          updatedAt
          isAvailable
          parent {
            guid
            name
            aliasName
            slugName
            imageUrl
            description
            createdAt
            updatedAt
            isAvailable
          }
        }
      }
    }
  }
}
    `;
export const CreateItemPriceRangeDocument = gql`
    mutation createItemPriceRange($createItemPriceRangeInput: CreateItemPriceRangeInput) {
  createItemPriceRange(input: $createItemPriceRangeInput) {
    guid
    name
    lowerLimit
    upperLimit
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const CreateMemberDocument = gql`
    mutation createMember($createMemberInput: CreateMemberInput) {
  createMember(input: $createMemberInput) {
    guid
    id
    email
    accountNumberBC
    address1
    address2
    address3
    birthday
    cellPhone
    memo
    name
    nameKana
    phone
    postalCode
    prefecture
    sex
    isAvailable
    hasSubscriptionToMailzine
    createdAt
    updatedAt
  }
}
    `;
export const CreateOptionDocument = gql`
    mutation createOption($createOptionInput: CreateOptionInput) {
  createOption(input: $createOptionInput) {
    guid
    expressionName
    memo
    name
    displayOrder
    optionType
    createdAt
    updatedAt
    optionItems {
      guid
      name
      displayOrder
      imageUrl
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const CreateOptionItemDocument = gql`
    mutation createOptionItem($createOptionItemInput: CreateOptionItemInput) {
  createOptionItem(input: $createOptionItemInput) {
    guid
    displayOrder
    imageUrl
    name
    optionType
    oldPackingId
    createdAt
    updatedAt
    properties {
      guid
      name
      slugName
      propertyType
      placeholderText
      cautionText
      createdAt
      updatedAt
    }
  }
}
    `;
export const CreatePointRateDocument = gql`
    mutation createPointRate($createPointRateInput: CreatePointRateInput) {
  createPointRate(input: $createPointRateInput) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
  }
}
    `;
export const CreatePropertyDocument = gql`
    mutation createProperty($createPropertyInput: CreatePropertyInput) {
  createProperty(input: $createPropertyInput) {
    guid
    name
    slugName
    propertyType
    placeholderText
    cautionText
    createdAt
    updatedAt
  }
}
    `;
export const CreatePurposeDocument = gql`
    mutation createPurpose($createPurposeInput: CreatePurposeInput) {
  createPurpose(input: $createPurposeInput) {
    guid
    slugName
    updatedAt
    createdAt
    name
    categoryName
    displayOrder
    isAvailable
    freeShippingLine
    purposeNo
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const CreateShopInformationDocument = gql`
    mutation createShopInformation($createShopInformationInput: CreateShopInformationInput) {
  createShopInformation(input: $createShopInformationInput) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const CreateShopSettingDocument = gql`
    mutation createShopSetting($input: CreateShopSettingInput) {
  createShopSetting(input: $input) {
    guid
    discountRateDefermentDays
    contactEmailAddress
    maxOrderAmountForDeferredPayment
    minOrderAmountForFreeShipping
    pointGrantRate
    privacyPolicy
    shopCode
    shopName
    specifiedCommercialTransactionAct
    contactAddress
  }
}
    `;
export const DeleteCatalogDocument = gql`
    mutation deleteCatalog($deleteCatalogInput: DeleteCatalogInput) {
  deleteCatalog(input: $deleteCatalogInput) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const DeleteCommunicationMessageDocument = gql`
    mutation deleteCommunicationMessage($input: DeleteCommunicationMessageInput) {
  deleteCommunicationMessage(input: $input) {
    guid
    subject
    message
    senderName
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    notifyDate
    readDate
    createdAt
    updatedAt
  }
}
    `;
export const DeleteCouponDocument = gql`
    mutation deleteCoupon($deleteCouponInput: DeleteCouponInput) {
  deleteCoupon(input: $deleteCouponInput) {
    guid
    title
    slugName
    amount
    description
    minPriceToGain
    minPriceToUse
    createdAt
    updatedAt
  }
}
    `;
export const DeleteDeliverdCouponDocument = gql`
    mutation deleteDeliverdCoupon($deleteDeliverdCouponInput: DeleteDeliverdCouponInput) {
  deleteDeliverdCoupon(input: $deleteDeliverdCouponInput) {
    guid
    expirationDate
    couponGuid
    couponTitle
    couponSlugName
    couponAmount
    couponDescription
    couponMinPriceToGain
    couponMinPriceToUse
    createdAt
    updatedAt
  }
}
    `;
export const DeleteDiscountRateDocument = gql`
    mutation deleteDiscountRate($deleteDiscountRateInput: DeleteDiscountRateInput) {
  deleteDiscountRate(input: $deleteDiscountRateInput) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
  }
}
    `;
export const DeleteDomesticPostageFeeDocument = gql`
    mutation deleteDomesticPostageFee($deleteDomesticPostageFeeInput: DeleteDomesticPostageFeeInput) {
  deleteDomesticPostageFee(input: $deleteDomesticPostageFeeInput) {
    guid
    prefecture
    amount
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const DeleteHandoverMessageDocument = gql`
    mutation deleteHandoverMessage($input: DeleteHandoverMessageInput) {
  deleteHandoverMessage(input: $input) {
    guid
    subject
    message
    senderName
    readDate
    createdAt
    updatedAt
  }
}
    `;
export const DeleteItemDocument = gql`
    mutation deleteItem($deleteItemInput: DeleteItemInput) {
  deleteItem(input: $deleteItemInput) {
    guid
    name
    webCode
    displayName
    code
    janCode
    daishinCode
    makerCode
    sellerCode
    sectionId
    onlyForCatalogOrder
    isFresh
    hasPersonalizedProperty
    cannotBeAddedNoshi
    cannotBeAddedWarppingPaper
    cannotBeAddedMessageCard
    canPickupAtStore
    isPostageFree
    sendDirectly
    noshiSize
    catalogPage
    content
    keyword
    catchCopy
    comment
    description
    memo
    minimumOrderQuantity
    stockQuantity
    forPrivateUse
    taxType
    price
    postage
    publicationDate
    publicationEndDate
    displayOrder
    createdAt
    updatedAt
    onDiscount
    originalPrice
    specialPrice
    affiliatePrice
    specialMemberPrice
    onlyForMember
    isAvailable
    catalogUrl
    sendday
  }
}
    `;
export const DeleteItemBrandDocument = gql`
    mutation deleteItemBrand($deleteItemBrandInput: DeleteItemBrandInput) {
  deleteItemBrand(input: $deleteItemBrandInput) {
    guid
    slugName
    name
    nameKana
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
  }
}
    `;
export const DeleteItemCategoryDocument = gql`
    mutation deleteItemCategory($deleteItemCategoryInput: DeleteItemCategoryInput) {
  deleteItemCategory(input: $deleteItemCategoryInput) {
    guid
    name
    aliasName
    slugName
    imageUrl
    description
    displayOrder
    isAvailable
    createdAt
    updatedAt
  }
}
    `;
export const DeleteItemPriceRangeDocument = gql`
    mutation deleteItemPriceRange($deleteItemPriceRangeInput: DeleteItemPriceRangeInput) {
  deleteItemPriceRange(input: $deleteItemPriceRangeInput) {
    guid
    name
    lowerLimit
    upperLimit
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const DeleteMemberDocument = gql`
    mutation deleteMember($deleteMemberInput: DeleteMemberInput) {
  deleteMember(input: $deleteMemberInput) {
    guid
    id
    email
    accountNumberBC
    address1
    address2
    address3
    birthday
    cellPhone
    memo
    name
    nameKana
    phone
    postalCode
    prefecture
    sex
    isAvailable
    hasSubscriptionToMailzine
    createdAt
    updatedAt
  }
}
    `;
export const DeleteOptionDocument = gql`
    mutation deleteOption($deleteOptionInput: DeleteOptionInput) {
  deleteOption(input: $deleteOptionInput) {
    guid
    expressionName
    memo
    name
    displayOrder
    optionType
    createdAt
    updatedAt
  }
}
    `;
export const DeleteOptionItemDocument = gql`
    mutation deleteOptionItem($deleteOptionItemInput: DeleteOptionItemInput) {
  deleteOptionItem(input: $deleteOptionItemInput) {
    guid
    displayOrder
    name
    optionType
    oldPackingId
    createdAt
    updatedAt
  }
}
    `;
export const DeletePointRateDocument = gql`
    mutation deletePointRate($deletePointRateInput: DeletePointRateInput) {
  deletePointRate(input: $deletePointRateInput) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
  }
}
    `;
export const DeletePropertyDocument = gql`
    mutation deleteProperty($deletePropertyInput: DeletePropertyInput) {
  deleteProperty(input: $deletePropertyInput) {
    guid
    name
    slugName
    propertyType
    placeholderText
    cautionText
    createdAt
    updatedAt
  }
}
    `;
export const DeletePurposeDocument = gql`
    mutation deletePurpose($deletePurposeInput: DeletePurposeInput) {
  deletePurpose(input: $deletePurposeInput) {
    guid
    slugName
    updatedAt
    createdAt
    name
    categoryName
    displayOrder
    isAvailable
    freeShippingLine
    purposeNo
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const DeleteShopInformationDocument = gql`
    mutation deleteShopInformation($deleteShopInformationInput: DeleteShopInformationInput) {
  deleteShopInformation(input: $deleteShopInformationInput) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const LaunchExportItemsDocument = gql`
    mutation launchExportItems {
  launchExportItems
}
    `;
export const LaunchImportItemsDocument = gql`
    mutation launchImportItems {
  launchImportItems {
    guid
    name
    webCode
    displayName
    code
    janCode
    daishinCode
    makerCode
    sellerCode
    sectionId
    onlyForCatalogOrder
    isFresh
    hasPersonalizedProperty
    cannotBeAddedNoshi
    cannotBeAddedWarppingPaper
    cannotBeAddedMessageCard
    canPickupAtStore
    isPostageFree
    sendDirectly
    noshiSize
    purposes {
      guid
      name
      slugName
      categoryName
      displayOrder
      updatedAt
      createdAt
    }
    categories {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
    }
    character {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
    brands {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    catalogs {
      guid
      name
      code
      createdAt
      updatedAt
    }
    catalogPage
    content
    keyword
    catchCopy
    comment
    description
    memo
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    imageUrl06
    imageUrl07
    imageUrl08
    minimumOrderQuantity
    stockQuantity
    forPrivateUse
    taxType
    price
    postage
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
    bundledItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    bundledWithItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    onDiscount
    originalPrice
    specialPrice
    affiliatePrice
    specialMemberPrice
    onlyForMember
    isAvailable
  }
}
    `;
export const NotifyMessageCardDesingCommunicationMessageDocument = gql`
    mutation notifyMessageCardDesingCommunicationMessage($input: NotifyCommunicationMessageInput) {
  notifyMessageCardDesingCommunicationMessage(input: $input) {
    guid
    subject
    message
    senderName
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    notifyDate
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const TestRenderEmailTemplateDocument = gql`
    mutation testRenderEmailTemplate($input: TestRenderEmailTemplateInput) {
  testRenderEmailTemplate(input: $input)
}
    `;
export const TransitionToCanceledDocument = gql`
    mutation transitionToCanceled($guid: ID!) {
  transitionToCanceled(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToDeletedDocument = gql`
    mutation transitionToDeleted($guid: ID!) {
  transitionToDeleted(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      isAvailable
      sex
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToDeliveryCompletedDocument = gql`
    mutation transitionToDeliveryCompleted($guid: ID!) {
  transitionToDeliveryCompleted(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToGettingReadyDocument = gql`
    mutation transitionToGettingReady($guid: ID!) {
  transitionToGettingReady(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToImportedDeliverySlipDocument = gql`
    mutation transitionToImportedDeliverySlip($guid: ID!) {
  transitionToImportedDeliverySlip(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToImportingDeliverySlipDocument = gql`
    mutation transitionToImportingDeliverySlip($guid: ID!) {
  transitionToImportingDeliverySlip(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToPaidDocument = gql`
    mutation transitionToPaid($guid: ID!) {
  transitionToPaid(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToPendingForRejectionDocument = gql`
    mutation transitionToPendingForRejection($guid: ID!) {
  transitionToPendingForRejection(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToRefundingDocument = gql`
    mutation transitionToRefunding($guid: ID!) {
  transitionToRefunding(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToWaitingForAuthDeferredPaymentDocument = gql`
    mutation transitionToWaitingForAuthDeferredPayment($guid: ID!) {
  transitionToWaitingForAuthDeferredPayment(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToWaitingForBankTransferDocument = gql`
    mutation transitionToWaitingForBankTransfer($guid: ID!) {
  transitionToWaitingForBankTransfer(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const TransitionToWaitingForCreditCardPaymentDocument = gql`
    mutation transitionToWaitingForCreditCardPayment($guid: ID!) {
  transitionToWaitingForCreditCardPayment(guid: $guid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      deliveryName
      guid
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const UpdateBundledItemsDocument = gql`
    mutation updateBundledItems($updateBundledItemsInput: UpdateBundledItemsInput) {
  updateBundledItems(input: $updateBundledItemsInput) {
    guid
    name
    webCode
    displayName
    code
    janCode
    daishinCode
    makerCode
    sellerCode
    sectionId
    onlyForCatalogOrder
    isFresh
    hasPersonalizedProperty
    cannotBeAddedNoshi
    cannotBeAddedWarppingPaper
    cannotBeAddedMessageCard
    canPickupAtStore
    isPostageFree
    sendDirectly
    noshiSize
    purposes {
      guid
      name
      slugName
      categoryName
      displayOrder
      updatedAt
      createdAt
    }
    categories {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      isAvailable
      createdAt
      updatedAt
    }
    character {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
    brands {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    catalogs {
      guid
      name
      code
      createdAt
      updatedAt
    }
    catalogPage
    content
    keyword
    catchCopy
    comment
    description
    memo
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    imageUrl06
    imageUrl07
    imageUrl08
    catalogUrl
    minimumOrderQuantity
    stockQuantity
    forPrivateUse
    taxType
    price
    postage
    publicationDate
    publicationEndDate
    displayOrder
    createdAt
    updatedAt
    bundledItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    bundledWithItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    onDiscount
    originalPrice
    specialPrice
    affiliatePrice
    specialMemberPrice
    onlyForMember
    isAvailable
  }
}
    `;
export const UpdateCatalogDocument = gql`
    mutation updateCatalog($updateCatalogInput: UpdateCatalogInput) {
  updateCatalog(input: $updateCatalogInput) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const UpdateCommunicationMessageDocument = gql`
    mutation updateCommunicationMessage($input: UpdateCommunicationMessageInput) {
  updateCommunicationMessage(input: $input) {
    guid
    subject
    message
    senderName
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    notifyDate
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($updateCouponInput: UpdateCouponInput) {
  updateCoupon(input: $updateCouponInput) {
    guid
    title
    slugName
    amount
    description
    minPriceToGain
    minPriceToUse
    createdAt
    updatedAt
  }
}
    `;
export const UpdateDeliverdCouponDocument = gql`
    mutation updateDeliverdCoupon($updateDeliverdCouponInput: UpdateDeliverdCouponInput) {
  updateDeliverdCoupon(input: $updateDeliverdCouponInput) {
    guid
    expirationDate
    couponGuid
    couponTitle
    couponSlugName
    couponAmount
    couponDescription
    couponMinPriceToGain
    couponMinPriceToUse
    createdAt
    updatedAt
    coupon {
      guid
    }
    member {
      guid
    }
  }
}
    `;
export const UpdateDeliverySlipNoDocument = gql`
    mutation updateDeliverySlipNo($input: UpdateDeliverySlipNoInput) {
  updateDeliverySlipNo(input: $input) {
    guid
    deliverySlipNo
    deliveryTraderNo
    deliveryName
    deliveryAddress
    deliveryDate
    deliveryPhone
    deliveryPostalCode
    deliveryPrefecture
    deliveryAddressType
    postageFee
    postageFeeWithTax
    disabled
    srcDeliveryGuid
    createdAt
    updatedAt
  }
}
    `;
export const UpdateDiscountRateDocument = gql`
    mutation updateDiscountRate($updateDiscountRateInput: UpdateDiscountRateInput) {
  updateDiscountRate(input: $updateDiscountRateInput) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
  }
}
    `;
export const UpdateDomesticPostageFeeDocument = gql`
    mutation updateDomesticPostageFee($updateDomesticPostageFeeInput: UpdateDomesticPostageFeeInput) {
  updateDomesticPostageFee(input: $updateDomesticPostageFeeInput) {
    guid
    prefecture
    amount
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const UpdateHandoverMessageDocument = gql`
    mutation updateHandoverMessage($input: UpdateHandoverMessageInput) {
  updateHandoverMessage(input: $input) {
    guid
    subject
    message
    senderName
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const UpdateItemDocument = gql`
    mutation updateItem($updateItemInput: UpdateItemInput) {
  updateItem(input: $updateItemInput) {
    guid
    name
    webCode
    displayName
    code
    janCode
    daishinCode
    makerCode
    sellerCode
    sectionId
    onlyForCatalogOrder
    isFresh
    hasPersonalizedProperty
    cannotBeAddedNoshi
    cannotBeAddedWarppingPaper
    cannotBeAddedMessageCard
    canPickupAtStore
    isPostageFree
    sendDirectly
    noshiSize
    purposes {
      guid
      name
      slugName
      categoryName
      displayOrder
      updatedAt
      createdAt
    }
    categories {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      isAvailable
      createdAt
      updatedAt
    }
    character {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
    brands {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    catalogs {
      guid
      name
      code
      createdAt
      updatedAt
    }
    catalogPage
    content
    keyword
    catchCopy
    comment
    description
    memo
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    imageUrl06
    imageUrl07
    imageUrl08
    catalogUrl
    minimumOrderQuantity
    stockQuantity
    forPrivateUse
    taxType
    price
    postage
    publicationDate
    publicationEndDate
    displayOrder
    createdAt
    updatedAt
    bundledItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    bundledWithItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    onDiscount
    originalPrice
    specialPrice
    affiliatePrice
    specialMemberPrice
    onlyForMember
    isAvailable
    sendday
  }
}
    `;
export const UpdateItemBrandDocument = gql`
    mutation updateItemBrand($updateItemBrandInput: UpdateItemBrandInput) {
  updateItemBrand(input: $updateItemBrandInput) {
    guid
    slugName
    name
    nameKana
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
  }
}
    `;
export const UpdateItemCategoryDocument = gql`
    mutation updateItemCategory($updateItemCategoryInput: UpdateItemCategoryInput) {
  updateItemCategory(input: $updateItemCategoryInput) {
    guid
    name
    aliasName
    slugName
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
    parent {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    children {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      createdAt
      updatedAt
      isAvailable
      parent {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
      }
      children {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
        parent {
          guid
          name
          aliasName
          slugName
          imageUrl
          description
          createdAt
          updatedAt
          isAvailable
          parent {
            guid
            name
            aliasName
            slugName
            imageUrl
            description
            createdAt
            updatedAt
            isAvailable
          }
        }
      }
    }
  }
}
    `;
export const UpdateItemPriceRangeDocument = gql`
    mutation updateItemPriceRange($updateItemPriceRangeInput: UpdateItemPriceRangeInput) {
  updateItemPriceRange(input: $updateItemPriceRangeInput) {
    guid
    name
    lowerLimit
    upperLimit
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const UpdateMemberDocument = gql`
    mutation updateMember($updateMemberInput: UpdateMemberInput) {
  updateMember(input: $updateMemberInput) {
    guid
    id
    email
    accountNumberBC
    address1
    address2
    address3
    birthday
    cellPhone
    memo
    name
    nameKana
    phone
    postalCode
    prefecture
    sex
    isAvailable
    hasSubscriptionToMailzine
    createdAt
    updatedAt
  }
}
    `;
export const UpdateOptionDocument = gql`
    mutation updateOption($updateOptionInput: UpdateOptionInput) {
  updateOption(input: $updateOptionInput) {
    guid
    expressionName
    memo
    name
    displayOrder
    optionType
    createdAt
    updatedAt
    optionItems {
      guid
      name
      displayOrder
      imageUrl
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const UpdateOptionItemDocument = gql`
    mutation updateOptionItem($updateOptionItemInput: UpdateOptionItemInput) {
  updateOptionItem(input: $updateOptionItemInput) {
    guid
    displayOrder
    imageUrl
    name
    optionType
    oldPackingId
    properties {
      guid
      name
      slugName
      propertyType
      placeholderText
      cautionText
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export const UpdatePointRateDocument = gql`
    mutation updatePointRate($updatePointRateInput: UpdatePointRateInput) {
  updatePointRate(input: $updatePointRateInput) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
  }
}
    `;
export const UpdatePropertyDocument = gql`
    mutation updateProperty($updatePropertyInput: UpdatePropertyInput) {
  updateProperty(input: $updatePropertyInput) {
    guid
    name
    slugName
    propertyType
    placeholderText
    cautionText
    createdAt
    updatedAt
  }
}
    `;
export const UpdatePurposeDocument = gql`
    mutation updatePurpose($updatePurposeInput: UpdatePurposeInput) {
  updatePurpose(input: $updatePurposeInput) {
    guid
    slugName
    updatedAt
    createdAt
    name
    categoryName
    displayOrder
    isAvailable
    freeShippingLine
    purposeNo
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const UpdateShopInformationDocument = gql`
    mutation updateShopInformation($updateShopInformationInput: UpdateShopInformationInput) {
  updateShopInformation(input: $updateShopInformationInput) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const UpdateShopSettingDocument = gql`
    mutation updateShopSetting($updateShopSettingInput: UpdateShopSettingInput) {
  updateShopSetting(input: $updateShopSettingInput) {
    guid
    shopCode
    shopName
    contactAddress
    contactEmailAddress
    discountRateDefermentDays
    maxOrderAmountForDeferredPayment
    minOrderAmountForFreeShipping
    privacyPolicy
    specifiedCommercialTransactionAct
    pointGrantRate
  }
}
    `;
export const UpsertCatalogsDocument = gql`
    mutation upsertCatalogs($upsertCatalogsInput: [UpsertCatalogInput]) {
  upsertCatalogs(input: $upsertCatalogsInput) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const UpsertEmailTemplateDocument = gql`
    mutation upsertEmailTemplate($input: UpsertEmailTemplateInput) {
  upsertEmailTemplate(input: $input) {
    HtmlPart
    SubjectPart
    TemplateName
    TextPart
  }
}
    `;
export const Base64NavFileDocument = gql`
    query base64NavFile($guid: ID!) {
  base64NavFile(guid: $guid)
}
    `;
export const Base64SalesSlipExcelDocument = gql`
    query base64SalesSlipExcel($guid: ID!) {
  base64SalesSlipExcel(guid: $guid)
}
    `;
export const CatalogDocument = gql`
    query catalog($catalogGuid: ID!) {
  catalog(guid: $catalogGuid) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const CatalogByCodeDocument = gql`
    query catalogByCode($catalogByCodeCode: ID!) {
  catalogByCode(code: $catalogByCodeCode) {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const CatalogsDocument = gql`
    query catalogs {
  catalogs {
    guid
    name
    code
    createdAt
    updatedAt
  }
}
    `;
export const CommunicationMessageDocument = gql`
    query communicationMessage($guid: ID!) {
  communicationMessage(guid: $guid) {
    guid
    subject
    message
    senderName
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    notifyDate
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const CommunicationMessagesDocument = gql`
    query communicationMessages($filter: CommunicationMessageFilter, $orderBy: CommunicationMessageOrderByInput, $skip: Int, $take: Int) {
  communicationMessages(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    guid
    subject
    message
    senderName
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    notifyDate
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const CountItemsDocument = gql`
    query countItems($filter: ItemFilter) {
  countItems(filter: $filter)
}
    `;
export const CountMembersDocument = gql`
    query countMembers($filter: MemberFilter) {
  countMembers(filter: $filter)
}
    `;
export const CountOrdersDocument = gql`
    query countOrders($filter: OrderFilter) {
  countOrders(filter: $filter)
}
    `;
export const CouponDocument = gql`
    query coupon($key: FindUniqueCouponKey!) {
  coupon(key: $key) {
    guid
    title
    slugName
    amount
    description
    minPriceToGain
    minPriceToUse
    createdAt
    updatedAt
  }
}
    `;
export const CouponHistoriesDocument = gql`
    query couponHistories {
  couponHistories {
    guid
    transactionDate
    debitCreditType
    couponGuid
    couponTitle
    couponSlugName
    couponAmount
    couponDescription
    couponMinPriceToGain
    couponMinPriceToUse
    couponExpirationDate
    createdAt
    updatedAt
    member {
      guid
    }
  }
}
    `;
export const CouponsDocument = gql`
    query coupons {
  coupons {
    guid
    title
    slugName
    amount
    description
    minPriceToGain
    minPriceToUse
    createdAt
    updatedAt
  }
}
    `;
export const DeliverdCouponDocument = gql`
    query deliverdCoupon($guid: ID!) {
  deliverdCoupon(guid: $guid) {
    guid
    couponGuid
    couponTitle
    couponSlugName
    couponAmount
    couponDescription
    couponMinPriceToGain
    couponMinPriceToUse
    expirationDate
    createdAt
    updatedAt
    isUsed
    usedAt
    coupon {
      guid
    }
    order {
      guid
      id
    }
    member {
      guid
    }
  }
}
    `;
export const DeliverdCouponsDocument = gql`
    query deliverdCoupons($filter: DeliverdCouponFilter, $orderBy: DeliverdCouponOrderByInput, $skip: Int, $take: Int) {
  deliverdCoupons(filter: $filter, skip: $skip, take: $take, orderBy: $orderBy) {
    guid
    couponGuid
    couponTitle
    couponSlugName
    couponAmount
    couponDescription
    couponMinPriceToGain
    couponMinPriceToUse
    expirationDate
    createdAt
    updatedAt
    isUsed
    usedAt
    coupon {
      guid
    }
    order {
      guid
      id
    }
    member {
      guid
    }
  }
}
    `;
export const DeliveryDocument = gql`
    query delivery($key: FindUniqueDeliveryKey) {
  delivery(key: $key) {
    guid
    id
    deliverySlipNo
    deliveryTraderNo
    deliveryName
    deliveryAddress
    deliveryDate
    deliveryPhone
    deliveryPostalCode
    deliveryPrefecture
    deliveryAddressType
    postageFee
    postageFeeWithTax
    disabled
    srcDeliveryGuid
    createdAt
    updatedAt
    purpose {
      guid
      slugName
      updatedAt
      createdAt
      name
      categoryName
      displayOrder
      isAvailable
      freeShippingLine
      purposeNo
    }
    contact {
      guid
    }
    orderOptions {
      guid
      optionName
      optionMemo
      optionExpressionName
      optionType
      optionItemName
      optionItemImageUrl
      createdAt
      updatedAt
      orderOptionPropertyValues {
        name
        guid
        slugName
        value
        imageUrl
        createdAt
        updatedAt
        propertyType
      }
    }
    deliveryDetails {
      guid
      quantity
      unitPrice
      unitPriceWithTax
      price
      priceWithTax
      name
      displayName
      displayOrder
      sectionId
      daishinCode
      webCode
      janCode
      catalogPage
      onlyForCatalogOrder
      keyword
      catchCopy
      comment
      isFresh
      makerCode
      sellerCode
      memo
      minimumOrderQuantity
      stockQuantity
      sendDirectly
      noshiSize
      addNoshi
      forPrivateUse
      code
      taxType
      content
      postage
      postageWithTax
      isPostageFree
      canPickupAtStore
      hasPersonalizedProperty
      cannotBeAddedNoshi
      cannotBeAddedWarppingPaper
      cannotBeAddedMessageCard
      sendday
      description
      isAvailable
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
      imageUrl06
      imageUrl07
      imageUrl08
      publicationDate
      publicationEndDate
      createdAt
      updatedAt
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
    }
  }
}
    `;
export const DiscountRateDocument = gql`
    query discountRate($discountRateGuid: ID!) {
  discountRate(guid: $discountRateGuid) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const DiscountRatesDocument = gql`
    query discountRates($discountRatesFilter: DiscountRateFilter) {
  discountRates(filter: $discountRatesFilter) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const DomesticPostageFeeDocument = gql`
    query domesticPostageFee($domesticPostageFeeKey: FindUniqueDomesticPostageFeeKey!) {
  domesticPostageFee(key: $domesticPostageFeeKey) {
    guid
    prefecture
    amount
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const DomesticPostageFeesDocument = gql`
    query domesticPostageFees {
  domesticPostageFees {
    guid
    prefecture
    amount
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const EmailTemplateDocument = gql`
    query emailTemplate($templateName: String!) {
  emailTemplate(templateName: $templateName) {
    HtmlPart
    SubjectPart
    TemplateName
    TextPart
  }
}
    `;
export const EmailTemplatesDocument = gql`
    query emailTemplates {
  emailTemplates {
    HtmlPart
    SubjectPart
    TemplateName
    TextPart
  }
}
    `;
export const HandoverMessageDocument = gql`
    query handoverMessage($guid: ID!) {
  handoverMessage(guid: $guid) {
    guid
    subject
    message
    senderName
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const HandoverMessagesDocument = gql`
    query handoverMessages($filter: HandoverMessageFilter, $orderBy: HandoverMessageOrderByInput, $skip: Int, $take: Int) {
  handoverMessages(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    guid
    subject
    message
    senderName
    readDate
    createdAt
    updatedAt
    order {
      guid
      id
    }
  }
}
    `;
export const ItemDocument = gql`
    query item($itemKey: FindUniqueItemKey!) {
  item(key: $itemKey) {
    guid
    name
    webCode
    displayName
    code
    janCode
    daishinCode
    makerCode
    sellerCode
    sectionId
    onlyForCatalogOrder
    isFresh
    hasPersonalizedProperty
    cannotBeAddedNoshi
    cannotBeAddedWarppingPaper
    cannotBeAddedMessageCard
    canPickupAtStore
    isPostageFree
    sendDirectly
    noshiSize
    purposes {
      guid
      name
      slugName
      categoryName
      displayOrder
      updatedAt
      createdAt
    }
    categories {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      isAvailable
      createdAt
      updatedAt
    }
    character {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      isAvailable
      createdAt
      updatedAt
      isAvailable
    }
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
    brands {
      guid
      slugName
      name
      nameKana
      imageUrl
      description
      displayOrder
      isAvailable
      createdAt
      updatedAt
      isAvailable
    }
    catalogs {
      guid
      name
      code
      createdAt
      updatedAt
    }
    catalogPage
    content
    keyword
    catchCopy
    comment
    description
    memo
    imageUrl01
    imageUrl02
    imageUrl03
    imageUrl04
    imageUrl05
    imageUrl06
    imageUrl07
    imageUrl08
    catalogUrl
    minimumOrderQuantity
    stockQuantity
    forPrivateUse
    taxType
    price
    postage
    publicationDate
    publicationEndDate
    displayOrder
    createdAt
    updatedAt
    sendday
    bundledItems {
      guid
      name
      displayName
      code
      janCode
      daishinCode
      makerCode
      sellerCode
      sectionId
    }
    onDiscount
    originalPrice
    specialPrice
    affiliatePrice
    specialMemberPrice
    onlyForMember
    isAvailable
  }
}
    `;
export const ItemBrandDocument = gql`
    query itemBrand($itemBrandGuid: ID!) {
  itemBrand(guid: $itemBrandGuid) {
    guid
    slugName
    name
    nameKana
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
  }
}
    `;
export const ItemBrandsDocument = gql`
    query itemBrands {
  itemBrands {
    guid
    slugName
    name
    nameKana
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
  }
}
    `;
export const ItemCategoriesDocument = gql`
    query itemCategories {
  itemCategories {
    guid
    name
    aliasName
    slugName
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
    parent {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    children {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      createdAt
      updatedAt
      isAvailable
      parent {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
      }
      children {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
        parent {
          guid
          name
          aliasName
          slugName
          imageUrl
          description
          createdAt
          updatedAt
          isAvailable
          parent {
            guid
            name
            aliasName
            slugName
            imageUrl
            description
            createdAt
            updatedAt
            isAvailable
          }
        }
      }
    }
  }
}
    `;
export const ItemCategoryDocument = gql`
    query itemCategory($itemCategoryGuid: ID!) {
  itemCategory(guid: $itemCategoryGuid) {
    guid
    name
    aliasName
    slugName
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
    parent {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    children {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      createdAt
      updatedAt
      isAvailable
      parent {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
      }
      children {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
        parent {
          guid
          name
          aliasName
          slugName
          imageUrl
          description
          createdAt
          updatedAt
          isAvailable
          parent {
            guid
            name
            aliasName
            slugName
            imageUrl
            description
            createdAt
            updatedAt
            isAvailable
          }
        }
      }
    }
  }
}
    `;
export const ItemPriceRangeDocument = gql`
    query itemPriceRange($itemPriceRangeGuid: ID!) {
  itemPriceRange(guid: $itemPriceRangeGuid) {
    createdAt
    displayOrder
    guid
    lowerLimit
    name
    updatedAt
    upperLimit
  }
}
    `;
export const ItemPriceRangesDocument = gql`
    query itemPriceRanges {
  itemPriceRanges {
    guid
    name
    lowerLimit
    upperLimit
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const ItemsDocument = gql`
    query items($filter: ItemFilter, $orderBy: ItemOrderByInput, $skip: Int, $take: Int) {
  items(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    guid
    name
    webCode
    displayName
    code
    janCode
    displayOrder
    daishinCode
    makerCode
    sellerCode
    sectionId
    onlyForCatalogOrder
    isFresh
    hasPersonalizedProperty
    cannotBeAddedNoshi
    cannotBeAddedWarppingPaper
    cannotBeAddedMessageCard
    canPickupAtStore
    isPostageFree
    sendDirectly
    noshiSize
    catalogPage
    content
    keyword
    catchCopy
    comment
    description
    memo
    minimumOrderQuantity
    stockQuantity
    forPrivateUse
    taxType
    price
    priceWithTax
    postage
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
    onDiscount
    originalPrice
    specialPrice
    affiliatePrice
    specialMemberPrice
    onlyForMember
    isAvailable
    sendday
  }
}
    `;
export const MemberDocument = gql`
    query member($memberKey: FindUniqueMemberKey!) {
  member(key: $memberKey) {
    guid
    id
    email
    accountNumberBC
    address1
    address2
    address3
    birthday
    cellPhone
    memo
    name
    nameKana
    phone
    postalCode
    prefecture
    sex
    isAvailable
    hasSubscriptionToMailzine
    createdAt
    updatedAt
    orders {
      guid
      id
      status
      transactionDate
    }
  }
}
    `;
export const MembersDocument = gql`
    query members($filter: MemberFilter, $take: Int, $skip: Int, $orderBy: MemberOrderByInput) {
  members(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
    guid
    id
    email
    accountNumberBC
    address1
    address2
    address3
    birthday
    cellPhone
    memo
    name
    nameKana
    phone
    postalCode
    prefecture
    sex
    isAvailable
    hasSubscriptionToMailzine
    createdAt
    updatedAt
    orders {
      guid
      id
      status
      transactionDate
    }
  }
}
    `;
export const NavsDocument = gql`
    query navs($guid: ID!) {
  navs(guid: $guid) {
    id
    memberId
    name
    purposeNo
    totalPriceWithTax
    postageFeeWithTax
    deliveryDate
    transactionDate
    np
    site
    deliveryDateSelect
    senders {
      senderName
      senderId
      senderPostalcode
      senderAddress
      senderPhone
    }
    deliveries {
      id
      deliveryName
      deliveryPostalCode
      deliveryAddress
      deliveryPhone
      deliveryDateSelect
      includedDeliveryIds
      items {
        webCode
        name
        quantity
        unitPriceWithTax
        deliveryItemRow
      }
    }
  }
}
    `;
export const OptionDocument = gql`
    query option($optionGuid: ID!) {
  option(guid: $optionGuid) {
    guid
    expressionName
    memo
    name
    displayOrder
    optionType
    createdAt
    updatedAt
    optionItems {
      guid
      name
      displayOrder
      imageUrl
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const OptionItemDocument = gql`
    query optionItem($optionItemGuid: ID!) {
  optionItem(guid: $optionItemGuid) {
    guid
    displayOrder
    imageUrl
    name
    optionType
    oldPackingId
    properties {
      guid
      name
      slugName
      propertyType
      placeholderText
      cautionText
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export const OptionItemsDocument = gql`
    query optionItems($optionItemFindCondition: OptionItemFindCondition) {
  optionItems(condition: $optionItemFindCondition) {
    guid
    displayOrder
    imageUrl
    name
    optionType
    oldPackingId
    properties {
      guid
      name
      slugName
      propertyType
      placeholderText
      cautionText
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export const OptionsDocument = gql`
    query options {
  options {
    guid
    expressionName
    memo
    name
    displayOrder
    optionType
    createdAt
    updatedAt
    optionItems {
      guid
      name
      displayOrder
      imageUrl
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const OrderDocument = gql`
    query order($orderGuid: ID!) {
  order(guid: $orderGuid) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
      orders {
        guid
        id
        status
        transactionDate
      }
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
    deliveries {
      guid
      id
      deliverySlipNo
      deliveryTraderNo
      deliveryName
      deliveryAddress
      deliveryDate
      deliveryPhone
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
      purpose {
        guid
        slugName
        updatedAt
        createdAt
        name
        categoryName
        displayOrder
        isAvailable
        freeShippingLine
        purposeNo
      }
      contact {
        guid
      }
      orderOptions {
        guid
        optionName
        optionMemo
        optionExpressionName
        optionType
        optionItemName
        optionItemImageUrl
        createdAt
        updatedAt
        orderOptionPropertyValues {
          name
          guid
          slugName
          value
          imageUrl
          createdAt
          updatedAt
          propertyType
        }
      }
      deliveryDetails {
        guid
        quantity
        unitPrice
        unitPriceWithTax
        price
        priceWithTax
        name
        displayName
        displayOrder
        sectionId
        daishinCode
        webCode
        janCode
        catalogPage
        onlyForCatalogOrder
        keyword
        catchCopy
        comment
        isFresh
        makerCode
        sellerCode
        memo
        minimumOrderQuantity
        stockQuantity
        sendDirectly
        noshiSize
        addNoshi
        forPrivateUse
        code
        taxType
        content
        postage
        postageWithTax
        isPostageFree
        canPickupAtStore
        hasPersonalizedProperty
        cannotBeAddedNoshi
        cannotBeAddedWarppingPaper
        cannotBeAddedMessageCard
        sendday
        description
        isAvailable
        imageUrl01
        imageUrl02
        imageUrl03
        imageUrl04
        imageUrl05
        imageUrl06
        imageUrl07
        imageUrl08
        publicationDate
        publicationEndDate
        createdAt
        updatedAt
        orderOptions {
          guid
          optionName
          optionMemo
          optionExpressionName
          optionType
          optionItemName
          optionItemImageUrl
          createdAt
          updatedAt
          orderOptionPropertyValues {
            name
            guid
            slugName
            value
            imageUrl
            createdAt
            updatedAt
            propertyType
          }
        }
      }
    }
  }
}
    `;
export const OrdersDocument = gql`
    query orders($ordersFilter: OrderFilter, $ordersSkip: Int, $ordersTake: Int, $ordersOrderBy: OrderOrderByInput) {
  orders(
    filter: $ordersFilter
    skip: $ordersSkip
    take: $ordersTake
    orderBy: $ordersOrderBy
  ) {
    guid
    id
    status
    transactionDate
    paymentMethodType
    discountRate
    discountPrice
    discountPriceWithTax
    couponDiscountPrice
    pointDiscountPrice
    memberId
    senderName
    senderNameKana
    senderPostalCode
    senderPrefecture
    senderAddress1
    senderAddress2
    senderAddress3
    senderPhone
    senderEmail
    orderNote
    chargePrice
    chargePriceWithTax
    createdAt
    updatedAt
    subTotalPriceOnDiscount
    subTotalPriceOnDiscountWithTax
    subTotalPriceNotOnDiscount
    subTotalPriceNotOnDiscountWithTax
    totalPostageFee
    totalPostageFeeWithTax
    totalPrice
    totalPriceWithTax
    totalPriceOnNormalTax
    totalPriceOnNormalTaxWithTax
    totalPriceOnReducedTax
    totalPriceOnReducedTaxWithTax
    member {
      guid
      id
      email
      accountNumberBC
      address1
      address2
      address3
      birthday
      cellPhone
      memo
      name
      nameKana
      phone
      postalCode
      prefecture
      sex
      isAvailable
      hasSubscriptionToMailzine
      createdAt
      updatedAt
      orders {
        guid
        id
        status
        transactionDate
      }
    }
    deliveries {
      guid
      id
      deliverySlipNo
      deliveryTraderNo
      deliveryDate
      deliveryName
      deliveryPostalCode
      deliveryPrefecture
      deliveryAddress
      deliveryPhone
      deliveryAddressType
      postageFee
      postageFeeWithTax
      disabled
      srcDeliveryGuid
      createdAt
      updatedAt
    }
    handoverMessages {
      guid
      senderName
      subject
      message
      readDate
      createdAt
      updatedAt
    }
    communicationMessages {
      guid
      senderName
      subject
      message
      notifyDate
      readDate
      createdAt
      updatedAt
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
    }
  }
}
    `;
export const PointRateDocument = gql`
    query pointRate($pointRateGuid: ID!) {
  pointRate(guid: $pointRateGuid) {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const PointRatesDocument = gql`
    query pointRates {
  pointRates {
    guid
    name
    lowerLimit
    upperLimit
    rate
    displayOrder
    createdAt
    updatedAt
  }
}
    `;
export const PropertiesDocument = gql`
    query properties {
  properties {
    guid
    name
    slugName
    propertyType
    placeholderText
    cautionText
    createdAt
    updatedAt
  }
}
    `;
export const PropertyDocument = gql`
    query property($propertyGuid: ID!) {
  property(guid: $propertyGuid) {
    guid
    name
    slugName
    propertyType
    placeholderText
    cautionText
    createdAt
    updatedAt
  }
}
    `;
export const PurposeDocument = gql`
    query purpose($purposeKey: FindUniquePurposeKey!) {
  purpose(key: $purposeKey) {
    guid
    slugName
    updatedAt
    createdAt
    name
    categoryName
    displayOrder
    isAvailable
    freeShippingLine
    purposeNo
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const PurposesDocument = gql`
    query purposes {
  purposes {
    guid
    slugName
    updatedAt
    createdAt
    name
    categoryName
    displayOrder
    isAvailable
    freeShippingLine
    purposeNo
    options {
      guid
      expressionName
      memo
      name
      displayOrder
      optionType
      createdAt
      updatedAt
    }
  }
}
    `;
export const SearchOptionItemsByOptionTypeDocument = gql`
    query searchOptionItemsByOptionType($searchOptionItemsByOptionTypeOptionType: OptionType) {
  searchOptionItemsByOptionType(
    optionType: $searchOptionItemsByOptionTypeOptionType
  ) {
    guid
    displayOrder
    imageUrl
    name
    optionType
    oldPackingId
    properties {
      guid
      name
      slugName
      propertyType
      placeholderText
      cautionText
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export const ShopInformationDocument = gql`
    query shopInformation($shopInformationGuid: ID!) {
  shopInformation(guid: $shopInformationGuid) {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const ShopInformationsDocument = gql`
    query shopInformations {
  shopInformations {
    guid
    title
    content
    publicationDate
    publicationEndDate
    createdAt
    updatedAt
  }
}
    `;
export const ShopSettingDocument = gql`
    query shopSetting {
  shopSetting {
    guid
    shopCode
    shopName
    contactAddress
    contactEmailAddress
    discountRateDefermentDays
    maxOrderAmountForDeferredPayment
    minOrderAmountForFreeShipping
    privacyPolicy
    specifiedCommercialTransactionAct
    pointGrantRate
  }
}
    `;
export const ToplevelItemCategoriesDocument = gql`
    query toplevelItemCategories {
  toplevelItemCategories {
    guid
    name
    aliasName
    slugName
    imageUrl
    description
    displayOrder
    createdAt
    updatedAt
    isAvailable
    parent {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      displayOrder
      createdAt
      updatedAt
      isAvailable
    }
    children {
      guid
      name
      aliasName
      slugName
      imageUrl
      description
      createdAt
      updatedAt
      isAvailable
      parent {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
      }
      children {
        guid
        name
        aliasName
        slugName
        imageUrl
        description
        createdAt
        updatedAt
        isAvailable
        parent {
          guid
          name
          aliasName
          slugName
          imageUrl
          description
          createdAt
          updatedAt
          isAvailable
          parent {
            guid
            name
            aliasName
            slugName
            imageUrl
            description
            createdAt
            updatedAt
            isAvailable
          }
        }
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createCatalog(variables?: CreateCatalogMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCatalogMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCatalogMutation>(CreateCatalogDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCatalog');
    },
    createCommunicationMessage(variables?: CreateCommunicationMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCommunicationMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCommunicationMessageMutation>(CreateCommunicationMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCommunicationMessage');
    },
    createCoupon(variables?: CreateCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCouponMutation>(CreateCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCoupon');
    },
    createDeliverdCoupon(variables?: CreateDeliverdCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDeliverdCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDeliverdCouponMutation>(CreateDeliverdCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createDeliverdCoupon');
    },
    createDiscountRate(variables?: CreateDiscountRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDiscountRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDiscountRateMutation>(CreateDiscountRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createDiscountRate');
    },
    createDomesticPostageFee(variables?: CreateDomesticPostageFeeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDomesticPostageFeeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDomesticPostageFeeMutation>(CreateDomesticPostageFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createDomesticPostageFee');
    },
    createHandoverMessage(variables?: CreateHandoverMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateHandoverMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateHandoverMessageMutation>(CreateHandoverMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createHandoverMessage');
    },
    createItem(variables?: CreateItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateItemMutation>(CreateItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createItem');
    },
    createItemBrand(variables?: CreateItemBrandMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateItemBrandMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateItemBrandMutation>(CreateItemBrandDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createItemBrand');
    },
    createItemCategory(variables?: CreateItemCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateItemCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateItemCategoryMutation>(CreateItemCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createItemCategory');
    },
    createItemPriceRange(variables?: CreateItemPriceRangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateItemPriceRangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateItemPriceRangeMutation>(CreateItemPriceRangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createItemPriceRange');
    },
    createMember(variables?: CreateMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMemberMutation>(CreateMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createMember');
    },
    createOption(variables?: CreateOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOptionMutation>(CreateOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createOption');
    },
    createOptionItem(variables?: CreateOptionItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateOptionItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOptionItemMutation>(CreateOptionItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createOptionItem');
    },
    createPointRate(variables?: CreatePointRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePointRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePointRateMutation>(CreatePointRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPointRate');
    },
    createProperty(variables?: CreatePropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePropertyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePropertyMutation>(CreatePropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createProperty');
    },
    createPurpose(variables?: CreatePurposeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePurposeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePurposeMutation>(CreatePurposeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPurpose');
    },
    createShopInformation(variables?: CreateShopInformationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateShopInformationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateShopInformationMutation>(CreateShopInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createShopInformation');
    },
    createShopSetting(variables?: CreateShopSettingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateShopSettingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateShopSettingMutation>(CreateShopSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createShopSetting');
    },
    deleteCatalog(variables?: DeleteCatalogMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCatalogMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCatalogMutation>(DeleteCatalogDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCatalog');
    },
    deleteCommunicationMessage(variables?: DeleteCommunicationMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCommunicationMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCommunicationMessageMutation>(DeleteCommunicationMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCommunicationMessage');
    },
    deleteCoupon(variables?: DeleteCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCouponMutation>(DeleteCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCoupon');
    },
    deleteDeliverdCoupon(variables?: DeleteDeliverdCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDeliverdCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDeliverdCouponMutation>(DeleteDeliverdCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDeliverdCoupon');
    },
    deleteDiscountRate(variables?: DeleteDiscountRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDiscountRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDiscountRateMutation>(DeleteDiscountRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDiscountRate');
    },
    deleteDomesticPostageFee(variables?: DeleteDomesticPostageFeeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDomesticPostageFeeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDomesticPostageFeeMutation>(DeleteDomesticPostageFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDomesticPostageFee');
    },
    deleteHandoverMessage(variables?: DeleteHandoverMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteHandoverMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteHandoverMessageMutation>(DeleteHandoverMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteHandoverMessage');
    },
    deleteItem(variables?: DeleteItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteItemMutation>(DeleteItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteItem');
    },
    deleteItemBrand(variables?: DeleteItemBrandMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteItemBrandMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteItemBrandMutation>(DeleteItemBrandDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteItemBrand');
    },
    deleteItemCategory(variables?: DeleteItemCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteItemCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteItemCategoryMutation>(DeleteItemCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteItemCategory');
    },
    deleteItemPriceRange(variables?: DeleteItemPriceRangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteItemPriceRangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteItemPriceRangeMutation>(DeleteItemPriceRangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteItemPriceRange');
    },
    deleteMember(variables?: DeleteMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMemberMutation>(DeleteMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteMember');
    },
    deleteOption(variables?: DeleteOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOptionMutation>(DeleteOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteOption');
    },
    deleteOptionItem(variables?: DeleteOptionItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteOptionItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteOptionItemMutation>(DeleteOptionItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteOptionItem');
    },
    deletePointRate(variables?: DeletePointRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePointRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePointRateMutation>(DeletePointRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deletePointRate');
    },
    deleteProperty(variables?: DeletePropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePropertyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePropertyMutation>(DeletePropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteProperty');
    },
    deletePurpose(variables?: DeletePurposeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePurposeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePurposeMutation>(DeletePurposeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deletePurpose');
    },
    deleteShopInformation(variables?: DeleteShopInformationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteShopInformationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteShopInformationMutation>(DeleteShopInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteShopInformation');
    },
    launchExportItems(variables?: LaunchExportItemsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LaunchExportItemsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LaunchExportItemsMutation>(LaunchExportItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'launchExportItems');
    },
    launchImportItems(variables?: LaunchImportItemsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LaunchImportItemsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LaunchImportItemsMutation>(LaunchImportItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'launchImportItems');
    },
    notifyMessageCardDesingCommunicationMessage(variables?: NotifyMessageCardDesingCommunicationMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NotifyMessageCardDesingCommunicationMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<NotifyMessageCardDesingCommunicationMessageMutation>(NotifyMessageCardDesingCommunicationMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'notifyMessageCardDesingCommunicationMessage');
    },
    testRenderEmailTemplate(variables?: TestRenderEmailTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TestRenderEmailTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TestRenderEmailTemplateMutation>(TestRenderEmailTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'testRenderEmailTemplate');
    },
    transitionToCanceled(variables: TransitionToCanceledMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToCanceledMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToCanceledMutation>(TransitionToCanceledDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToCanceled');
    },
    transitionToDeleted(variables: TransitionToDeletedMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToDeletedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToDeletedMutation>(TransitionToDeletedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToDeleted');
    },
    transitionToDeliveryCompleted(variables: TransitionToDeliveryCompletedMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToDeliveryCompletedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToDeliveryCompletedMutation>(TransitionToDeliveryCompletedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToDeliveryCompleted');
    },
    transitionToGettingReady(variables: TransitionToGettingReadyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToGettingReadyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToGettingReadyMutation>(TransitionToGettingReadyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToGettingReady');
    },
    transitionToImportedDeliverySlip(variables: TransitionToImportedDeliverySlipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToImportedDeliverySlipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToImportedDeliverySlipMutation>(TransitionToImportedDeliverySlipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToImportedDeliverySlip');
    },
    transitionToImportingDeliverySlip(variables: TransitionToImportingDeliverySlipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToImportingDeliverySlipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToImportingDeliverySlipMutation>(TransitionToImportingDeliverySlipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToImportingDeliverySlip');
    },
    transitionToPaid(variables: TransitionToPaidMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToPaidMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToPaidMutation>(TransitionToPaidDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToPaid');
    },
    transitionToPendingForRejection(variables: TransitionToPendingForRejectionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToPendingForRejectionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToPendingForRejectionMutation>(TransitionToPendingForRejectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToPendingForRejection');
    },
    transitionToRefunding(variables: TransitionToRefundingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToRefundingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToRefundingMutation>(TransitionToRefundingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToRefunding');
    },
    transitionToWaitingForAuthDeferredPayment(variables: TransitionToWaitingForAuthDeferredPaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToWaitingForAuthDeferredPaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToWaitingForAuthDeferredPaymentMutation>(TransitionToWaitingForAuthDeferredPaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToWaitingForAuthDeferredPayment');
    },
    transitionToWaitingForBankTransfer(variables: TransitionToWaitingForBankTransferMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToWaitingForBankTransferMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToWaitingForBankTransferMutation>(TransitionToWaitingForBankTransferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToWaitingForBankTransfer');
    },
    transitionToWaitingForCreditCardPayment(variables: TransitionToWaitingForCreditCardPaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransitionToWaitingForCreditCardPaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TransitionToWaitingForCreditCardPaymentMutation>(TransitionToWaitingForCreditCardPaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transitionToWaitingForCreditCardPayment');
    },
    updateBundledItems(variables?: UpdateBundledItemsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateBundledItemsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBundledItemsMutation>(UpdateBundledItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateBundledItems');
    },
    updateCatalog(variables?: UpdateCatalogMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCatalogMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCatalogMutation>(UpdateCatalogDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCatalog');
    },
    updateCommunicationMessage(variables?: UpdateCommunicationMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCommunicationMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCommunicationMessageMutation>(UpdateCommunicationMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCommunicationMessage');
    },
    updateCoupon(variables?: UpdateCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCouponMutation>(UpdateCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCoupon');
    },
    updateDeliverdCoupon(variables?: UpdateDeliverdCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDeliverdCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDeliverdCouponMutation>(UpdateDeliverdCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDeliverdCoupon');
    },
    updateDeliverySlipNo(variables?: UpdateDeliverySlipNoMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDeliverySlipNoMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDeliverySlipNoMutation>(UpdateDeliverySlipNoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDeliverySlipNo');
    },
    updateDiscountRate(variables?: UpdateDiscountRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDiscountRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDiscountRateMutation>(UpdateDiscountRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDiscountRate');
    },
    updateDomesticPostageFee(variables?: UpdateDomesticPostageFeeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDomesticPostageFeeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDomesticPostageFeeMutation>(UpdateDomesticPostageFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDomesticPostageFee');
    },
    updateHandoverMessage(variables?: UpdateHandoverMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateHandoverMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateHandoverMessageMutation>(UpdateHandoverMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateHandoverMessage');
    },
    updateItem(variables?: UpdateItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateItemMutation>(UpdateItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateItem');
    },
    updateItemBrand(variables?: UpdateItemBrandMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateItemBrandMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateItemBrandMutation>(UpdateItemBrandDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateItemBrand');
    },
    updateItemCategory(variables?: UpdateItemCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateItemCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateItemCategoryMutation>(UpdateItemCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateItemCategory');
    },
    updateItemPriceRange(variables?: UpdateItemPriceRangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateItemPriceRangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateItemPriceRangeMutation>(UpdateItemPriceRangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateItemPriceRange');
    },
    updateMember(variables?: UpdateMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMemberMutation>(UpdateMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMember');
    },
    updateOption(variables?: UpdateOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOptionMutation>(UpdateOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOption');
    },
    updateOptionItem(variables?: UpdateOptionItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateOptionItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOptionItemMutation>(UpdateOptionItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOptionItem');
    },
    updatePointRate(variables?: UpdatePointRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePointRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePointRateMutation>(UpdatePointRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePointRate');
    },
    updateProperty(variables?: UpdatePropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePropertyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePropertyMutation>(UpdatePropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateProperty');
    },
    updatePurpose(variables?: UpdatePurposeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePurposeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePurposeMutation>(UpdatePurposeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePurpose');
    },
    updateShopInformation(variables?: UpdateShopInformationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateShopInformationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateShopInformationMutation>(UpdateShopInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateShopInformation');
    },
    updateShopSetting(variables?: UpdateShopSettingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateShopSettingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateShopSettingMutation>(UpdateShopSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateShopSetting');
    },
    upsertCatalogs(variables?: UpsertCatalogsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertCatalogsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertCatalogsMutation>(UpsertCatalogsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsertCatalogs');
    },
    upsertEmailTemplate(variables?: UpsertEmailTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertEmailTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertEmailTemplateMutation>(UpsertEmailTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsertEmailTemplate');
    },
    base64NavFile(variables: Base64NavFileQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Base64NavFileQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Base64NavFileQuery>(Base64NavFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'base64NavFile');
    },
    base64SalesSlipExcel(variables: Base64SalesSlipExcelQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Base64SalesSlipExcelQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Base64SalesSlipExcelQuery>(Base64SalesSlipExcelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'base64SalesSlipExcel');
    },
    catalog(variables: CatalogQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CatalogQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CatalogQuery>(CatalogDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'catalog');
    },
    catalogByCode(variables: CatalogByCodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CatalogByCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CatalogByCodeQuery>(CatalogByCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'catalogByCode');
    },
    catalogs(variables?: CatalogsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CatalogsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CatalogsQuery>(CatalogsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'catalogs');
    },
    communicationMessage(variables: CommunicationMessageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CommunicationMessageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CommunicationMessageQuery>(CommunicationMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'communicationMessage');
    },
    communicationMessages(variables?: CommunicationMessagesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CommunicationMessagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CommunicationMessagesQuery>(CommunicationMessagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'communicationMessages');
    },
    countItems(variables?: CountItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountItemsQuery>(CountItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'countItems');
    },
    countMembers(variables?: CountMembersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountMembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountMembersQuery>(CountMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'countMembers');
    },
    countOrders(variables?: CountOrdersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CountOrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountOrdersQuery>(CountOrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'countOrders');
    },
    coupon(variables: CouponQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CouponQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CouponQuery>(CouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'coupon');
    },
    couponHistories(variables?: CouponHistoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CouponHistoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CouponHistoriesQuery>(CouponHistoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'couponHistories');
    },
    coupons(variables?: CouponsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CouponsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CouponsQuery>(CouponsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'coupons');
    },
    deliverdCoupon(variables: DeliverdCouponQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeliverdCouponQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeliverdCouponQuery>(DeliverdCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deliverdCoupon');
    },
    deliverdCoupons(variables?: DeliverdCouponsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeliverdCouponsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeliverdCouponsQuery>(DeliverdCouponsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deliverdCoupons');
    },
    delivery(variables?: DeliveryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeliveryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeliveryQuery>(DeliveryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'delivery');
    },
    discountRate(variables: DiscountRateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DiscountRateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DiscountRateQuery>(DiscountRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'discountRate');
    },
    discountRates(variables?: DiscountRatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DiscountRatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DiscountRatesQuery>(DiscountRatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'discountRates');
    },
    domesticPostageFee(variables: DomesticPostageFeeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DomesticPostageFeeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DomesticPostageFeeQuery>(DomesticPostageFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'domesticPostageFee');
    },
    domesticPostageFees(variables?: DomesticPostageFeesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DomesticPostageFeesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DomesticPostageFeesQuery>(DomesticPostageFeesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'domesticPostageFees');
    },
    emailTemplate(variables: EmailTemplateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EmailTemplateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EmailTemplateQuery>(EmailTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'emailTemplate');
    },
    emailTemplates(variables?: EmailTemplatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EmailTemplatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EmailTemplatesQuery>(EmailTemplatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'emailTemplates');
    },
    handoverMessage(variables: HandoverMessageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HandoverMessageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HandoverMessageQuery>(HandoverMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handoverMessage');
    },
    handoverMessages(variables?: HandoverMessagesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HandoverMessagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HandoverMessagesQuery>(HandoverMessagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handoverMessages');
    },
    item(variables: ItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemQuery>(ItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'item');
    },
    itemBrand(variables: ItemBrandQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemBrandQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemBrandQuery>(ItemBrandDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'itemBrand');
    },
    itemBrands(variables?: ItemBrandsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemBrandsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemBrandsQuery>(ItemBrandsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'itemBrands');
    },
    itemCategories(variables?: ItemCategoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemCategoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemCategoriesQuery>(ItemCategoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'itemCategories');
    },
    itemCategory(variables: ItemCategoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemCategoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemCategoryQuery>(ItemCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'itemCategory');
    },
    itemPriceRange(variables: ItemPriceRangeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemPriceRangeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemPriceRangeQuery>(ItemPriceRangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'itemPriceRange');
    },
    itemPriceRanges(variables?: ItemPriceRangesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemPriceRangesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemPriceRangesQuery>(ItemPriceRangesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'itemPriceRanges');
    },
    items(variables?: ItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemsQuery>(ItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'items');
    },
    member(variables: MemberQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MemberQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MemberQuery>(MemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'member');
    },
    members(variables?: MembersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MembersQuery>(MembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'members');
    },
    navs(variables: NavsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NavsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NavsQuery>(NavsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'navs');
    },
    option(variables: OptionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OptionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OptionQuery>(OptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'option');
    },
    optionItem(variables: OptionItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OptionItemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OptionItemQuery>(OptionItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'optionItem');
    },
    optionItems(variables?: OptionItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OptionItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OptionItemsQuery>(OptionItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'optionItems');
    },
    options(variables?: OptionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OptionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OptionsQuery>(OptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'options');
    },
    order(variables: OrderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OrderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderQuery>(OrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'order');
    },
    orders(variables?: OrdersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrdersQuery>(OrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orders');
    },
    pointRate(variables: PointRateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PointRateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PointRateQuery>(PointRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pointRate');
    },
    pointRates(variables?: PointRatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PointRatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PointRatesQuery>(PointRatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pointRates');
    },
    properties(variables?: PropertiesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PropertiesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PropertiesQuery>(PropertiesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'properties');
    },
    property(variables: PropertyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PropertyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PropertyQuery>(PropertyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'property');
    },
    purpose(variables: PurposeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PurposeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PurposeQuery>(PurposeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'purpose');
    },
    purposes(variables?: PurposesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PurposesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PurposesQuery>(PurposesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'purposes');
    },
    searchOptionItemsByOptionType(variables?: SearchOptionItemsByOptionTypeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchOptionItemsByOptionTypeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchOptionItemsByOptionTypeQuery>(SearchOptionItemsByOptionTypeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'searchOptionItemsByOptionType');
    },
    shopInformation(variables: ShopInformationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopInformationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShopInformationQuery>(ShopInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shopInformation');
    },
    shopInformations(variables?: ShopInformationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopInformationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShopInformationsQuery>(ShopInformationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shopInformations');
    },
    shopSetting(variables?: ShopSettingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopSettingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShopSettingQuery>(ShopSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shopSetting');
    },
    toplevelItemCategories(variables?: ToplevelItemCategoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ToplevelItemCategoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToplevelItemCategoriesQuery>(ToplevelItemCategoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toplevelItemCategories');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;